import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import resellerBanner from '../../images/reseller.jpg';  
import { registerReseller } from '../../features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import LoaderComponent from '../../components/LoaderComponent';

const ResellerSignup = () => {

  const { isAuthenticated,loading } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(()=>{
    if (isAuthenticated) {
      navigate('/reseller/login');
    }
  },[isAuthenticated,navigate]);

  

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().min(5, 'name minimum 5 character').required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string().required('Phone number is required'),
    refCode: Yup.string(),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const handleSubmit = (values) => {
    // Handle form submission
    console.log(values);
    dispatch(registerReseller({
      name:  values.name,
      email: values.email,
      phone: values.phone,
      refCode: values.refCode,
      password: values.password
    }));
  };

  

  if (loading) return <LoaderComponent />

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 pt-3">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-md w-full space-y-6">
        {/* Banner Image */}
        <div className="w-full flex justify-center mb-4">
          <img src={resellerBanner} alt="Login Banner" className="w-full h-auto rounded-lg" />
        </div>
        
        {/* Register Form */}
        <h2 className="text-center text-2xl font-bold text-gray-700">Create Your Reseller Account</h2>
        <Formik
          initialValues={{
            name: '',
            email: '',
            phone: '',
            refCode: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-600">Name</label>
                <Field
                  id="name"
                  name="name"
                  type="text"
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Enter your name"
                />
                <ErrorMessage name="name" component="div" className="text-red-500 text-sm" />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-600">Email</label>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Enter your email"
                />
                <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
              </div>
              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-600">Phone</label>
                <Field
                  id="phone"
                  name="phone"
                  type="text"
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Enter your phone number"
                />
                <ErrorMessage name="phone" component="div" className="text-red-500 text-sm" />
              </div>
              <div>
                <label htmlFor="refCode" className="block text-sm font-medium text-gray-600">Refer By Code (optional)</label>
                <Field
                  id="refCode"
                  name="refCode"
                  type="text"
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Enter your referral code"
                />
                <ErrorMessage name="refCode" component="div" className="text-red-500 text-sm" />
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-600">Password</label>
                <Field
                  id="password"
                  name="password"
                  type="password"
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Enter your password"
                />
                <ErrorMessage name="password" component="div" className="text-red-500 text-sm" />
              </div>
              <div>
                <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-600">Confirm Password</label>
                <Field
                  id="confirm-password"
                  name="confirmPassword"
                  type="password"
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Confirm your password"
                />
                <ErrorMessage name="confirmPassword" component="div" className="text-red-500 text-sm" />
              </div>
              
              <div>
                <button
                  type="submit"
                  className="w-full bg-green-600 text-white p-2 rounded-lg hover:bg-green-700 transition duration-300"
                >
                  Signup
                </button>
              </div>
            </Form>
          )}
        </Formik>

        {/* Sign Up Link */}
        <p className="text-center text-sm text-gray-600">
          Already Have an Account?{' '}
          <Link to="/reseller/login" className="text-green-600 hover:text-green-700 font-medium">
            Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ResellerSignup;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {FaTrash,FaPlus } from 'react-icons/fa';
import { getPaymentOptions, deletePaymentOption } from '../../features/paymentOptions/paymentOptionSlice';

import ShowPaymentOptionsForm from './ShowPaymentOptionsForm';
import LoaderComponent from '../LoaderComponent';

const PaymentOptionsList = () => {

  const { paymentOptions, loading, error } = useSelector((state) => state.paymentOptions);

const [showCreateForm, setShowCreateForm] = useState(false);
const dispatch = useDispatch();

// fetch headlines on mount

useEffect(() => {
  dispatch(getPaymentOptions());
}, [dispatch]);

  const handleDelete = (paymentOptionId) => {
    if (window.confirm('Are you sure you want to delete this headline?')) {
      dispatch(deletePaymentOption({paymentOptionId, token: JSON.parse(localStorage.getItem("access_token"))}));
    }
  };

  if(loading) return <LoaderComponent message="Loading categories..." />
  if(error) return <p className="text-red-500">{error}</p>
  

  return (
    <div className="container mx-auto p-6 ">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Payment Options</h2>
        <button
          onClick={() => setShowCreateForm(true)}
          className="bg-blue-500 text-white p-2 rounded shadow-md hover:bg-blue-600 transition-all flex items-center"
        >
          <FaPlus className="mr-2" /> create payment option
        </button>
      </div>

      {/* {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>} */}
      <div className='shadow-lg rounded-lg overflow-x-auto'>
        {paymentOptions.length > 0 ? (
          <table className="min-w-full bg-white border border-gray-200 rounded shadow-md">
            <thead className="bg-gray-200">
              <tr>
                <th className="py-2 px-4 border-b text-left font-semibold text-gray-700">Provider Name</th>
                <th className="py-2 px-4 border-b text-left font-semibold text-gray-700">Account Number</th>
                <th className="py-2 px-4 border-b text-center font-semibold text-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody>
              {paymentOptions.map((paymentOption) => (
                <tr key={paymentOption._id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b">{paymentOption.provider || '-'}</td>
                  <td className="py-2 px-4 border-b">{paymentOption.accountNumber || '-'}</td>
                  <td className="py-2 px-4 border-b text-center">
                    
                    <button
                      className="text-red-500 hover:text-red-700 mx-1"
                      onClick={() => handleDelete(paymentOption._id)}
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
      ) : (
        <p>No payment options available.</p>
      )}
      </div>
      {showCreateForm && <ShowPaymentOptionsForm setShowHeadlineForm={setShowCreateForm} />}
    </div>

  );
};

export default PaymentOptionsList;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleSubscriptionOrder } from '../../features/subscriptionOrder/subscriptionOrderSlice';
import LoaderComponent from '../LoaderComponent';

const SubscriptionDetails = () => {
    const { userInfo } = useSelector((state) => state.auth);
    const { subscriptionOrderByReseller, loading } = useSelector((state) => state.subscriptionOrders);

    const dispatch = useDispatch();

    useEffect(() => {
        if (userInfo) {
            dispatch(
                getSingleSubscriptionOrder({
                    resellerId: userInfo._id,
                    token: JSON.parse(localStorage.getItem('access_token')),
                })
            );
        }
    }, [userInfo,dispatch]);

    if (loading) return <LoaderComponent />;

    if (!subscriptionOrderByReseller) return <p className="text-center text-gray-500">No subscription details found.</p>;

    // Calculate expiration date by adding 1 year to `createdAt`
    const subscriptionDate = new Date(subscriptionOrderByReseller.createdAt);
    const expirationDate = new Date(subscriptionDate);
    expirationDate.setFullYear(subscriptionDate.getFullYear() + 1);

    return (
        <div className="max-w-lg mx-auto my-8 p-6 bg-white rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Subscription Details</h2>
            <div className="space-y-4">
                <div className="flex justify-between text-lg text-gray-700">
                    <span className="font-semibold">Package Name:</span>
                    <span>{subscriptionOrderByReseller.subscriptionPackageId.name}</span>
                </div>
                <div className="flex justify-between text-lg text-gray-700">
                    <span className="font-semibold">Amount:</span>
                    <span>${subscriptionOrderByReseller.amount}</span>
                </div>
                <div className="flex justify-between text-lg text-gray-700">
                    <span className="font-semibold">Subscription Date:</span>
                    <span>{subscriptionDate.toLocaleDateString()}</span>
                </div>
                <div className="flex justify-between text-lg text-gray-700">
                    <span className="font-semibold">Expiration Date:</span>
                    <span>{expirationDate.toLocaleDateString()}</span>
                </div>
                <div className="flex justify-between text-lg text-gray-700">
                    <span className="font-semibold">Status:</span>
                    <span>{subscriptionOrderByReseller.status}</span>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionDetails;

// src/components/UsersList.js
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaEdit, FaTrash, FaEye, FaPlus } from 'react-icons/fa';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { getAllUsersByAdmin, updateUser, deleteUser } from '../../features/user/userSlice';
import ShowCreateUserFormModal from './ShowCreateUserFormModal';
import ShowUserDetails from './ShowUserDetails';
import LoaderComponent from '../LoaderComponent';

const UsersList = () => {
  const { users, loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("access_token"));
    dispatch(getAllUsersByAdmin(token));
  }, [dispatch]);

  const handleDelete = (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      dispatch(deleteUser({ id: userId, token: JSON.parse(localStorage.getItem("access_token")) }));
      alert('User deleted successfully!');
    }
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setIsEditing(true);
  };

  const handleViewDetails = (user) => {
    setSelectedUser(user);
    setShowDetails(true);
  };

  const handleUpdateUser = (values) => {
    dispatch(updateUser({
      id: selectedUser._id,
      userData: values,
      token: JSON.parse(localStorage.getItem("access_token"))
    }));
    setIsEditing(false);
    alert('User updated successfully!');
  };

  if (loading) return <LoaderComponent />;

  return (
    <div className="container mx-auto p-4 mb-8 sm:p-6 lg:p-8 h-screen">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h2 className="text-xl sm:text-2xl font-semibold">Users List</h2>
        <button
          onClick={() => setShowCreateForm(true)}
          className="mt-4 sm:mt-0 bg-blue-500 text-white py-2 px-4 rounded shadow-md hover:bg-blue-600 transition-all flex items-center"
        >
          <FaPlus className="mr-2" /> Create User
        </button>
      </div>

      <div className="shadow-lg rounded-lg overflow-x-auto">
        {users.length > 0 ? (
          <table className="min-w-full bg-white text-left text-sm">
            <thead className="bg-gray-200 text-gray-700">
              <tr>
                <th className="py-2 px-3 sm:px-4">User ID</th>
                <th className="py-2 px-3 sm:px-4">Name</th>
                <th className="py-2 px-3 sm:px-4">Email</th>
                <th className="py-2 px-3 sm:px-4">Role</th>
                <th className="py-2 px-3 sm:px-4">Affiliate</th>
                <th className="py-2 px-3 sm:px-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id} className="border-t hover:bg-gray-100 transition">
                  <td className="py-2 px-3 sm:px-4 truncate max-w-xs">{user._id}</td>
                  <td className="py-2 px-3 sm:px-4">{user.name}</td>
                  <td className="py-2 px-3 sm:px-4 truncate max-w-xs">{user.email}</td>
                  <td className="py-2 px-3 sm:px-4">{user.role}</td>
                  <td className="py-2 px-3 sm:px-4">{user.affiliate ? "true" : "false"}</td>
                  <td className="py-2 px-3 sm:px-4 flex gap-2 justify-center">
                    <button
                      onClick={() => handleViewDetails(user)}
                      className="bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-600 transition-all"
                    >
                      <FaEye />
                    </button>
                    <button
                      onClick={() => handleEdit(user)}
                      className="bg-green-500 text-white p-2 rounded-full shadow-md hover:bg-green-600 transition-all"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => handleDelete(user._id)}
                      className="bg-red-500 text-white p-2 rounded-full shadow-md hover:bg-red-600 transition-all"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center py-4">No users found.</p>
        )}
      </div>

      {/* Modal for User Details */}
      {showDetails && selectedUser && (
        <ShowUserDetails selectedUser={selectedUser} setShowDetails={setShowDetails} />
      )}

      {/* Modal for Edit User */}
      {isEditing && selectedUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-1/2 lg:w-1/3">
            <h3 className="text-xl font-semibold mb-4">Edit User</h3>

            <Formik
              initialValues={{
                name: selectedUser.name,
                email: selectedUser.email,
                role: selectedUser.role,
              }}
              validationSchema={Yup.object({
                name: Yup.string().required('Name is required'),
                email: Yup.string().email('Invalid email address').required('Email is required'),
                role: Yup.string().required('Role is required'),
              })}
              onSubmit={(values) => {
                handleUpdateUser(values);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="mb-4">
                    <label className="block text-gray-700">Name</label>
                    <Field
                      name="name"
                      type="text"
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                    <ErrorMessage name="name" component="div" className="text-red-500 text-sm" />
                  </div>

                  <div className="mb-4">
                    <label className="block text-gray-700">Email</label>
                    <Field
                      name="email"
                      type="email"
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                    <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
                  </div>

                  <div className="mb-4">
                    <label className="block text-gray-700">Role</label>
                    <Field
                      as="select"
                      name="role"
                      className="w-full p-2 border border-gray-300 rounded"
                    >
                      <option value="">Select a role</option>
                      <option value="admin">Admin</option>
                      <option value="user">User</option>
                      {/* Add other roles as needed */}
                    </Field>
                    <ErrorMessage name="role" component="div" className="text-red-500 text-sm" />
                  </div>

                  <div className="flex justify-end gap-2">
                    <button
                      type="button"
                      onClick={() => setIsEditing(false)}
                      className="bg-gray-400 text-white py-2 px-4 rounded hover:bg-gray-500 transition-all"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition-all"
                    >
                      Save Changes
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}

      {/* Modal for Create User */}
      {showCreateForm && (
        <ShowCreateUserFormModal setShowCreateForm={setShowCreateForm} />
      )}
    </div>
  );
};

export default UsersList;














// // src/components/UsersList.js
// import React, { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { FaEdit, FaTrash, FaEye,FaPlus } from 'react-icons/fa'; 
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import * as Yup from 'yup';
// import { getAllUsersByAdmin, updateUser, deleteUser } from '../../features/user/userSlice';
// import ShowCreateUserFormModal from './ShowCreateUserFormModal';
// import ShowUserDetails from './ShowUserDetails';
// import LoaderComponent from '../LoaderComponent';


// const UsersList = () => {

//   const { users, loading } = useSelector((state) => state.user);
//   const dispatch = useDispatch();
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [showDetails, setShowDetails] = useState(false);
//   const [isEditing, setIsEditing] = useState(false);
//   const [showCreateForm, setShowCreateForm] = useState(false);

//   useEffect(() => {
//     const token = JSON.parse(localStorage.getItem("access_token"));
//     dispatch(getAllUsersByAdmin(token));
//   }, [dispatch]);

//   const handleDelete = (userId) => {
//     if (window.confirm('Are you sure you want to delete this user?')) {
//       dispatch(deleteUser({id: userId, token: JSON.parse(localStorage.getItem("access_token"))})); 
//       alert('User deleted successfully!');
//     }
//   };

//   const handleEdit = (user) => {
//     setSelectedUser(user);
//     setIsEditing(true);
//   };

//   const handleViewDetails = (user) => {
//     setSelectedUser(user);
//     setShowDetails(true);
//   };

//   const handleUpdateUser = (values) => {
//     dispatch(updateUser({
//       id: selectedUser._id,
//       userData: values,
//       token: JSON.parse(localStorage.getItem("access_token"))
//     })); 
//     setIsEditing(false);
//     alert('User updated successfully!');
//   };

  

//   if(loading) return <LoaderComponent />

//   return (
//     <div className="container mx-auto p-6">
//       <div className="flex justify-between items-center mb-6">
//         <h2 className="text-2xl font-semibold">Users List</h2>
//         <button
//           onClick={() => setShowCreateForm(true)} 
//           className="bg-blue-500 text-white p-2 rounded shadow-md hover:bg-blue-600 transition-all flex items-center"
//         >
//           <FaPlus className="mr-2" /> Create User
//         </button>
//       </div>
//       <div className="shadow-lg rounded-lg overflow-hidden">
//         {
//           users.length > 0 ? (
//             <table className="min-w-full bg-white">
//           <thead className="bg-gray-200">
//             <tr>
//               <th className="py-2 px-4">User ID</th>
//               <th className="py-2 px-4">Name</th>
//               <th className="py-2 px-4">Email</th>
//               <th className="py-2 px-4">Role</th>
//               <th className="py-2 px-4">Affiliate</th>
//               <th className="py-2 px-4">Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {users.map((user) => (
//               <tr key={user._id} className="border-t">
//                 <td className="py-2 px-4">{user._id}</td>
//                 <td className="py-2 px-4">{user.name}</td>
//                 <td className="py-2 px-4">{user.email}</td>
//                 <td className="py-2 px-4">{user.role}</td>
//                 <td className="py-2 px-4">{user.affiliate ? "true" : "false"}</td>
//                 <td className="py-2 px-4 flex gap-2">
//                   <button
//                     onClick={() => handleViewDetails(user)}
//                     className="bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-600 transition-all"
//                   >
//                     <FaEye />
//                   </button>
//                   <button
//                     onClick={() => handleEdit(user)}
//                     className="bg-green-500 text-white p-2 rounded-full shadow-md hover:bg-green-600 transition-all"
//                   >
//                     <FaEdit />
//                   </button>
//                   <button
//                     onClick={() => handleDelete(user._id)}
//                     className="bg-red-500 text-white p-2 rounded-full shadow-md hover:bg-red-600 transition-all"
//                   >
//                     <FaTrash />
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//           ):(
//             <p className="text-center py-4">No users found.</p>
//           )
//         }
//       </div>

//       {/* Modal for User Details */}
//       {showDetails && selectedUser && <ShowUserDetails selectedUser={selectedUser} setShowDetails={setShowDetails} />}

//       {/* Modal for Edit User */}
//       {isEditing && selectedUser && (
//         <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
//             <h3 className="text-xl font-semibold mb-4">Edit User</h3>

//             <Formik
//               initialValues={{
//                 name: selectedUser.name,
//                 email: selectedUser.email,
//                 role: selectedUser.role,
//               }}
//               validationSchema={Yup.object({
//                 name: Yup.string().required('Name is required'),
//                 email: Yup.string().email('Invalid email address').required('Email is required'),
//                 role: Yup.string().required('Role is required'),
//               })}
//               onSubmit={(values) => {
//                 handleUpdateUser(values);
//               }}
//             >
//               {({ isSubmitting }) => (
//                 <Form>
//                   <div className="mb-4">
//                     <label className="block text-gray-700">Name</label>
//                     <Field
//                       name="name"
//                       type="text"
//                       className="w-full p-2 border border-gray-300 rounded"
//                     />
//                     <ErrorMessage name="name" component="div" className="text-red-500" />
//                   </div>

//                   <div className="mb-4">
//                     <label className="block text-gray-700">Email</label>
//                     <Field
//                       name="email"
//                       type="email"
//                       className="w-full p-2 border border-gray-300 rounded"
//                     />
//                     <ErrorMessage name="email" component="div" className="text-red-500" />
//                   </div>

//                   <div className="mb-4">
//                     <label className="block text-gray-700">Role</label>
//                     <Field
//                       as="select"
//                       name="role"
//                       className="w-full p-2 border border-gray-300 rounded"
//                     >
//                       <option value="">Select a role</option>
//                       <option value="admin">Admin</option>
//                       <option value="user">User</option>
//                       {/* Add other roles as needed */}
//                     </Field>
//                     <ErrorMessage name="role" component="div" className="text-red-500" />
//                   </div>

//                   <div className="flex justify-end gap-2">
//                     <button
//                       type="button"
//                       onClick={() => setIsEditing(false)}
//                       className="bg-gray-400 text-white p-2 rounded hover:bg-gray-500 transition-all"
//                     >
//                       Cancel
//                     </button>
//                     <button
//                       type="submit"
//                       disabled={isSubmitting}
//                       className="bg-green-500 text-white p-2 rounded hover:bg-green-600 transition-all"
//                     >
//                       Save Changes
//                     </button>
//                   </div>
//                 </Form>
//               )}
//             </Formik>
//           </div>
//         </div>
//       )}

//       {/* Modal for Create User */}
//       {showCreateForm && <ShowCreateUserFormModal setShowCreateForm={setShowCreateForm} />}

//     </div>
//   );
// };

// export default UsersList;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaTrash, FaPlus } from 'react-icons/fa';
import { getSliderImages, deleteSliderImage } from '../../features/sliderImage/sliderImageSlice';
import ShowSliderImageForm from './ShowSliderImageForm';
import LoaderComponent from '../LoaderComponent';

const SliderImageList = () => {
  const dispatch = useDispatch();
  const { sliderImages, loading, error } = useSelector((state) => state.sliderImage);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    dispatch(getSliderImages({ token: JSON.parse(localStorage.getItem("access_token")) }));
  }, [dispatch]);

  const handleDeleteImage = (id) => {
    if (window.confirm("Are you sure you want to delete this slider image?")) {
      dispatch(deleteSliderImage({ sliderImageId: id, token: JSON.parse(localStorage.getItem("access_token")) }));
    }
  };

  const toggleForm = () => setShowForm(!showForm);

  if (loading) return <LoaderComponent message="Loading slider images..." />;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Slider Images</h2>
        <button 
          onClick={toggleForm}
          className="flex items-center bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          <FaPlus className="mr-2" /> Create Slider Image
        </button>
      </div>
      
      

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-lg">
          <thead>
            <tr className="bg-gray-100 text-gray-700">
              <th className="py-3 px-6 font-semibold text-left border-b">Image</th>
              <th className="py-3 px-6 font-semibold text-left border-b">URL</th>
              <th className="py-3 px-6 font-semibold text-left border-b">Section</th>
              <th className="py-3 px-6 font-semibold text-left border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {sliderImages && sliderImages.length > 0 ? (
              sliderImages.map((slider) => (
                <tr key={slider._id} className="hover:bg-gray-50">
                  <td className="py-3 px-6 border-b">
                    <img src={slider.image} alt="Slider" className="h-16 w-16 object-cover rounded" />
                  </td>
                  <td className="py-3 px-6 border-b">
                    <a href={slider.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                      {slider.url}
                    </a>
                  </td>
                  <td className="py-3 px-6 border-b">{slider.for}</td>
                  <td className="py-3 px-6 border-b">
                    <button onClick={() => handleDeleteImage(slider._id)} className="px-4 py-2 text-sm text-white bg-red-500 rounded hover:bg-red-600">
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center py-6 text-gray-500">No slider images found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      { showForm && <ShowSliderImageForm setShowForm={setShowForm} />}
    </div>
  );
};

export default SliderImageList;

import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../../components/LoaderComponent";
import { addToCart } from "../../utils/cartUtils";
import { getSingleProductFromResellerStore , getRecommendationProductsFromResellerStore} from "../../features/resellerStore/resellerStoreSlice";
import { getReviewsByProductId } from "../../features/reviews/reviewSlice";
import ShareComponent from "../../components/ShareComponent";
import RelatedProductsAndReviewsFromStore from "./RelatedProductsAndReviewsFromStore";


const StoreSingleProduct = () => {

  const { productTitle, storeid } = useParams();
  const { singleProductOfStore,recommendedProducts, loading } = useSelector((state) => state.resellerStore);
  const { isAuthenticated, userInfo } = useSelector((state) => state.auth);
  const { singleProductReviews } = useSelector((state) => state.review);
  // const [selectedImage, setSelectedImage] = useState("");
  // const [zoomStyle, setZoomStyle] = useState({ display: "none" });
  const [quantity, setQuantity] = useState(1);

  const [selectedColor, setSelectedColor] = useState({});
  const [selectedSize, setSelectedSize] = useState({});
  const [selectedVariant, setSelectedVariant] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    if (productTitle && storeid) {
      dispatch(getSingleProductFromResellerStore({ productTitle, storeId:storeid }));
    }
  }, [dispatch, productTitle,storeid]);

  useEffect(() => {
    if (singleProductOfStore) {
      if (singleProductOfStore.colorSizeVariants?.length > 0) {
        setSelectedColor(singleProductOfStore?.colorSizeVariants[0]);
        setSelectedSize(singleProductOfStore.colorSizeVariants[0]?.sizes[0]);
        setSelectedVariant({});
      } else if (singleProductOfStore?.sizeVariants?.length > 0) {
        setSelectedVariant(singleProductOfStore?.sizeVariants[0]);
        setSelectedColor({});
        setSelectedSize({});
      }

      // const categoryIds = Array.isArray(singleProductOfStore?.category)
      //   ? singleProductOfStore?.category.map((cat) => cat._id)
      //   : [];

      if (singleProductOfStore?._id) {
        dispatch(
          getRecommendationProductsFromResellerStore({
            category:singleProductOfStore?.category.join(","),
            productId: singleProductOfStore?._id,
            storeId: singleProductOfStore?.storeId,
          })
        );
        dispatch(getReviewsByProductId(singleProductOfStore?._id));
      } 
      else {
        console.error("Product ID is undefined");
      }
    }
  }, [singleProductOfStore, dispatch]);

  // destructuring singleProduct from singleProductOfStore
  // const {product: singleProduct,customPrice } = singleProductOfStore

  // useEffect(() => {
  //   if (
  //     singleProductOfStore &&
  //     singleProductOfStore.product?.images &&
  //     singleProductOfStore.product?.images?.length > 0
  //   ) {
  //     setSelectedImage(singleProductOfStore?.product?.images[0]?.url);
  //   }
  // }, [singleProductOfStore]);

  const incrementQuantity = () => setQuantity((prev) => prev + 1);
  const decrementQuantity = () =>
    quantity > 1 && setQuantity((prev) => prev - 1);

  // const magnifyImage = (index) => {
  //   if (singleProductOfStore?.product?.images?.[index]?.url) {
  //     setSelectedImage(singleProductOfStore?.product?.images[index].url);
  //   }
  // };

  // const handleMouseMove = (e) => {
  //   const { left, top, width, height } = e.target.getBoundingClientRect();
  //   const x = e.clientX - left;
  //   const y = e.clientY - top;

  //   const bgPosX = (x / width) * 100;
  //   const bgPosY = (y / height) * 100;

  //   setZoomStyle({
  //     display: "block",
  //     backgroundImage: `url(${selectedImage})`,
  //     backgroundSize: `${width * 2}px ${height * 2}px`,
  //     backgroundPosition: `${bgPosX}% ${bgPosY}%`,
  //     left: x - 75,
  //     top: y - 75,
  //   });
  // };

  // const handleMouseLeave = () => setZoomStyle({ display: "none" });

  const handleAddToCart = () => {
    const productDetails =
    singleProductOfStore.colorSizeVariants?.length > 0
        ? {
            _id: singleProductOfStore._id,
            title: singleProductOfStore.title,
            image: selectedColor.image,
            price: selectedSize.customPrice,
            color: selectedColor.colorName,
            size: selectedSize.sizeName,
            affiliateCommission: selectedColor.affiliateCommission,
          }
        : {
            _id: singleProductOfStore._id,
            title: singleProductOfStore.title,
            image: selectedVariant.image,
            price: selectedVariant.customPrice,
            size: selectedVariant.sizeName,
            affiliateCommission: selectedVariant.affiliateCommission,
          };
       
    addToCart(productDetails,Number(quantity), singleProductOfStore.reseller); // Adds the selected product configuration to the cart
    
  };

  // handle checkout from Buy Now  button
  const handleBuyNow = () => {
    if (!isAuthenticated) {
      navigate("/login", { state: { from: `/reseller/store/${storeid}/products/${singleProductOfStore?.title}` } });
      return;
    }

    // create checkout data
    const products = [
      {
        id: singleProductOfStore._id,
        title: singleProductOfStore.title,
        image: selectedColor.image || selectedVariant.image,
        color: selectedColor.colorName || "",
        size: selectedSize.sizeName || selectedVariant.sizeName,
        quantity,
        price: selectedSize.customPrice || selectedVariant.customPrice,
        affiliateCommission: selectedSize.affiliateCommission || selectedVariant.affiliateCommission,
        resellerPrice: selectedSize.resellerPrice || selectedVariant.resellerPrice
      },
    ];

    const totalPrice = Number(quantity * (selectedSize.customPrice || selectedVariant.customPrice));

    navigate("/checkout", {
      state: { products, totalPrice, affiliateCode: null,reseller: singleProductOfStore.reseller },
    });
  };


  if (loading) return <LoaderComponent message="Loading..." />;
  if (!singleProductOfStore) return <p>Product not found</p>;

  return (
    <div className="container mx-auto py-8 px-4 md:px-0">
       <div className="p-6 flex flex-col md:flex-row">
      {/* Image Gallery */}
      <div className="flex-1">
        <img
          src={
            singleProductOfStore && singleProductOfStore.colorSizeVariants?.length > 0
            ? selectedColor?.image
              : selectedVariant?.image
            }
            alt={selectedColor?.colorName || selectedVariant?.sizeName || ""}
            className="w-full h-96 object-contain rounded-lg shadow-lg mb-4"
            />
        {/* Color Selector for `colorSizeVariants` */}
        {singleProductOfStore && singleProductOfStore.colorSizeVariants && (
          <div className="flex flex-row space-x-2 items-center">
            {singleProductOfStore?.colorSizeVariants.map((color) => (
              <>
              <button
                key={color.colorName}
                onClick={() => {
                  setSelectedColor(color);
                  setSelectedSize(color.sizes[0]); // Reset size to the first available
                }}
                className={`w-10 h-10 rounded-full border-2 border-gray-300 transition duration-200 ${
                  selectedColor.colorName === color.colorName
                    ? "border-blue-500"
                    : ""
                }`}
                style={{ backgroundColor: color.colorName.toLowerCase() }}
              />
            <span>{color.colorName}</span>
            </>
            ))}
          </div>
        )}
      </div>
      {/* Product Details */}
      <div className="flex-1 md:ml-6">
        <h1 className="text-3xl font-bold mb-2">{singleProductOfStore.title}</h1>
        <p className="text-gray-800 mb-4">{singleProductOfStore.description}</p>

        {/* Size Selector for `colorSizeVariants` */}
        {singleProductOfStore && singleProductOfStore.colorSizeVariants && selectedColor?.sizes?.length > 0 && (
          <>
            <h3 className="font-semibold">Available Sizes:</h3>
            <div className="flex flex-wrap mb-4">
              {selectedColor.sizes.map((size) => (
                <button
                key={size.sizeName}
                onClick={() => setSelectedSize(size)}
                className={`mr-2 mb-2 px-3 py-1 border rounded transition duration-200 ${
                  selectedSize.sizeName === size.sizeName
                  ? "bg-blue-500 text-white"
                      : "bg-white text-gray-800 border-gray-300 hover:bg-gray-100"
                  }`}
                  disabled={size.stock === 0}
                >
                  {size.sizeName}
                  {size.stock > 0 ? `(${size.stock} available)` : "(Out of stock)"}
                </button>
              ))}
            </div>
          </>
        )}

        {/* Size Selector for `sizeVariants` */}
        {singleProductOfStore && singleProductOfStore.sizeVariants && singleProductOfStore.sizeVariants?.length > 0 && (
          <>
            <h3 className="font-semibold">Available Sizes:</h3>
            <div className="flex flex-wrap mb-4">
              {singleProductOfStore && singleProductOfStore.sizeVariants.map((variant) => (
                <button
                  key={variant.sizeName}
                  onClick={() => setSelectedVariant(variant)}
                  className={`mr-2 mb-2 px-3 py-1 border rounded transition duration-200 ${
                    selectedVariant.sizeName === variant.sizeName
                      ? "bg-blue-500 text-white"
                      : "bg-white text-gray-800 border-gray-300 hover:bg-gray-100"
                    }`}
                  disabled={variant.stock === 0}
                >
                  {variant.sizeName}
                  {variant.stock > 0
                    ? `(${variant.stock} available)`
                    : "(Out of stock)"}
                </button>
              ))}
            </div>
          </>
        )}

        {/* Quantity Selector */}
        <div className="flex items-center mb-4">
          <button
            className="px-4 py-2 bg-gray-300 rounded"
            onClick={decrementQuantity}
          >
            -
          </button>
          <span className="px-4">{quantity}</span>
          <button
            className="px-4 py-2 bg-gray-300 rounded"
            onClick={incrementQuantity}
          >
            +
          </button>
        </div>

        <h3 className="font-semibold">
          Price: TK.
          {singleProductOfStore && singleProductOfStore.colorSizeVariants?.length > 0
            ? selectedSize?.customPrice?.toFixed(2)
            : selectedVariant?.customPrice?.toFixed(2)}
        </h3>
        <div className="flex items-center">
        <button
        onClick={handleAddToCart}
          className={`mt-4 w-1/2 bg-blue-500 text-white m-2 p-2 rounded hover:bg-blue-600 transition duration-200 ${
            singleProductOfStore && singleProductOfStore.colorSizeVariants?.length > 0
              ? selectedSize.stock === 0
              : selectedVariant.stock === 0
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          disabled={
           ( singleProductOfStore && singleProductOfStore.colorSizeVariants?.length > 0
              ? selectedSize.stock === 0
              : selectedVariant.stock === 0) || userInfo?.role === "reseller"
          }
        >
          {singleProductOfStore && singleProductOfStore.colorSizeVariants?.length > 0
            ? selectedSize.stock > 0
              ? "Add to Cart"
              : "Out of Stock"
            : selectedVariant.stock > 0
            ? "Add to Cart"
            : "Out of Stock"}
        </button>
        <button
          onClick={handleBuyNow}
          className={`mt-4 w-1/2 bg-blue-500 text-white m-2 p-2 rounded hover:bg-blue-600 transition duration-200 ${
            singleProductOfStore.colorSizeVariants
              ? selectedSize.stock === 0
              : selectedVariant.stock === 0
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          disabled={
            (singleProductOfStore.colorSizeVariants
              ? selectedSize.stock === 0
              : selectedVariant.stock === 0) || userInfo?.role === "reseller"
          }
        >
          Buy Now
        </button>
        </div>
      <ShareComponent url={window.location.href} title={singleProductOfStore.title} description={singleProductOfStore.description} />
      </div>
    </div>
    <RelatedProductsAndReviewsFromStore reviews={singleProductReviews} relatedProducts={recommendedProducts} />
    </div>
  );
};

export default StoreSingleProduct;

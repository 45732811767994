import React, { useState } from 'react';

const FAQPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle between open and close
  };

  const faqs = [
    {
      question: 'What is your return policy?',
      answer:
        'Our return policy allows you to return items within 30 days of purchase as long as they are unused, in the original packaging, and with all tags intact.',
    },
    {
      question: 'How do I track my order?',
      answer:
        'Once your order ships, we will send you a tracking number via email. You can use that tracking number to follow your order’s journey on our website or through the courier’s site.',
    },
    {
      question: 'Do you offer international shipping?',
      answer:
        'Yes, we offer international shipping! Delivery times and costs will depend on the destination country. Please check our shipping page for more information.',
    },
    {
      question: 'Can I modify my order after placing it?',
      answer:
        'Unfortunately, once an order is placed, we cannot modify it. However, you can contact our support team, and we’ll do our best to assist you before it’s shipped.',
    },
    {
      question: 'How can I contact customer support?',
      answer:
        'You can reach our customer support team via the “Contact Us” form on our website, or you can email us at support@yourcompany.com.',
    },
  ];

  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-purple-600 to-indigo-500 text-white py-20">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl md:text-5xl font-extrabold mb-6">Frequently Asked Questions</h1>
          <p className="text-lg md:text-xl mb-8">
            Find answers to some of the most common questions we get from our customers. If you need further assistance, don't hesitate to reach out to us.
          </p>
        </div>
      </section>

      {/* FAQ Accordion */}
      <section className="container mx-auto py-12 px-6 md:px-12">
        <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg space-y-6">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`transition-all duration-300 rounded-lg border-2 border-transparent ${activeIndex === index ? 'border-indigo-500' : ''}`}
            >
              <div
                onClick={() => toggleAccordion(index)}
                className="cursor-pointer flex justify-between items-center p-4 text-xl font-semibold text-gray-800 hover:bg-indigo-100 rounded-t-lg"
              >
                <span>{faq.question}</span>
                <span className="text-xl text-indigo-500">{activeIndex === index ? '−' : '+'}</span>
              </div>
              {activeIndex === index && (
                <div className="p-4 text-gray-600 bg-indigo-50 rounded-b-lg">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>

    </div>
  );
};

export default FAQPage;

import React from 'react';

const BusinessUnderConstruction = () => {
  return (
    <div className="bg-gradient-to-r from-blue-100 to-indigo-200 min-h-screen flex items-center justify-center py-20">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-xl p-8 text-center">
        {/* Header */}
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Our Business is Coming Soon!</h1>
        <p className="text-lg text-gray-600 mb-6">
          We are currently building something amazing. Stay tuned for more details!
        </p>

        {/* Under Construction Illustration */}
        <div className="mb-8">
          <img
            src="https://via.placeholder.com/500x300?text=Under+Construction"
            alt="Under Construction"
            className="w-full h-auto rounded-lg shadow-md"
          />
        </div>

        {/* Countdown Timer (Optional) */}
        <div className="mb-6">
          <p className="text-xl font-semibold text-gray-700 mb-4">We are almost ready!</p>
          <div className="flex justify-center items-center space-x-4">
            <div className="text-center bg-blue-50 p-4 rounded-lg shadow-md w-20">
              <p className="text-2xl font-bold text-gray-800">30</p>
              <p className="text-sm text-gray-500">Days</p>
            </div>
            <div className="text-center bg-blue-50 p-4 rounded-lg shadow-md w-20">
              <p className="text-2xl font-bold text-gray-800">12</p>
              <p className="text-sm text-gray-500">Hours</p>
            </div>
            <div className="text-center bg-blue-50 p-4 rounded-lg shadow-md w-20">
              <p className="text-2xl font-bold text-gray-800">45</p>
              <p className="text-sm text-gray-500">Minutes</p>
            </div>
            <div className="text-center bg-blue-50 p-4 rounded-lg shadow-md w-20">
              <p className="text-2xl font-bold text-gray-800">60</p>
              <p className="text-sm text-gray-500">Seconds</p>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  );
};

export default BusinessUnderConstruction;

import React, { useState } from "react";
import UserSidebar from "./UserSidebar";
import ProfileContent from "./ProfileContent";
import OrdersContent from "./OrdersContent";
import AffiliateContent from "./AffiliateContent";
import ReferralsContent from "./ReferralsContent";
import PaymentSettings from "../common/PaymentSettings";
import Withdraw from "../common/Withdraw";
import JoinAffiliate from "./JoinAffiliate";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  createWithdrawalMethod,
  getWithdrawalMethods,
} from "../../features/withdrawalMethod/withdrawalSlice";
import { FiMenu } from "react-icons/fi";

const UserDashboard = () => {
  const { userDetails: userInfo } = useSelector((state) => state.user);
  const location = useLocation();
  const { active } = location.state || { active: "profile" };

  const [selectedSection, setSelectedSection] = useState(active);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      {/* Sidebar */}
      <UserSidebar
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
        isAffiliate={userInfo?.affiliate}
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />

      {/* Main content */}
      <div className="flex-1 overflow-y-auto h-full  bg-gray-100">
        {/* Toggle Button for Mobile View */}
        <div className="flex items-center justify-center w-full bg-blue-600 p-4">
              <button
                onClick={toggleSidebar}
                className="inline-flex lg:hidden text-white mr-4"
              >
                <FiMenu size={24} />
              </button>
              <h1 className="text-3xl text-white font-semibold">
                User Dashboard
              </h1>
            </div>

        {/* Conditionally render based on selected section */}
        {selectedSection === "profile" && <ProfileContent role="user" />}
        {selectedSection === "orders" && <OrdersContent />}
        {selectedSection === "affiliate" && <AffiliateContent />}
        {selectedSection === "referrals" && <ReferralsContent />}
        {selectedSection === "payment-settings" && (
          <PaymentSettings
            createWithdrawalMethod={createWithdrawalMethod}
            getWithdrawalMethods={getWithdrawalMethods}
          />
        )}
        {selectedSection === "withdraw" && <Withdraw />}
        {selectedSection === "join-affiliate" && <JoinAffiliate />}
      </div>
    </div>
  );
};

export default UserDashboard;

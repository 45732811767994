import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories, } from '../../features/category/categorySlice';
import {  FaPlus } from 'react-icons/fa';
import ShowCreateCategoryFormModal from './ShowCreateCategoryFormModal';
import LoaderComponent from '../LoaderComponent';

const CategoriesList = () => {
  const dispatch = useDispatch();
  const { categories, loading, error } = useSelector((state) => state.category);
  const [showCreateForm, setShowCreateForm] = useState(false);

  // fetch categories on mount
  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);
 


  if(loading) return <LoaderComponent message="Loading categories..." />
  if(error) return <p className="text-red-500">{error}</p>

  return (
    <div className="container mx-auto p-6 mb-8 h-screen">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Categories</h2>
        <button
          onClick={() => setShowCreateForm(true)}
          className="bg-blue-500 text-white p-2 rounded shadow-md hover:bg-blue-600 transition-all flex items-center"
        >
          <FaPlus className="mr-2" /> Create categories
        </button>
      </div>

      {/* Table */}
      <div className='shadow-lg rounded-lg overflow-hidden'>
        {categories.length > 0 ? (
          <table className="min-w-full bg-white border border-gray-200 rounded shadow-md">
            <thead className="bg-gray-200">
              <tr>
                <th className="py-2 px-4 border-b text-left font-semibold text-gray-700">Category Id</th>
                <th className="py-2 px-4 border-b text-left font-semibold text-gray-700">Category Name</th>
                <th className="py-2 px-4 border-b text-center font-semibold text-gray-700">Category Image</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category) => (
                <tr key={category.id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b">{category._id}</td>
                  <td className="py-2 px-4 border-b">{category.name}</td>
                  <td className="py-2 px-4">
                  <img
                    src={category.image}
                    alt={category.name}
                    className="w-20 h-20 object-cover rounded-lg"
                  />
                </td>
                </tr>
              ))}
            </tbody>
          </table>
      ) : (
        <p>No categories available.</p>
      )}
      </div>
      {showCreateForm && <ShowCreateCategoryFormModal setShowCreateForm={setShowCreateForm} />}
    </div>

  );
};

export default CategoriesList;

// src/components/Sidebar.js
import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../features/auth/authSlice'
import { useNavigate } from 'react-router-dom';
import { FiX } from 'react-icons/fi';

const Sidebar = ({ selectedSection, setSelectedSection, menuItems, toggleSidebar, isOpen }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () =>{
    dispatch(logout());
    navigate('/');
  }
  const handleToggleSidebarAndSelectedSection = (id) => {
    setSelectedSection(id);
    if(isOpen) toggleSidebar();
  };
  return (
    <div className={`fixed inset-0 bg-green-800 text-white transform ${
      isOpen ? 'translate-x-0 z-50' : '-translate-x-full'
    } transition-transform duration-300 lg:translate-x-0 lg:relative lg:w-64 h-screen overflow-y-auto`}>
      {/* Close button for mobile view */}
      <button onClick={toggleSidebar} className="lg:hidden text-white top-4 right-4 absolute">
        <FiX size={24} />
      </button>
      <ul className="p-4 mt-8">
        {menuItems.map((item) => (
          <li
            key={item.id}
            onClick={() => handleToggleSidebarAndSelectedSection(item.id)}
            className={`p-3 cursor-pointer hover:bg-gray-700 ${
              selectedSection === item.id ? 'bg-red-700' : ''
            }`}
          >
            {item.label}
          </li>
        ))}
       
        {/* Add more items for other sections */}
        <li
          onClick={handleLogout}
          className={`p-3 cursor-pointer bg-blue-500 italic text-center hover:bg-gray-700`}
        >
          log out
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/api";

export const getReferAmounts = createAsyncThunk(
  "referAmount/getReferAmounts",
  async ({ token }) => {
    const response = await api.get("/refer-amounts", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  }
);

export const addReferAmount = createAsyncThunk(
  "referAmount/addReferAmount",
  async ({ referAmountForAffiliate, referAmountForReseller, token }) => {
    const response = await api.post(
      "/refer-amounts",
      { referAmountForAffiliate, referAmountForReseller },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  }
);

export const deleteReferAmount = createAsyncThunk(
  "referAmount/deleteReferAmount",
  async ({ referAmountId, token }) => {
    await api.delete(`/refer-amounts/${referAmountId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return referAmountId;
  }
);

const referAmountSlice = createSlice({
  name: "referAmount",
  initialState: {
    referAmounts: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReferAmounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReferAmounts.fulfilled, (state, action) => {
        state.loading = false;
        state.referAmounts = action.payload;
      })
      .addCase(getReferAmounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addReferAmount.fulfilled, (state, action) => {
        state.referAmounts.push(action.payload);
      })
      .addCase(deleteReferAmount.fulfilled, (state, action) => {
        state.referAmounts = state.referAmounts.filter(
          (item) => item._id !== action.payload
        );
      });
  },
});

export default referAmountSlice.reducer;

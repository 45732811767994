import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaEye, FaTrash } from "react-icons/fa";
import { getAffiliates, updateAffiliate, deleteAffiliate } from "../../features/affiliate/affiliateSlice";
import LoaderComponent from "../LoaderComponent";

const AffiliateList = () => {
  const dispatch = useDispatch();
  const { affiliates, loading, error } = useSelector((state) => state.affiliate);

  const [selectedAffiliate, setSelectedAffiliate] = useState(null);
  const [showAffiliateDetails, setShowAffiliateDetails] = useState(false);

  useEffect(() => {
    dispatch(getAffiliates({ token: JSON.parse(localStorage.getItem("access_token")) }));
  }, [dispatch]);

  const handleStatusChange = (id, newStatus) => {
    dispatch(updateAffiliate({ id, affiliateData: { status: newStatus }, token: JSON.parse(localStorage.getItem("access_token")) }));
  };

  const handleDeleteAffiliate = (id) => {
    if (window.confirm("Are you sure you want to delete this affiliate?")) {
      dispatch(deleteAffiliate({ id, token: JSON.parse(localStorage.getItem("access_token")) }));
    }
  };

  const openAffiliateDetails = (affiliate) => {
    setSelectedAffiliate(affiliate);
    setShowAffiliateDetails(true);
  };

  const closeAffiliateDetails = () => {
    setShowAffiliateDetails(false);
    setSelectedAffiliate(null);
  };

  if (loading) return <LoaderComponent message="Loading affiliates..." />;
  if (error) return <p>Error: {error}</p>;
  if (!affiliates || affiliates.length === 0) return <p>No affiliates found.</p>;

  return (
    <div className="container mx-auto px-4 py-8 mb-8 h-screen">
      <h2 className="text-2xl font-bold mb-4">Affiliate List</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-lg">
          <thead>
            <tr className="bg-gray-100 text-gray-700">
              <th className="py-3 px-6 font-semibold text-left border-b">User ID</th>
              <th className="py-3 px-6 font-semibold text-left border-b">Referral Code</th>
              <th className="py-3 px-6 font-semibold text-left border-b">Social Links</th>
              <th className="py-3 px-6 font-semibold text-left border-b">Total Commission</th>
              <th className="py-3 px-6 font-semibold text-left border-b">Status</th>
              <th className="py-3 px-6 font-semibold text-left border-b">Joined On</th>
              <th className="py-3 px-6 font-semibold text-left border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {affiliates.map((affiliate) => (
              <tr key={affiliate._id} className="hover:bg-gray-50">
                <td className="py-3 px-6 border-b">{affiliate.userId}</td>
                <td className="py-3 px-6 border-b">{affiliate.ref_code}</td>
                <td className="py-3 px-6 border-b">
                  <ul className="space-y-1">
                    {affiliate.social_links.facebook && (
                      <li>
                        <a href={affiliate.social_links.facebook} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                          Facebook
                        </a>
                      </li>
                    )}
                    {affiliate.social_links.instagram && (
                      <li>
                        <a href={affiliate.social_links.instagram} target="_blank" rel="noopener noreferrer" className="text-pink-500 hover:underline">
                          Instagram
                        </a>
                      </li>
                    )}
                    {affiliate.social_links.twitter && (
                      <li>
                        <a href={affiliate.social_links.twitter} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">
                          Twitter
                        </a>
                      </li>
                    )}
                    {affiliate.social_links.youtube && (
                      <li>
                        <a href={affiliate.social_links.youtube} target="_blank" rel="noopener noreferrer" className="text-red-600 hover:underline">
                          YouTube
                        </a>
                      </li>
                    )}
                  </ul>
                </td>
                <td className="py-3 px-6 border-b">TK. {affiliate.total_commission.toFixed(2)}</td>
                <td className="py-3 px-6 border-b">
                  <select
                    value={affiliate.status}
                    onChange={(e) => handleStatusChange(affiliate._id, e.target.value)}
                    className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </td>
                <td className="py-3 px-6 border-b">{new Date(affiliate.createdAt).toLocaleDateString()}</td>
                <td className="py-3 px-6 border-b flex gap-2">
                  <button onClick={() => openAffiliateDetails(affiliate)} className="px-4 py-2 text-sm text-white bg-blue-500 rounded hover:bg-blue-600 mr-2">
                    <FaEye />
                  </button>
                  <button onClick={() => handleDeleteAffiliate(affiliate._id)} className="px-4 py-2 text-sm text-white bg-red-500 rounded hover:bg-red-600">
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Affiliate Details Modal */}
      {showAffiliateDetails && selectedAffiliate && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3">
            <h3 className="text-xl font-bold mb-4">Affiliate Details</h3>
            <p><strong>User ID:</strong> {selectedAffiliate.userId}</p>
            <p><strong>Referral Code:</strong> {selectedAffiliate.ref_code}</p>
            <p><strong>Total Commission:</strong> ${selectedAffiliate.total_commission.toFixed(2)}</p>
            <p><strong>Status:</strong> {selectedAffiliate.status}</p>
            <p><strong>Joined On:</strong> {new Date(selectedAffiliate.createdAt).toLocaleDateString()}</p>
            <div className="mt-4">
              <button onClick={closeAffiliateDetails} className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700">Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AffiliateList;

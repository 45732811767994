import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import bannerImage from '../images/login.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../features/auth/authSlice';

const Login = () => {
  
  const {loading, userInfo} = useSelector(state=>state.auth);
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  useEffect(()=>{
    if(userInfo){
      navigate(from)
    }
  },[userInfo,navigate,from]);

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  // Formik hook with initial values, validation, and submit handler
  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema,
    onSubmit: (values) => {
      
      dispatch(loginUser(values))

      //reset form 
      formik.resetForm();
    },

    
  });

 

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-md w-full space-y-6">
        {/* Banner Image */}
        <div className="w-full flex justify-center mb-4">
          <img src={bannerImage} alt="Login Banner" className="w-full h-auto rounded-lg" />
        </div>
        
        {/* Login Form */}
        <h2 className="text-center text-2xl font-bold text-gray-700">Login to Your Account</h2>
        <form onSubmit={formik.handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-600">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              placeholder="Enter your email"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500 text-sm">{formik.errors.email}</div>
            )}
          </div>
          
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-600">Password</label>
            <input
              id="password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              placeholder="Enter your password"
            />
            {formik.touched.password && formik.errors.password && (
              <div className="text-red-500 text-sm">{formik.errors.password}</div>
            )}
          </div>
          
          <div className="flex items-center justify-between">
            <Link to="/forgot-password" className="text-sm text-green-600 hover:text-green-700">
              Forgot Password?
            </Link>
          </div>
          
          <div>
            <button
              type="submit"
              disabled={formik.isSubmitting}
              className="w-full bg-green-600 text-white p-2 rounded-lg hover:bg-green-700 transition duration-300"
            >
              { loading ? 'logging in': "login"}
            </button>
          </div>
        </form>

        {/* Sign Up Link */}
        <p className="text-center text-sm text-gray-600">
          Don't have an account?{' '}
          <Link to="/signup" state={{from:from}} className="text-green-600 hover:text-green-700 font-medium">
            Sign Up
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit, FaPlus } from 'react-icons/fa';
import Modal from './Modal';
import ProductRequestForm from './ProductRequestForm';
import { getAllProductRequests, getAllProductRequestsByReseller, updateProductRequest, createProductRequest } from '../../features/productRequest/productRequestSlice';
import LoaderComponent from '../LoaderComponent';

const ProductRequestList = ({from="admin"}) => {
  
    const { productRequests, loading, error } = useSelector((state) => state.productRequest);
  
  const dispatch = useDispatch();
  
  const [isModalOpen, setModalOpen] = useState(false);
  const [editRequest, setEditRequest] = useState(null);



useEffect(() => {
  if (from === "admin") {
    dispatch(getAllProductRequests());
  } else {
    dispatch(getAllProductRequestsByReseller());
  }
}, [dispatch, from]);


  const openCreateForm = () => {
    setEditRequest(null);
    setModalOpen(true);
  };

  const openEditForm = (request) => {
    setEditRequest(request);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  const handleFormSubmit = (values,{resetForm}) => {
    if (editRequest) {
      dispatch(updateProductRequest({ data:values, id: editRequest._id }));
    } else {
      dispatch(createProductRequest(values));
    }
    resetForm();
    closeModal();
  };


  if (loading) return <LoaderComponent message="Loading product requests..." />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">Product Requests</h2>
        <button
          onClick={openCreateForm}
          className={`flex ${from === "admin" ? "hidden" : ""} items-center bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700`}
        >
          <FaPlus className="mr-2" /> Send Product Request
        </button>
      </div>

      {productRequests.length === 0 ? (
        <div className="text-center text-gray-500">No product requests found.</div>
      ) : (
        <div className='shadow-lg rounded-lg overflow-x-auto'>
        <table className="w-full bg-white rounded shadow-md">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-2">Product Name</th>
              <th className="p-2">Category</th>
              <th className="p-2">Quantity</th>
              <th className="p-2">Response Message</th>
              <th className="p-2">status</th>
             {
                from === "admin" &&  <th className="p-2">Actions</th>
             }
            </tr>
          </thead>
          <tbody>
            {productRequests.map((request) => (
              <tr key={request.id} className="border-b">
                <td className="p-2">{request.productName}</td>
                <td className="p-2">{request.category}</td>
                <td className="p-2">{request.quantityRequested}</td>
                <td className="p-2">{request.responseMessage || 'No response yet'}</td>
                <td className="p-2">{request.status}</td>
                {
                    from === "admin" && <td className="p-2 text-center">
                    <button
                      onClick={() => openEditForm(request)}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <FaEdit />
                    </button>
                  </td>
                }
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      )}

      <Modal title={editRequest ? 'Edit Product Request' : 'New Product Request'} isOpen={isModalOpen} onClose={closeModal}>
        <ProductRequestForm
          onSubmit={handleFormSubmit}
          initialValues={editRequest}
          mode={editRequest ? 'edit' : 'add'}
        />
      </Modal>
    </div>
  );
};

export default ProductRequestList;

import { FaStar } from "react-icons/fa";

const ReviewCard = ({ review }) => {
  const { user, rating, createdAt: date, comment } = review;
 
  return (
    <div className="mx-auto bg-white shadow-lg rounded-lg p-6 w-full max-w-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl">
      {/* Header */}
      <div className="flex items-center mb-3">
        <div className="flex-shrink-0 w-10 h-10 bg-blue-500 text-white flex items-center justify-center rounded-full font-semibold">
          {user.name.charAt(0)}
        </div>
        <div className="ml-4">
          <h4 className="text-lg font-semibold text-gray-800">{user.name}</h4>
          <p className="text-gray-500 text-sm">{new Date(date).toLocaleDateString()}</p>
        </div>
      </div>

      {/* Rating */}
      <div className="flex items-center mb-3">
        {[...Array(5)].map((_, index) => (
          <FaStar
            key={index}
            className={`${
              index < rating ? "text-yellow-500" : "text-gray-300"
            } mr-1`}
          />
        ))}
        <span className="ml-2 text-sm text-gray-600">{rating} / 5</span>
      </div>

      {/* Comment */}
      <p className="text-gray-700 text-base leading-relaxed">{comment}</p>
    </div>
  );
};

export default ReviewCard;

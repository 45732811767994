import React, {  useEffect, useState } from 'react';
import {  useDispatch, useSelector } from 'react-redux';
import { fetchUserDetails,updateUserDetails,updateResellerProfile, fetchResellerProfile } from '../../features/user/userSlice';
import LoaderComponent from '../LoaderComponent';

const ProfileContent = ({role}) => {

  const {userDetails:userInfo,loading} = useSelector((state) => state.user);
  
  const [isEditing, setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState({
       
       name: '', 
       email: '',
       contactNumber: '',
       birthday: {
         day: '',
         month: '',
         year:'',
       },
       gender:  '',

     }
  );

  const dispatch = useDispatch();

  // fetch user details
  useEffect(() => {
    if (role === 'reseller') {
      dispatch(fetchResellerProfile());
    }else if( role === 'user') {      
      dispatch(fetchUserDetails(JSON.parse(localStorage.getItem('access_token'))));
    }
    
  }, [dispatch,role]);

  
  // Set profileData when userInfo is available
  useEffect(() => {
    if (userInfo) {
      const dateOfBirth = userInfo.birthday ? userInfo.birthday.split('-') : ['00', '00', '0000'];
      setProfileData({
        name: userInfo.name || '',
        email: userInfo.email || '',
        phone: userInfo.phone || '',
        birthday: {
          day: dateOfBirth[0],
          month: dateOfBirth[1],
          year: dateOfBirth[2],
        },
        gender: userInfo.gender || '',
      });
    }
  }, [userInfo]);

  // Toggle between edit and view modes
  const toggleEditMode = () => setIsEditing(!isEditing);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'day' || name === 'month' || name === 'year') {
      setProfileData((prevData) => ({
        ...prevData,
        birthday: { ...prevData.birthday, [name]: value },
      }));
    } else {
      setProfileData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Handle gender change
  const handleGenderChange = (e) => {
    setProfileData((prevData) => ({
      ...prevData,
      gender: e.target.value,
    }));
  };

 
  
  const saveProfile = () => {
    // Example validation check
    if (!profileData.email.includes('@')) {
      alert('Please enter a valid email.');
      return;
    } 
    // dispatch the update action -- based on role
    if(role === 'reseller') {
      dispatch(updateResellerProfile({id: userInfo._id, userData:{...profileData,birthday:`${profileData.birthday.day}-${profileData.birthday.month}-${profileData.birthday.year}`}}));
    } else if (role === 'user') {
      dispatch(updateUserDetails({id: userInfo._id, userData:{...profileData,birthday:`${profileData.birthday.day}-${profileData.birthday.month}-${profileData.birthday.year}`}}));
      
    }

    // Exit edit mode
    setIsEditing(false);
  };

  

  if (loading) return <LoaderComponent />;
  return (
    <div className="max-w-2xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Profile</h2>
        
        <button
          onClick={toggleEditMode}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          ✏️
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block font-semibold">Name</label>
          {isEditing ? (
            <input
              type="text"
              name="name"
              value={profileData.name}
              onChange={handleChange}
              className="border rounded w-full px-2 py-1"
            />
          ) : (
            <p className="bg-gray-200 p-2 rounded">{profileData.name}</p>
          )}
        </div>


        <div>
          <label className="block font-semibold">Email</label>
          {isEditing ? (
            <input
              type="email"
              name="email"
              value={profileData.email}
              onChange={handleChange}
              className="border rounded w-full px-2 py-1"
            />
          ) : (
            <p className="bg-gray-200 p-2 rounded">{profileData.email}</p>
          )}
        </div>

        <div>
          <label className="block font-semibold">Contact Number</label>
          {isEditing ? (
            <input
              type="text"
              name="phone"
              value={profileData.phone}
              onChange={handleChange}
              className="border rounded w-full px-2 py-1"
            />
          ) : (
            <p className="bg-gray-200 p-2 rounded">{profileData.phone}</p>
          )}
        </div>

        <div className="col-span-2">
          <label className="block font-semibold">Birthday</label>
          <div className="flex gap-2">
            <input
              type="text"
              name="day"
              placeholder="DD"
              value={profileData.birthday.day}
              onChange={handleChange}
              className="border rounded w-1/3 px-2 py-1"
            />
            <input
              type="text"
              name="month"
              placeholder="MM"
              value={profileData.birthday.month}
              onChange={handleChange}
              className="border rounded w-1/3 px-2 py-1"
            />
            <input
              type="text"
              name="year"
              placeholder="YYYY"
              value={profileData.birthday.year}
              onChange={handleChange}
              className="border rounded w-1/3 px-2 py-1"
            />
          </div>
        </div>

        <div className="col-span-2">
          <label className="block font-semibold">Gender</label>
          <div className="flex items-center gap-4">
            <label className="flex items-center">
              <input
                type="radio"
                name="gender"
                value="male"
                checked={profileData.gender === 'male'}
                onChange={handleGenderChange}
                disabled={!isEditing}
                className="mr-2"
              />
              Male
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="gender"
                value="female"
                checked={profileData.gender === 'female'}
                onChange={handleGenderChange}
                disabled={!isEditing}
                className="mr-2"
              />
              Female
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="gender"
                value="N/A"
                checked={profileData.gender === 'N/A'}
                onChange={handleGenderChange}
                disabled={!isEditing}
                className="mr-2"
              />
              N/A
            </label>
          </div>
        </div>
      </div>

      {isEditing && (
        <div className="mt-6">
          <button
            onClick={saveProfile}
            className="px-4 py-2 bg-blue-500 text-white rounded mr-2"
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
          <button
            onClick={toggleEditMode}
            className="px-4 py-2 bg-gray-400 text-white rounded"
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default ProfileContent;


const ShowModal = ({ChildComponent,setShowCreateForm,onClose,isEditing=false,videoCourse = null,title="Add your desire!", ...rest}) => {

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-2/3 max-h-[90vh] overflow-auto"> {/* Added max-h and overflow-auto */}
            <h3 className="text-xl text-center bg-green-900 text-white font-semibold mb-4 p-2">{title}</h3>
            {/* Render child component */}
            {ChildComponent && <ChildComponent initialValues={videoCourse} onClose={onClose} isEditing={isEditing} product={rest.product}  />} 
            <button
              onClick={() => setShowCreateForm(false)}
              className="w-full mt-4 bg-red-600 text-white p-2 rounded hover:bg-red-700 transition-all"
            >
              Cancel
            </button>
          </div>
        </div>
      )
 };

export default ShowModal;

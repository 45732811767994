import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaShoppingCart, FaSearch } from "react-icons/fa";
import { FiMenu, FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../features/auth/authSlice";
import logo from "../images/logo.png";
import userProfile from "../images/profile.webp";
import CartSidebar from "../pages/Cart";
import { getCartItems } from "../utils/cartUtils";
import { debounce } from "lodash";

const Navbar = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [cart, setCart] = useState(getCartItems());
  const [cartOpen, setCartOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
    setMenuOpen(false);
    window.location.reload();
  };

  // Debounced search handler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query) {
        navigate(`/shop/products?search=${query}`);
      }
    }, 500), // Adjust debounce delay as needed
    [navigate, searchQuery]
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery) {
      navigate(`/shop/products?search=${searchQuery}`);
    }
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel(); // Cleanup debounce on unmount
    };
  }, [debouncedSearch]);

  useEffect(() => {
    // Update cart when items change
    const updateCart = () => {
      setCart(getCartItems());
    };

    // Listen for the custom event
    window.addEventListener("cartUpdated", updateCart);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("cartUpdated", updateCart);
    };
  }, []);

  return (
    <>
      {/* Main Navbar */}
      <nav className="bg-white shadow-lg sticky top-0 z-50 p-4">
        <div className="container mx-auto flex justify-around items-center">
          {/* Logo */}
          <Link to="/" className="text-black text-2xl font-bold">
            <img src={logo} alt="Logo" className="h-18 w-18 mr-2" />
          </Link>

          {/* Search Bar */}
          <form
            onSubmit={handleSearchSubmit}
            className="flex items-center w-full max-w-md mx-4"
          >
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search On Paribar"
              className="p-2 w-full border border-gray-300 rounded-l-lg focus:outline-none"
            />
            <button type="submit" className="bg-purple-600 p-3 rounded-r-lg">
              <FaSearch className="text-white" />
            </button>
          </form>

          {/* Desktop Links */}
          <div className="hidden md:flex space-x-4 items-center">
            <Link
              to="/reseller"
              className="text-black-300 hover:text-black-800 hover:bg-gray-200 p-2 rounded-lg"
            >
              RESELLER-SHOP
            </Link>

            <Link
              to="/shop"
              className="text-black-300 hover:text-black-800 hover:bg-gray-200 p-2 rounded-lg"
            >
              SHOP
            </Link>

            {!userInfo && (
              <>
                <Link
                  to="/login"
                  className="text-black-300 hover:text-black-800 hover:bg-gray-200 p-2 rounded-lg"
                >
                  SIGN IN
                </Link>
                <Link
                  to="/reseller/signup"
                  className="text-black-300 hover:text-black-800 hover:bg-gray-200 p-2 rounded-lg"
                >
                  BECOME A RESELLER
                </Link>
              </>
            )}

            {userInfo && (
              <Link
                to={`/${userInfo.role}/dashboard`}
                className="text-black-300 hover:text-black-800 p-2 rounded-lg"
              >
                <img
                  src={userProfile}
                  alt="User"
                  className="w-10 h-10 rounded-full inline-block"
                />
              </Link>
            )}
            {userInfo && (
              <button
                onClick={handleLogout}
                className="text-green-300 text-2xl hover:text-green-800 hover:bg-gray-200 p-4 rounded-lg"
              >
                logout
              </button>
            )}
            <button
              onClick={() => setCartOpen(true)}
              className="text-green-300 text-2xl hover:text-green-800 hover:bg-gray-200 p-4 rounded-lg relative"
            >
              <FaShoppingCart />
              {cart.length > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full px-2 text-xs">
                  {cart.length}
                </span>
              )}
            </button>
          </div>

          {/* Hamburger Icon for Mobile */}
          <button
            onClick={() => setMenuOpen(true)}
            className="md:hidden text-black-300 p-2"
            aria-label="Open Menu"
          >
            <FiMenu size={24} />
          </button>
        </div>
      </nav>

      {/* Mobile Menu Modal with Animation */}
      {menuOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-end ">
          <div
            className={`w-3/4 max-w-sm bg-white p-6 shadow-lg transform transition-transform duration-400 h-full overflow-y-auto ${
              menuOpen ? "translate-x-0" : "translate-x-full"
            }`}
          >
            {/* Close Button */}
            <button
              onClick={() => setMenuOpen(false)}
              className="text-black-300 p-2 absolute top-4 right-4"
              aria-label="Close Menu"
            >
              <FiX size={24} />
            </button>

            {/* Mobile Navigation Links */}
            <nav className="flex flex-col space-y-4 mt-8">
              <Link
                to="/shop"
                className="text-black-300 hover:text-black-800 p-2"
                onClick={() => setMenuOpen(false)}
              >
                SHOP
              </Link>
              <Link
                to="/reseller"
                className="text-black-300 hover:text-black-800 p-2"
                onClick={() => setMenuOpen(false)}
              >
                RESELLER-SHOP
              </Link>
              <Link
                to="/contacts"
                className="text-black-300 hover:text-black-800 p-2"
                onClick={() => setMenuOpen(false)}
              >
                Contact Us
              </Link>
              {!userInfo && (
                <>
                  <Link
                    onClick={() => setMenuOpen(false)}
                    to="/login"
                    className="text-black-300 hover:text-black-800 hover:bg-gray-200 p-2 rounded-lg"
                  >
                    SIGN IN
                  </Link>
                  <Link
                    onClick={() => setMenuOpen(false)}
                    to="/reseller/signup"
                    className="text-black-300 hover:text-black-800 hover:bg-gray-200 p-2 rounded-lg"
                  >
                    BECOME A RESELLER
                  </Link>
                </>
              )}

              {userInfo && (
                <Link
                  to={`/${userInfo.role}/dashboard`}
                  className="text-black-300 hover:text-black-800 p-2"
                  onClick={() => setMenuOpen(false)}
                >
                  <img
                    src={userProfile}
                    alt="User"
                    className="w-10 h-10 rounded-full inline-block"
                  />
                </Link>
              )}

              {userInfo && (
                <Link
                  onClick={handleLogout}
                  className="text-black-300 hover:text-black-800 hover:bg-gray-200 p-2 rounded-l"
                >
                  logout
                </Link>
              )}
              <button
                onClick={() => {
                  setCartOpen(true);
                  setMenuOpen(false);
                }}
                className="relative text-green-300 text-2xl hover:text-green-800 hover:bg-gray-200 p-4 rounded-lg relative"
              >
                <FaShoppingCart />
              {cart.length > 0 && (
                <span className="absolute -top-2 left-8 bg-red-500 text-white rounded-full px-2 text-xs">
                  {cart.length}
                </span>
              )}
              </button>
            </nav>
          </div>
        </div>
      )}

      {/* Cart Sidebar */}
      <CartSidebar isOpen={cartOpen} onClose={() => setCartOpen(false)} />
    </>
  );
};

export default Navbar;

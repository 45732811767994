export const getCartItems = () => {
    const cart = localStorage.getItem("cart");
    return cart ? JSON.parse(cart) : [];
  };
  
  export const saveCartItems = (items) => {
    localStorage.setItem("cart", JSON.stringify(items));
  };
  
  export const addToCart = (product, quantity = 1, reseller=null) => {
    const currentCart = getCartItems();
    const existingProduct = currentCart.find(item => item._id === product._id);
  
    if (existingProduct) {
      existingProduct.quantity += quantity;
    } else {
      currentCart.push({ ...product, quantity });
    }

    if(reseller) {
      localStorage.setItem('reseller', JSON.stringify(reseller));
    }
  
    saveCartItems(currentCart);
    // the event for immediately the product cart
    const event = new Event('cartUpdated');
    window.dispatchEvent(event);
  };
  
  export const removeFromCart = (productId) => {
    const updatedCart = getCartItems().filter(item => item._id !== productId);
    if (updatedCart.length === 0) {
      localStorage.removeItem("reseller");
    }
    saveCartItems(updatedCart);  
    window.dispatchEvent(new Event('cartUpdated'));  
  };
  
  // src/utils/cartUtils.js
export const clearCart = () => {
    localStorage.removeItem("cart");
    localStorage.removeItem("reseller");
    window.dispatchEvent(new Event('cartUpdated'));  
  };
  
  export const updateQuantity = (productId, quantity) => {
    const currentCart = getCartItems();
    const updatedCart = currentCart.map((item) =>
      item.id === productId ? { ...item, quantity } : item
    );
    saveCartItems(updatedCart);
  };
  
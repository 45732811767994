import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";

const ShareComponent = ({ url, title, description, image }) => {
  // Native Web Share API
  const handleWebShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title,
          text: description,
          url,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };

  return (
    <div className="flex flex-wrap items-center gap-4 mt-8">
      <h4 className="text-lg font-semibold mb-2">Share this product:</h4>
      {/* React-Share Buttons */}
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon size={40} round />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={40} round />
      </TwitterShareButton>
      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon size={40} round />
      </WhatsappShareButton>
      <LinkedinShareButton url={url} title={title} summary={description}>
        <LinkedinIcon size={40} round />
      </LinkedinShareButton>
      <EmailShareButton url={url} subject={title} body={description}>
        <EmailIcon size={40} round />
      </EmailShareButton>
      {/* Web Share API Button */}
      <button
        onClick={handleWebShare}
        className="bg-blue-500 text-white px-3 py-2 rounded-md hover:bg-blue-600 transition duration-200"
      >
        Share Now
      </button>
    </div>
  );
};

export default ShareComponent;

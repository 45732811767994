import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api/api';

// fetch user details
export const fetchUserDetails = createAsyncThunk('user/fetchUserDetails', async (token) => {
  const response = await api.get(`/users/profile`,{
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return response.data;
});

// become an affiliate  --- never used yet.
export const becomeAffiliate = createAsyncThunk('user/becomeAffiliate', async ({userId,token}) => {
  const response = await api.post(`/users/${userId}/affiliate`,null,{
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
  return response.data;
});

// update user details --user and --admin
export const updateUserDetails = createAsyncThunk('user/updateUserDetails', async ({id,userData,token}) => {
  const response = await api.put(`/users/${id}`, userData, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
  return response.data;
});

// fetch reseller profile
export const fetchResellerProfile = createAsyncThunk('reseller/fetchResellerProfile', async (token) => {
  const response = await api.get(`/reseller/profile`,{
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
  return response.data;
});

// update reseller profile
export const updateResellerProfile = createAsyncThunk('reseller/updateResellerProfile', async ({id,userData,token}) => {
  const response = await api.put(`/reseller/profile/${id}`, userData, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
});


// get all user by admin
export const getAllUsersByAdmin = createAsyncThunk('user/getAllUsers', async (token) => {
  const response = await api.get('/users', {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
  return response.data;
});

// update a user by admin
export const updateUser = createAsyncThunk('user/updateUser', async ({id,userData,token}) => {
  const response = await api.put(`/admin/users/${id}`, userData, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
  return response.data;
});

// delete user by admin
export const deleteUser = createAsyncThunk('user/deleteUser', async ({id,token}) => {
  const response = await api.delete(`/admin/users/${id}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
  return response.data;
});

// create user by admin
export const createUser = createAsyncThunk('user/createUser', async ({userData,token}) => {
  const response = await api.post('/admin/users', userData,{headers: {
    "Content-Type":"application/json",
    "Authorization": `Bearer ${token}`
  }});
  return response.data;
});

// get all reseller by admin
export const getAllResellersByAdmin = createAsyncThunk('reseller/getAllResellers', async (token) => {
  const response = await api.get('/admin/resellers', {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
  return response.data;
})

// delete reseller by admin
export const deleteResellerByAdmin = createAsyncThunk('reseller/deleteReseller', async ({id,token}) => {
  const response = await api.delete(`/admin/resellers/${id}`, {
    headers: {
    "Authorization": `Bearer ${token}`
    }
  });
  return response.data;
});
  

const userSlice = createSlice({
  name: 'userDetails',
  initialState: {
    users: [],
    resellers: [],
    userDetails: null,
    pagination: null,
    loading: false,
    error: null,
    isAuthenticated: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(becomeAffiliate.pending, (state) => {
        state.loading = true;
      })
      .addCase(becomeAffiliate.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(becomeAffiliate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateUserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload.user;
        state.isAuthenticated = true;
      })
      .addCase(updateUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchResellerProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchResellerProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(fetchResellerProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateResellerProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateResellerProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload.user;
        state.isAuthenticated = true;
      })
      .addCase(updateResellerProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllUsersByAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUsersByAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.users;
        state.pagination = action.payload.pagination;
      })
      .addCase(getAllUsersByAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = state.users.map((user) => {
          if (user._id === action.payload.user._id) {
            return action.payload.user;
          }
          return user;
        });
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = state.users.filter((user) => user._id !== action.payload._id);
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users.push(action.payload);
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllResellersByAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllResellersByAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.resellers = action.payload;
      })
      .addCase(getAllResellersByAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteResellerByAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteResellerByAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.resellers = state.resellers.filter((reseller) => reseller._id !== action.payload._id);
      })
      .addCase(deleteResellerByAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export default userSlice.reducer;

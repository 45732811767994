import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api/api';

// fetch categories
export const fetchCategories = createAsyncThunk('category/fetchCategories', async () => {
    const response = await api.get('/categories');
    return response.data;
});

// create category by admin
export const createCategory = createAsyncThunk('category/createCategory', async (category) => {
    const response = await api.post('/categories', category,{headers: {
        "Content-Type":"application/json"
    }});
    return response.data;
});



const categorySlice = createSlice({
    name: 'category',
    initialState: {
        categories: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.categories = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createCategory.fulfilled, (state, action) => {
                state.categories.push(action.payload);
                state.loading = false;
                state.error = null;
            })
            .addCase(createCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default categorySlice.reducer;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProductToResellerStore } from "../../features/resellerStore/resellerStoreSlice";

const AddToStoreForm = ({ setShowCreateForm, product }) => {
  const [updatedProduct, setUpdatedProduct] = useState(product);
  const [allPricesSet, setAllPricesSet] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  // Handle custom price changes immutably
  const handleCustomPriceChange = (variantType, variantIndex, sizeIndex, value) => {
    const customPrice = Number(value);
    if (isNaN(customPrice) || customPrice < 0) {
      alert("Please enter a valid number for custom price.");
      return;
    }

    // Create a deep copy of the product
    const updated = JSON.parse(JSON.stringify(updatedProduct));

    if (variantType === "colorSizeVariants") {
      updated.colorSizeVariants[variantIndex].sizes[sizeIndex].customPrice = customPrice;
    } else if (variantType === "sizeVariants") {
      updated.sizeVariants[sizeIndex].customPrice = customPrice;
    }

    setUpdatedProduct(updated);
  };

  // Check if all prices are set
  useEffect(() => {
    let allSet = true;
    const checkVariants = (variants) =>
      variants.forEach((variant) => {
        variant.sizes?.forEach((size) => {
          if (!size.customPrice || size.customPrice <= 0) allSet = false;
        });
      });

    if (updatedProduct.colorSizeVariants.length > 0) {
      checkVariants(updatedProduct.colorSizeVariants);
    } else if (updatedProduct.sizeVariants.length > 0) {
      updatedProduct.sizeVariants.forEach((size) => {
        if (!size.customPrice || size.customPrice <= 0) allSet = false;
      });
    }

    setAllPricesSet(allSet);
  }, [updatedProduct]);

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Updated Product to Send to Backend:", updatedProduct);
    alert("Product variants successfully updated!");

    // Dispatch the action to add the product to the reseller store
    dispatch(addProductToResellerStore({storeData:{reseller:userInfo._id,product:updatedProduct},token:JSON.parse(localStorage.getItem("access_token"))}));

    // Optionally close the form
    // setShowCreateForm(false);
  };

  const renderVariantFields = () => {
    if (updatedProduct.colorSizeVariants.length > 0) {
      return updatedProduct.colorSizeVariants.map((colorVariant, colorIndex) => (
        <div key={colorIndex}>
          <h3 className="text-lg font-semibold text-gray-700 mb-2">{colorVariant.colorName}</h3>
          {colorVariant.sizes.map((sizeVariant, sizeIndex) => (
            <div key={sizeIndex} className="mb-4">
              <label className="block text-gray-700 mb-2">
                {`Size: ${sizeVariant.sizeName} - Reseller Base Price: ${sizeVariant.resellerPrice} - Minimum Sold Price: ${sizeVariant.minimumSoldPrice}`}
              </label>
              <input
                type="number"
                className="block w-full p-2 border border-gray-300 rounded"
                placeholder="Enter custom price"
                value={sizeVariant.customPrice || ""}
                onChange={(e) =>
                  handleCustomPriceChange("colorSizeVariants", colorIndex, sizeIndex, e.target.value)
                }
                required
              />
            </div>
          ))}
        </div>
      ));
    } else if (updatedProduct.sizeVariants.length > 0) {
      return updatedProduct.sizeVariants.map((sizeVariant, sizeIndex) => (
        <div key={sizeIndex} className="mb-4">
          <label className="block text-gray-700 mb-2">
            {`Size: ${sizeVariant.sizeName} - Reseller Base Price: ${sizeVariant.resellerPrice} - Minimum Sold Price: ${sizeVariant.minimumSoldPrice}`}
          </label>
          <input
            type="number"
            className="block w-full p-2 border border-gray-300 rounded"
            placeholder="Enter custom price"
            value={sizeVariant.customPrice || ""}
            onChange={(e) =>
              handleCustomPriceChange("sizeVariants", null, sizeIndex, e.target.value)
            }
            required
          />
        </div>
      ));
    } else {
      return <p>No variants available for this product.</p>;
    }
  };
console.log(userInfo);
  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-md shadow-md">
      <h2 className="text-2xl font-semibold text-gray-700 mb-4">Add to Store</h2>
      <form onSubmit={handleSubmit}>
        {renderVariantFields()}

        {allPricesSet ? (
          <button
            type="submit"
            className="w-full bg-blue-500 text-white font-bold py-2 rounded hover:bg-blue-600 transition duration-200"
          >
            Submit to Backend
          </button>
        ) : (
          <p className="text-red-500 font-semibold text-center">
            Please set a custom price for all variants before submitting.
          </p>
        )}
      </form>
    </div>
  );
};

export default AddToStoreForm;






















// import React, { useState } from 'react';

// const AddToStoreForm = ({ setShowCreateForm, product }) => {
//     const [selectedVariants, setSelectedVariants] = useState([]);
//     const [customPrices, setCustomPrices] = useState({});
//     const [currentVariant, setCurrentVariant] = useState(null);

//     const handleVariantChange = (event) => {
//         const variantId = event.target.value;
//         const variant = findVariant(variantId);

//         if (variant && !selectedVariants.some(v => v.sizeName === variant.sizeName && v.colorName === variant.colorName)) {
//             setCurrentVariant(variant);
//         }
//     };

//     const findVariant = (variantId) => {
//         for (const colorVariant of product.colorSizeVariants) {
//             const sizeVariant = colorVariant.sizes.find(size => size.sizeName === variantId);
//             if (sizeVariant) {
//                 return {
//                     ...sizeVariant,
//                     colorName: colorVariant.colorName,
//                     image: colorVariant.image,
//                 };
//             }
//         }
//         return null;
//     };

//     const handleCustomPriceChange = (event) => {
//         if (currentVariant) {
//             setCurrentVariant(prev => ({
//                 ...prev,
//                 customPrice: event.target.value,
//             }));
//         }
//     };

//     const handleCustomPriceSubmit = () => {
//         if (currentVariant && currentVariant.customPrice) {
//             setSelectedVariants(prev => [...prev, currentVariant]);
//             setCustomPrices(prev => ({
//                 ...prev,
//                 [currentVariant.sizeName]: currentVariant.customPrice,
//             }));
//             setCurrentVariant(null); // Reset current variant after adding
//         }
//     };

//     const handleSubmit = (event) => {
//         event.preventDefault();

//         const variantsWithPrices = selectedVariants.map(variant => ({
//             ...variant,
//             customPrice: customPrices[variant.sizeName],
//         }));

//         console.log({
//             productId: product._id,
//             variants: variantsWithPrices,
//         });

//         // Reset everything
//         setSelectedVariants([]);
//         setCustomPrices({});
//         setCurrentVariant(null);
//     };

//     const allPricesSet = selectedVariants.length > 0 && selectedVariants.every(variant => customPrices[variant.sizeName]);

//     const variantsToDisplay = product.colorSizeVariants.length > 0 ? product.colorSizeVariants : product.sizeVariants;

//     return (
//         <div className="max-w-2xl mx-auto p-6 bg-white rounded-md shadow-md">
//             <h2 className="text-2xl font-semibold text-gray-700 mb-4">Add to Store</h2>
//             <form onSubmit={handleSubmit}>
//                 <div className="mb-4">
//                     <label className="block text-gray-700 mb-2">Select Variant</label>
//                     <select
//                         className="block w-full p-2 border border-gray-300 rounded"
//                         onChange={handleVariantChange}
//                         value={currentVariant ? currentVariant.sizeName : ''}
//                     >
//                         <option value="" disabled>Select a variant</option>
//                         {variantsToDisplay.map((colorVariant) =>
//                             colorVariant.sizes.map((size) => (
//                                 <option
//                                     key={`${colorVariant.colorName}-${size.sizeName}`}
//                                     value={size.sizeName}
//                                     disabled={selectedVariants.some(v => v.sizeName === size.sizeName)}
//                                 >
//                                     {colorVariant.colorName} - {size.sizeName} - Price: {size.resellerPrice} (Minimum Sold Price: {size.minimumSoldPrice})
//                                 </option>
//                             ))
//                         )}
//                     </select>
//                 </div>
//                 {currentVariant && (
//                     <div className="mb-4">
//                         <label className="block text-gray-700 mb-2">{`Set Custom Price for ${currentVariant.colorName} ${currentVariant.sizeName}`}</label>
//                         <input
//                             type="number"
//                             className="block w-full p-2 border border-gray-300 rounded"
//                             value={currentVariant.customPrice || ''}
//                             onChange={handleCustomPriceChange}
//                             placeholder="Enter custom price"
//                             required
//                         />
//                         <button
//                             type="button"
//                             className="mt-2 bg-green-500 text-white font-bold py-1 px-4 rounded hover:bg-green-600 transition duration-200"
//                             onClick={handleCustomPriceSubmit}
//                             disabled={!currentVariant.customPrice || currentVariant.customPrice <= 0}
//                         >
//                             Add Variant
//                         </button>
//                     </div>
//                 )}
//                 {allPricesSet && (
//                     <button
//                         type="submit"
//                         className="w-full bg-blue-500 text-white font-bold py-2 rounded hover:bg-blue-600 transition duration-200"
//                     >
//                         Add to Store
//                     </button>
//                 )}
//             </form>
//         </div>
//     );
// };

// export default AddToStoreForm;

















import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createCategory } from '../../features/category/categorySlice';
import { useDispatch } from 'react-redux';

const CreateCategoryForm = () => {
  const dispatch = useDispatch();
  const [previewImage, setPreviewImage] = useState(null); // State for image preview

  // Formik validation schema
  const formik = useFormik({
    initialValues: {
      name: '',
      image: '', // Store the base64 string here
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Category name is required'),
      image: Yup.string().required('An image is required'),
    }),
    onSubmit: (values, { resetForm }) => {
      // Send data as JSON, including base64 image
      dispatch(createCategory(values));
      resetForm();
      setPreviewImage(null); // Clear preview on submit
    },
  });

  // Handle image change and convert to base64
  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];

    // Use FileReader to read the file as base64
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      formik.setFieldValue('image', base64String); // Set base64 string in Formik's image field
      setPreviewImage(base64String); // Display preview
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-4">
        <label className="block text-gray-700">Category Name</label>
        <input
          type="text"
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          className="mt-1 p-2 block w-full border rounded-md"
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="text-red-500 text-sm">{formik.errors.name}</div>
        ) : null}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Category Image</label>
        <input
          type="file"
          name="image"
          onChange={handleImageChange}
          accept="image/*"
          className="mt-1 block w-full text-sm text-gray-700"
        />
        {formik.touched.image && formik.errors.image ? (
          <div className="text-red-500 text-sm">{formik.errors.image}</div>
        ) : null}
      </div>

      {/* Image Preview */}
      {previewImage && (
        <div className="mb-4">
          <label className="block text-gray-700">Image Preview:</label>
          <img src={previewImage} alt="Preview" className="w-32 h-32 object-cover mt-2 border rounded-md" />
        </div>
      )}

      <button
        type="submit"
        className="w-full text-center bg-blue-600 text-white p-2 rounded hover:bg-blue-700"
      >
        Create Category
      </button>
    </form>
  );
};

export default CreateCategoryForm;

























// import React from 'react';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import { createCategory } from '../../features/category/categorySlice';
// import { useDispatch } from 'react-redux';

// const CreateCategoryForm = () => {
//   const dispatch = useDispatch();
//   // Formik validation schema for the CreateCategoryForm
//   const formik = useFormik({
//     initialValues: {
//       name: '',
//     },
//     validationSchema: Yup.object({
//       name: Yup.string().required('Category name is required'),
//     }),
//     onSubmit: (values, { resetForm }) => {
//       // Perform the API request to create the category 
//       dispatch(createCategory(values));
//       resetForm();
//     },
//   });

//   return (
//     <form onSubmit={formik.handleSubmit}>
//       <div className="mb-4">
//         <label className="block text-gray-700">Category Name</label>
//         <input
//           type="text"
//           name="name"
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           value={formik.values.name}
//           className="mt-1 p-2 block w-full border rounded-md"
//         />
//         {formik.touched.name && formik.errors.name ? (
//           <div className="text-red-500 text-sm">{formik.errors.name}</div>
//         ) : null}
//       </div>

//       <button type="submit" className="w-full text-center bg-blue-600 text-white p-2 rounded hover:bg-blue-700">
//         Create Category
//       </button>
//     </form>
//   );
// };

// export default CreateCategoryForm;
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VideosList from "./VideosList";
import LoaderComponent from "../LoaderComponent";
import { getVideoCourses } from "../../features/videoCourse/videoCourseSlice";


const VideoCourses = () => {

    const { videoCourses,loading } = useSelector((state) => state.videoCourse);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getVideoCourses(JSON.parse(localStorage.getItem("access_token"))));
    }, [dispatch]);


    if(loading) return <LoaderComponent />

    return (
        <div className="bg-gray-100 min-h-screen">
        {
            videoCourses.length > 0 ? (
                <VideosList videos={videoCourses} />
            ) : (
                <div className="flex items-center justify-center h-screen">
                    <h1 className="text-3xl font-semibold text-gray-700">No video courses found</h1>    
                </div>
            )
        }
      </div>
    )
}

export default VideoCourses
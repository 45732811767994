// features/userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api/api';

// Async thunk for user registration
export const registerUser  = createAsyncThunk('user/register', async (userData) => {
  const response = await api.post('/auth/register', userData,{headers: {
    "Content-Type":"application/json"
  }}); 
  
  return response.data;
});

// Async thunk for user login
export const loginUser  = createAsyncThunk('user/login', async (userData) => {
  const response = await api.post('/auth/login', userData,{headers: {
    'Content-Type': 'application/json'
  }}); 
  
  return response.data;
});

// Async thunk for admin login
export const loginAdmin = createAsyncThunk('admin/adminLogin', async (adminData) => {
  const response = await api.post('/auth/admin/login', adminData,{headers: {
      'Content-Type': 'application/json'
  }});
    
  return response.data;
});

// Async thunk for reseller register and login
export const registerReseller = createAsyncThunk('reseller/resellerRegister', async (resellerData) => {
  const response = await api.post('/reseller/auth/register', resellerData,{headers: {
      "Content-Type":"application/json"
  }});
    
  return response.data;
});

export const loginReseller = createAsyncThunk('reseller/resellerLogin', async (resellerData) => {
  const response = await api.post('/reseller/auth/login', resellerData,{headers: {
      'Content-Type': 'application/json'
  }});
  
  return response.data;
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    access_token: null,
    userInfo: null,
    loading: false,
    error: null,
    isAuthenticated: false,
    isCreated: false
  },
  reducers: {
    login: (state, action) => {
      state.access_token = action.payload.access_token;
      state.isAuthenticated = true;
      localStorage.setItem("access_token", action.payload.access_token);
    },
    logout: (state) => {
      state.userInfo = null;
      state.isAuthenticated = false;
      state.access_token = null;
      state.isCreated = false;
      localStorage.removeItem('access_token');
    },
    updateUserSubscriptionOrder: (state, action) => {
      state.userInfo.subscriptionOrder = action.payload;
    },
    initializeAuth: (state) => {
      const token = JSON.parse(localStorage.getItem("access_token"));
      if (token) {
        state.access_token = token;
        state.isAuthenticated = true;
      }else{
        state.isAuthenticated = false;
        state.isCreated = false;
        state.access_token = null;
        state.userInfo = null;
      }
    },
  },
  extraReducers: (builder) => {
    // Handle registration
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload.access_token) {
          state.isCreated = true;
        }
        state.userInfo = null;
        state.isAuthenticated = true;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Handle login
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.access_token = action.payload.access_token;
        state.userInfo = action.payload.user;
        state.isAuthenticated = true;
        localStorage.setItem('access_token', JSON.stringify(action.payload.access_token));
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(loginAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload.user;
        state.access_token = action.payload.access_token;
        state.isAuthenticated = true;
        localStorage.setItem('access_token', JSON.stringify(action.payload.access_token));
      })
      .addCase(loginAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(registerReseller.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerReseller.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload.access_token) {
          state.isAuthenticated = true;
        }
      })
      .addCase(registerReseller.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(loginReseller.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginReseller.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload.user;
        state.access_token = action.payload.access_token;
        state.isAuthenticated = true;
        localStorage.setItem('access_token', JSON.stringify(action.payload.access_token));
      })
      .addCase(loginReseller.rejected, (state, action) => {
        state.loading = false;  
        state.error = action.error.message;
      })
  },
});

export const {login, logout, updateUserSubscriptionOrder, initializeAuth } = userSlice.actions;
export default userSlice.reducer;
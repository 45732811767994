import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from '../api/api';


export const getMetaData = createAsyncThunk('metaData/getMetaData', async () => {
    const response = await api.get('/meta-data');
    return response.data;
});

const metaDataSlice = createSlice({
    name: 'metaData',
    initialState: {
        metaData: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMetaData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getMetaData.fulfilled, (state, action) => {
                state.loading = false;
                state.metaData = action.payload;
            })
            .addCase(getMetaData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default metaDataSlice.reducer;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  FaEdit, FaTrash,FaPlus } from 'react-icons/fa';
import ShowModal from './ShowModal';
import { deleteVideoCourse, getAllVideoCourses } from '../../features/videoCourse/videoCourseSlice';
import VideoCourseForm from './VideoCourseForm';
import LoaderComponent from '../LoaderComponent';

const VideoCourseList = () => {

    const {videoCourseList, loading} = useSelector((state) => state.videoCourse);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedVideoCourse, setSelectedVideoCourse] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getAllVideoCourses({token:JSON.parse(localStorage.getItem("token"))}));
    }, [dispatch]);

  const handleDelete = (categoryId) => {
    if (window.confirm('Are you sure you want to delete this video course?')) {
      dispatch(deleteVideoCourse(categoryId));
      alert('Category deleted successfully!');
    }
  };

  const handleEdit = (videoCourse) => {
    setIsEditing(true);
    setSelectedVideoCourse(videoCourse);
  };    

  const closeEditModal = () => {
    setIsEditing(false);
    setSelectedVideoCourse(null);
  };

  

  const closeCreateModal = () => {
    setShowCreateForm(false);
  };


  if(loading) return <LoaderComponent />
 
  return (
    <div className="container mx-auto p-6 mb-8 h-screen">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Video Courses</h2>
        <button
          onClick={() => setShowCreateForm(true)}
          className="bg-blue-500 text-white p-2 rounded shadow-md hover:bg-blue-600 transition-all flex items-center"
        >
          <FaPlus className="mr-2" /> create video course
        </button>
      </div>

      {/* {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>} */}
      <div className='shadow-lg rounded-lg overflow-x-auto'>
        {videoCourseList.length > 0 ? (
          <table className="min-w-full bg-white border border-gray-200 rounded shadow-md">
            <thead className="bg-gray-200">
              <tr>
                <th className="py-2 px-4 border-b text-left font-semibold text-gray-700">video title</th>
                <th className="py-2 px-4 border-b text-left font-semibold text-gray-700">Description</th>
                <th className="py-2 px-4 border-b text-left font-semibold text-gray-700">url</th>
                <th className="py-2 px-4 border-b text-center font-semibold text-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody>
              {videoCourseList.map((videoCourse) => (
                <tr key={videoCourse._id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b">{videoCourse.title}</td>
                  <td className="py-2 px-4 border-b">{videoCourse.description || '-'}</td>
                  <td className="py-2 px-4 border-b">{videoCourse.url || '-'}</td>
                  <td className="py-2 px-4 border-b text-center">
                    
                    <button
                      className="text-green-500 hover:text-green-700 mx-1"
                      onClick={() => handleEdit(videoCourse)}
                    >
                      <FaEdit />
                    </button>
                    <button
                      className="text-red-500 hover:text-red-700 mx-1"
                      onClick={() => handleDelete(videoCourse._id)}
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
      ) : (
        <p className='text-center text-3xl font-semibold text-red-500'>No video courses available.</p>
      )}
      </div>
      {showCreateForm && <ShowModal ChildComponent={VideoCourseForm} setShowCreateForm={setShowCreateForm} onClose={closeCreateModal} />}
      {isEditing && selectedVideoCourse && (
        <ShowModal ChildComponent={VideoCourseForm} setShowCreateForm={setIsEditing} onClose={closeEditModal} isEditing={true} videoCourse={selectedVideoCourse} />
      )}
    </div>

  );
};

export default VideoCourseList;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiMenu } from "react-icons/fi";
import Card from "./Card";
import Sidebar from "../common/Sidebar";
import OrdersList from "./OrdersList";
import ProductsList from "./ProductsList";
import UsersList from "./UsersList";
import CategoriesList from "./CategoriesList";
import ResellersList from "./ResellersList";
import ExecutiveList from "./ExecutiveList";
import PackageList from "./PackageList";
import HeadlineList from "./HeadlineList";
import { getDashboard } from "../../features/dashboard/dashboardSlice";
import LoaderComponent from "../LoaderComponent";
import VideoCourseList from "./VideoCourseList";
import SubscriptionOrdersList from "./SubscriptionOrdersList";
import AffiliateList from "./AffiliateList";
import ContactList from "./ContactList";
import OrderBonusList from "./OrderBonusList";
import SliderImageList from "./SliderImageList";
import ReferAmountList from "./ReferAmountList";
import ServiceChargeList from "./ServiceChargeList";
import ProductRequestList from "../common/ProductRequestList";
import WithdrawalRequestsList from "./WithdrawalRequestList";
import ReviewList from "./ReviewList";
import PaymentOptionsList from "./PaymentOptionsList";
import Offer from "./Offer";

// menuItems
const menuItems = [
  { id: "dashboard", label: "Dashboard" },
  { id: "orders", label: "Orders" },
  { id: "products", label: "Products" },
  { id: "create-offers", label: "Create Offers" },
  { id: "users", label: "Users" },
  { id: "categories", label: "Categories" },
  { id: "resellers", label: "Resellers" },
  { id: "executives", label: "Executives" },
  { id: "packages", label: "Packages" },
  { id: "headline", label: "Headline" },
  { id: "video-courses", label: "Video Courses" },
  { id: "subscription-orders", label: "Subscription Orders" },
  { id: "affiliates", label: "Affiliates" },
  { id: "managing-directors", label: "Managing Directors" },
  { id: "order-bonus", label: "Order Bonus" },
  { id: "slider-images", label: "Slider Images" },
  { id: "refer-amounts", label: "Refer Amounts" },
  { id: "service-charges", label: "Service Charges" },
  { id: "contacts", label: "Contacts" },
  { id: "product-requests", label: "Product Requests" },
  { id: "withdrawal-requests", label: "Withdrawal Requests" },
  { id: "reviews", label: "Reviews" },
  { id: "payment-options", label: "Payment Options" },
];

const Dashboard = () => {
  const [selectedSection, setSelectedSection] = useState("dashboard"); // Default section

  const { dashboard, loading } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashboard(JSON.parse(localStorage.getItem("access_token"))));
  }, [dispatch]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (loading) return <LoaderComponent />;
  return (
    <div className="flex">
      {/* Sidebar */}
      <Sidebar
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
        menuItems={menuItems}
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />

      {/* Main content */}
      <div className="flex-1 overflow-y-auto ">
        {/* Toggle Button for Mobile View */}
        <div className="flex items-center justify-center w-full bg-blue-600 p-4">
          <button
            onClick={toggleSidebar}
            className="inline-flex lg:hidden text-white mr-4"
          >
            <FiMenu size={24} />
          </button>
          <h1 className="text-3xl text-white font-semibold">
            Admin Dashboard
          </h1>
        </div>

        {/* Conditionally render based on selected section */}
        {selectedSection === "dashboard" && (
          <div className="grid grid-cols-3 gap-6 mt-6">
            {dashboard.map((item, index) => (
              <Card key={index} title={item.title} count={item.count} />
            ))}
          </div>
        )}
        {selectedSection === "orders" && <OrdersList />}
        {selectedSection === "products" && <ProductsList />}
        {selectedSection === "create-offers" && <Offer />}
        {selectedSection === "users" && <UsersList />}
        {selectedSection === "categories" && <CategoriesList />}
        {selectedSection === "resellers" && <ResellersList />}
        {selectedSection === "managing-directors" && <ExecutiveList />}
        {selectedSection === "packages" && <PackageList />}
        {selectedSection === "headlines" && <HeadlineList />}
        {selectedSection === "video-courses" && <VideoCourseList />}
        {selectedSection === "subscription-orders" && (
          <SubscriptionOrdersList />
        )}
        {selectedSection === "affiliates" && <AffiliateList />}
        {selectedSection === "contacts" && <ContactList />}
        {selectedSection === "order-bonus" && <OrderBonusList />}
        {selectedSection === "headline" && <HeadlineList />}
        {selectedSection === "executives" && <ExecutiveList />}
        {selectedSection === "slider-images" && <SliderImageList />}
        {selectedSection === "refer-amounts" && <ReferAmountList />}
        {selectedSection === "service-charges" && <ServiceChargeList />}
        {selectedSection === "product-requests" && (
          <ProductRequestList from="admin" />
        )}
        {selectedSection === "withdrawal-requests" && <WithdrawalRequestsList />}

        {selectedSection === "reviews" && <ReviewList />}
        {selectedSection === "payment-options" && <PaymentOptionsList />}

        {/* Add more conditions for other sections (e.g., affiliates, resellers) */}
      </div>
    </div>
  );
};

export default Dashboard;

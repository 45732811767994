import React, { useState, useRef } from 'react';

const TermsAndConditions = () => {
  const [isOpen, setIsOpen] = useState(null);

  // Create references for each section
  const introRef = useRef(null);
  const useOfServiceRef = useRef(null);
  const userResponsibilitiesRef = useRef(null);
  const privacyPolicyRef = useRef(null);
  const limitationOfLiabilityRef = useRef(null);
  const terminationOfServiceRef = useRef(null);
  const changesToTermsRef = useRef(null);
  const governingLawRef = useRef(null);

  // Scroll to section when clicked
  const scrollToSection = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - 100, // 100px for top offset
      behavior: 'smooth',
    });
  };

  const toggleSection = (index) => {
    setIsOpen(isOpen === index ? null : index);
  };

  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-indigo-600 to-blue-500 text-white py-20">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl md:text-5xl font-bold leading-tight mb-4">Terms and Conditions</h1>
          <p className="text-lg md:text-xl mb-8">Please carefully review the following terms and conditions before using our service.</p>
        </div>
      </section>

      {/* Table of Contents */}
      <section className="container mx-auto py-12 px-6 md:px-12">
        <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg space-y-6">
          <h2 className="text-2xl font-semibold text-gray-800">Table of Contents</h2>
          <ul className="list-none space-y-2 text-gray-600">
            {['Introduction', 'Use of Service', 'User Responsibilities', 'Privacy Policy', 'Limitation of Liability', 'Termination of Service', 'Changes to Terms', 'Governing Law'].map((item, index) => (
              <li key={index}>
                <button
                  onClick={() => {
                    // Scroll to corresponding section
                    const refs = [introRef, useOfServiceRef, userResponsibilitiesRef, privacyPolicyRef, limitationOfLiabilityRef, terminationOfServiceRef, changesToTermsRef, governingLawRef];
                    scrollToSection(refs[index]);
                  }}
                  className="text-blue-600 hover:text-blue-800 font-medium transition-all ease-in-out duration-300 transform hover:scale-105"
                >
                  {item}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </section>

      {/* Terms and Conditions Content */}
      <section className="container mx-auto py-12 px-6 md:px-12">
        <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg space-y-6">
          {/* Section 1: Introduction */}
          <div
            ref={introRef}
            className={`transition-all duration-300 rounded-lg ${isOpen === 0 ? 'bg-blue-100' : 'bg-white'}`}
            onClick={() => toggleSection(0)}
          >
            <h3 className="text-xl font-semibold text-gray-800 cursor-pointer p-4">
              Introduction
            </h3>
            {isOpen === 0 && (
              <p className="text-gray-600 p-4">
                These terms and conditions outline the rules and regulations for the use of our service. By accessing or using the service, you agree to comply with these terms.
              </p>
            )}
          </div>

          {/* Section 2: Use of Service */}
          <div
            ref={useOfServiceRef}
            className={`transition-all duration-300 rounded-lg ${isOpen === 1 ? 'bg-blue-100' : 'bg-white'}`}
            onClick={() => toggleSection(1)}
          >
            <h3 className="text-xl font-semibold text-gray-800 cursor-pointer p-4">
              Use of Service
            </h3>
            {isOpen === 1 && (
              <p className="text-gray-600 p-4">
                You may use our service for personal and non-commercial purposes only. Any unauthorized use of the service may result in suspension or termination of your account.
              </p>
            )}
          </div>

          {/* Section 3: User Responsibilities */}
          <div
            ref={userResponsibilitiesRef}
            className={`transition-all duration-300 rounded-lg ${isOpen === 2 ? 'bg-blue-100' : 'bg-white'}`}
            onClick={() => toggleSection(2)}
          >
            <h3 className="text-xl font-semibold text-gray-800 cursor-pointer p-4">
              User Responsibilities
            </h3>
            {isOpen === 2 && (
              <p className="text-gray-600 p-4">
                You are responsible for maintaining the confidentiality of your account information. You agree to notify us immediately if you suspect any unauthorized use of your account.
              </p>
            )}
          </div>

          {/* Section 4: Privacy Policy */}
          <div
            ref={privacyPolicyRef}
            className={`transition-all duration-300 rounded-lg ${isOpen === 3 ? 'bg-blue-100' : 'bg-white'}`}
            onClick={() => toggleSection(3)}
          >
            <h3 className="text-xl font-semibold text-gray-800 cursor-pointer p-4">
              Privacy Policy
            </h3>
            {isOpen === 3 && (
              <p className="text-gray-600 p-4">
                We respect your privacy. Please refer to our Privacy Policy for detailed information on how we collect, use, and protect your personal information.
              </p>
            )}
          </div>

          {/* Section 5: Limitation of Liability */}
          <div
            ref={limitationOfLiabilityRef}
            className={`transition-all duration-300 rounded-lg ${isOpen === 4 ? 'bg-blue-100' : 'bg-white'}`}
            onClick={() => toggleSection(4)}
          >
            <h3 className="text-xl font-semibold text-gray-800 cursor-pointer p-4">
              Limitation of Liability
            </h3>
            {isOpen === 4 && (
              <p className="text-gray-600 p-4">
                Our liability is limited to the maximum extent permitted by law. We are not liable for any indirect, incidental, or consequential damages arising from the use of our service.
              </p>
            )}
          </div>

          {/* Section 6: Termination of Service */}
          <div
            ref={terminationOfServiceRef}
            className={`transition-all duration-300 rounded-lg ${isOpen === 5 ? 'bg-blue-100' : 'bg-white'}`}
            onClick={() => toggleSection(5)}
          >
            <h3 className="text-xl font-semibold text-gray-800 cursor-pointer p-4">
              Termination of Service
            </h3>
            {isOpen === 5 && (
              <p className="text-gray-600 p-4">
                We reserve the right to suspend or terminate your access to the service at any time, without notice, for any violation of these terms and conditions.
              </p>
            )}
          </div>

          {/* Section 7: Changes to Terms */}
          <div
            ref={changesToTermsRef}
            className={`transition-all duration-300 rounded-lg ${isOpen === 6 ? 'bg-blue-100' : 'bg-white'}`}
            onClick={() => toggleSection(6)}
          >
            <h3 className="text-xl font-semibold text-gray-800 cursor-pointer p-4">
              Changes to Terms
            </h3>
            {isOpen === 6 && (
              <p className="text-gray-600 p-4">
                We may update these terms at any time. All changes will be posted on this page, and your continued use of the service constitutes acceptance of the new terms.
              </p>
            )}
          </div>

          {/* Section 8: Governing Law */}
          <div
            ref={governingLawRef}
            className={`transition-all duration-300 rounded-lg ${isOpen === 7 ? 'bg-blue-100' : 'bg-white'}`}
            onClick={() => toggleSection(7)}
          >
            <h3 className="text-xl font-semibold text-gray-800 cursor-pointer p-4">
              Governing Law
            </h3>
            {isOpen === 7 && (
              <p className="text-gray-600 p-4">
                These terms and conditions are governed by the laws of the jurisdiction where our company is registered. Any disputes will be resolved in the courts of that jurisdiction.
              </p>
            )}
          </div>
        </div>
      </section>

    </div>
  );
};

export default TermsAndConditions;



const ShowResellerDetails = ({selectedReseller, setShowDetails}) => {

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <h3 className="text-xl font-semibold mb-4">User Details</h3>
            <p><strong>User ID:</strong> {selectedReseller.id}</p>
            <p><strong>Name:</strong> {selectedReseller.name}</p>
            <p><strong>Email:</strong> {selectedReseller.email}</p>
            <p><strong>Role:</strong> {selectedReseller.phone}</p>
            <p><strong>Subscription:</strong> {selectedReseller.subscription}</p>
            <p><strong>Subscription Status:</strong> {selectedReseller.subscriptionStatus ? "true" : "false"}</p>
            <p><strong>total Earnings:</strong> {selectedReseller.total_earnings}</p>
            
            <button
              onClick={() => setShowDetails(false)}
              className="mt-4 bg-blue-600 text-white p-2 rounded hover:bg-blue-700 transition-all"
            >
              Close
            </button>
          </div>
        </div>
      )
};


export default ShowResellerDetails;
import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FaRegClipboard } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { updateSubscriptionOrder } from "../../features/subscriptionOrder/subscriptionOrderSlice";
import { getPaymentOptions } from "../../features/paymentOptions/paymentOptionSlice";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../../components/LoaderComponent";

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  provider:Yup.string().required('provider Type is required'),
  transactionId: Yup.string()
    .matches(/\d/, "invalid transaction id")
    .matches(/[A-Z]/, "invalid transaction id")
    .min(8, "invalid transaction id")
    .max(14, "invalid transaction id")
    .required("transaction id is required"),
});

const ManualPayment = () => {

    const { paymentOptions } = useSelector((state) => state.paymentOptions);
    const { updatedSubscriptionOrder,loading } = useSelector((state) => state.subscriptionOrders);
    const location = useLocation();
    const { singleSubscriptionOrder } = location.state || {};

    const [copied, setCopied] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {name:packageName} = useParams();

    const handleCopyClick = () => {
      navigator.clipboard.writeText(singleSubscriptionOrder?.paymentId)
        .then(() => {
          setCopied(true);
          setTimeout(() => {
              setCopied(false);
          }, 2000);
        })
        .catch((error) => {
          //@TODO handle error
          console.error('Copy failed', error);
        });
    };

    // get payment options when component mounts
    useEffect(() => {
      dispatch(getPaymentOptions());
      }, [dispatch]);

    useEffect(() => {
      if(updatedSubscriptionOrder && updatedSubscriptionOrder.manualPaymentId){
        navigate(`/reseller/package/${packageName}/order/manual-payment/success/${updateSubscriptionOrder?.paymentId}`);
      }
    },[updatedSubscriptionOrder, packageName, navigate]);

   
   
    const handleSubmit = (values) => {
      const paymentDetails = {
        provider: values.provider,
        manualPaymentId: values.transactionId,        
      }
      dispatch(updateSubscriptionOrder({ subscriptionOrderId: singleSubscriptionOrder?._id, subscriptionOrderData: paymentDetails }));

    }


    if (loading) return <LoaderComponent />

    return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
        <h2 className="text-2xl text-center bg-green-900 text-white p-2 font-bold mb-6">Manual Payment</h2>
        <h1 className="text-3xl text-center font-bold p-2 mb-2 text-red-900">Disclaimer</h1>

        <p className="text-lg text-center p-2 text-red-500 italic">Hi! Welcome to our manual payment section. before you proceed, please make sure that you have entered your transaction id.Your transaction id will be verified by our team. After verification, we will process your payment. Please make sure that you have entered the correct transaction id. If you have any questions, please contact our support team. </p>
        <p className="text-lg text-center p-2 text-red-500 italic">Chose your payment method. Then Go to your chosen
            provider and make payment. </p>
        <p className="text-lg text-center p-2 text-red-500 italic">Copy your invoice id. when you make payment, paste it in reference field. Then make payment. Thank you </p>
        <div className="grid grid-cols-3 border-b p-4 mb-2">
           {
              paymentOptions.map((option) => (
                <div className="text-center bg-green-200 shadow-lg p-6">
                <p>Provider: {option.provider?.charAt(0).toUpperCase() + option.provider.slice(1)}</p>
                <p>Number: {option.accountNumber}</p>
            </div>
              ))
           }
            
        </div>
        {/* invoice id copied section */}
        <div className="flex items-center justify-between border rounded-lg p-2 bg-gray-700 w-full">
          <span className="text-lg font-mono text-white">your invoice id: {singleSubscriptionOrder?.paymentId}</span>
          <button
            onClick={handleCopyClick}
            className="text-blue-500 hover:text-blue-700 transition-colors duration-200 flex items-center"
          >
            {copied ? (
          <FaCheck className="text-green-500" />
        ) : (
          <FaRegClipboard />
        )}
          </button>
        </div>
        <Formik
          initialValues={{provider: '', transactionId: ''}}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form className="space-y-4">
              <div>
              <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="provider">
                Provider Name
              </label>
              <Field
                as="select"
                name="provider"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
              >
                {/* <option value="nagad" label="Nagad" />
                <option value="bkash" label="Bkash" />
                <option value="rocket" label="Rocket" /> */}
                  <option value="" label="Select provider" />
                {
                  paymentOptions.length > 0 && paymentOptions.map((option) => (
                    <option key={option._id} value={option.provider} label={option.provider.toUpperCase()} />
                  ))
                }
              </Field>
              <ErrorMessage
                name="provider"
                component="div"
                className="text-sm text-red-600 mt-1"
              />
            </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="transactionId">
                  TransactionId
                </label>
                <Field
                  name="transactionId"
                  type="text"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
                  placeholder="Enter your transaction id"
                />
                <ErrorMessage
                  name="transactionId"
                  component="div"
                  className="text-sm text-red-600 mt-1"
                />
              </div>
              
              <button
                type="submit"
                className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-200"
              >
                Submit
              </button>
            </Form>
          )}
        </Formik>
    </div>
    );
};

export default ManualPayment;
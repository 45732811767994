import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api/api';

// Create offers by category
export const createOfferByCategory = createAsyncThunk(
  'offer/createOfferByCategory',
  async (categoryId) => {
    const response = await api.post('/offers/category', { categoryId });
    return response.data;
  }
);

// Create offers for all products
export const createOfferAllProducts = createAsyncThunk(
  'offer/createOfferAllProducts',
  async () => {
    const response = await api.post('/offers/all');
    return response.data;
  }
);

// Create offers for specific products
export const createOfferSpecificProducts = createAsyncThunk(
  'offer/createOfferSpecificProducts',
  async (productIds) => {
    const response = await api.post('/offers/specific', { productIds });
    return response.data;
  }
);

// Stop offer
export const stopOffer = createAsyncThunk(
  'offer/stopOffer',
  async () => {
    const response = await api.post('/offers/stop');
    return response.data;
  }
);

const offerSlice = createSlice({
  name: 'offer',
  initialState: {
    status: 'idle',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOfferByCategory.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createOfferAllProducts.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createOfferSpecificProducts.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(stopOffer.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createOfferByCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default offerSlice.reducer;

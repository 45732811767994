import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaTrash,FaPlus } from 'react-icons/fa';
import { getAllHeadlines, deleteHeadline } from '../../features/headline/headlineSlice';

import ShowHeadlineForm from './ShowHeadlineForm';
import LoaderComponent from '../LoaderComponent';

const HeadlineList = () => {

  const { headlines, loading, error } = useSelector((state) => state.headline);

const [showCreateForm, setShowCreateForm] = useState(false);
const dispatch = useDispatch();

// fetch headlines on mount

useEffect(() => {
  dispatch(getAllHeadlines());
}, [dispatch]);

  const handleDelete = (headlineId) => {
    if (window.confirm('Are you sure you want to delete this headline?')) {
      dispatch(deleteHeadline(headlineId));
    }
  };

  if(loading) return <LoaderComponent message="Loading categories..." />
  if(error) return <p className="text-red-500">{error}</p>

  return (
    <div className="container mx-auto p-6 mb-8 h-screen ">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Headlines</h2>
        <button
          onClick={() => setShowCreateForm(true)}
          className="bg-blue-500 text-white p-2 rounded shadow-md hover:bg-blue-600 transition-all flex items-center"
        >
          <FaPlus className="mr-2" /> create headlines
        </button>
      </div>

      {/* {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>} */}
      <div className='shadow-lg rounded-lg overflow-x-auto'>
        {headlines.length > 0 ? (
          <table className="min-w-full bg-white border border-gray-200 rounded shadow-md">
            <thead className="bg-gray-200">
              <tr>
                <th className="py-2 px-4 border-b text-left font-semibold text-gray-700">Description</th>
                <th className="py-2 px-4 border-b text-center font-semibold text-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody>
              {headlines.map((headline) => (
                <tr key={headline.id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b">{headline.headline || '-'}</td>
                  <td className="py-2 px-4 border-b text-center">
                    
                    {/* <button
                      className="text-green-500 hover:text-green-700 mx-1"
                      onClick={() => console.log(`Editing category ${headline._id}`)}
                    >
                      <FaEdit />
                    </button> */}
                    <button
                      className="text-red-500 hover:text-red-700 mx-1"
                      onClick={() => handleDelete(headline._id)}
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
      ) : (
        <p>No headlines available.</p>
      )}
      </div>
      {showCreateForm && <ShowHeadlineForm setShowHeadlineForm={setShowCreateForm} />}
    </div>

  );
};

export default HeadlineList;

// import React from "react";

// const ProductDetails = ({ selectedProduct, se }) => {
//   if (!selectedProduct) {
//     return (
//       <div className="p-6 bg-gray-100 min-h-screen flex items-center justify-center">
//         <p className="text-gray-600 text-lg">No product selected</p>
//       </div>
//     );
//   }

//   const {
//     title,
//     description,
//     category,
//     ratings,
//     inOffer,
//     upcoming,
//     forReseller,
//     colorSizeVariants,
//     sizeVariants,
//   } = selectedProduct;

//   return (
//     <div className="p-6 bg-gray-100 min-h-screen">
//       {/* Product Header */}
//       <div className="bg-white shadow-md rounded-lg p-6">
//         <h1 className="text-2xl font-bold text-gray-800">{title}</h1>
//         <p className="text-gray-600 text-sm mt-2">
//           Created on: <span className="font-medium">{selectedProduct.createdAt}</span>
//         </p>
//       </div>

//       {/* Product Overview */}
//       <div className="mt-6 bg-white shadow-md rounded-lg p-6">
//         <h2 className="text-lg font-semibold text-gray-800 mb-4">Product Overview</h2>
//         <div className="grid grid-cols-2 gap-4">
//           <div>
//             <p>
//               <strong>Category:</strong>{" "}
//               <span className="text-gray-600">{category?.join(", ") || "N/A"}</span>
//             </p>
//             <p>
//               <strong>Description:</strong>{" "}
//               <span className="text-gray-600">{description || "No description provided."}</span>
//             </p>
//           </div>
//           <div>
//             <p>
//               <strong>Ratings:</strong>{" "}
//               <span className="text-yellow-500">{ratings?.rate || 0} ★</span> (
//               {ratings?.count || 0} reviews)
//             </p>
//             <p>
//               <strong>Status:</strong>
//               {inOffer && (
//                 <span className="px-2 py-1 bg-green-100 text-green-800 rounded-md ml-2">
//                   In Offer
//                 </span>
//               )}
//               {upcoming && (
//                 <span className="px-2 py-1 bg-blue-100 text-blue-800 rounded-md ml-2">
//                   Upcoming
//                 </span>
//               )}
//               {forReseller && (
//                 <span className="px-2 py-1 bg-yellow-100 text-yellow-800 rounded-md ml-2">
//                   For Reseller
//                 </span>
//               )}
//             </p>
//           </div>
//         </div>
//       </div>

//       {/* Variant Details */}
//       <div className="mt-6 bg-white shadow-md rounded-lg p-6">
//         <h2 className="text-lg font-semibold text-gray-800 mb-4">Variants</h2>
//         {colorSizeVariants && colorSizeVariants.length > 0 ? (
//           <div>
//             <h3 className="text-md font-bold text-gray-700 mb-2">Color-Size Variants</h3>
//             <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
//               {colorSizeVariants.map((variant, index) => (
//                 <div key={index} className="p-4 border rounded-lg">
//                   <img
//                     src={variant.image}
//                     alt={variant.colorName}
//                     className="w-full h-24 object-cover rounded-md mb-3"
//                   />
//                   <p>
//                     <strong>Color:</strong>{" "}
//                     <span className="text-gray-600">{variant.colorName}</span>
//                   </p>
//                   <div className="mt-2">
//                     <h4 className="text-sm font-semibold">Sizes:</h4>
//                     <ul className="list-disc list-inside">
//                       {variant.sizes.map((size, sizeIndex) => (
//                         <li key={sizeIndex}>
//                           {size.sizeName} - ${size.price} (Stock: {size.stock})
//                         </li>
//                       ))}
//                     </ul>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         ) : sizeVariants && sizeVariants.length > 0 ? (
//           <div>
//             <h3 className="text-md font-bold text-gray-700 mb-2">Size-Quantity Variants</h3>
//             <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
//               {sizeVariants.map((variant, index) => (
//                 <div key={index} className="p-4 border rounded-lg">
//                   <img
//                     src={variant.image}
//                     alt={variant.sizeName}
//                     className="w-full h-24 object-cover rounded-md mb-3"
//                   />
//                   <p>
//                     <strong>Size:</strong>{" "}
//                     <span className="text-gray-600">{variant.sizeName}</span>
//                   </p>
//                   <p>
//                     <strong>Price:</strong>{" "}
//                     <span className="text-gray-600">${variant.price}</span>
//                   </p>
//                   <p>
//                     <strong>Offer Price:</strong>{" "}
//                     <span className="text-green-600">${variant.offerPrice || "N/A"}</span>
//                   </p>
//                   <p>
//                     <strong>Affiliate Commission:</strong>{" "}
//                     <span className="text-gray-600">${variant.affiliateCommission || "N/A"}</span>
//                   </p>
//                   <p>
//                     <strong>Stock:</strong>{" "}
//                     <span className="text-gray-600">{variant.stock}</span>
//                   </p>
//                 </div>
//               ))}
//             </div>
//           </div>
//         ) : (
//           <p className="text-gray-600">No variants available for this product.</p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ProductDetails;










const ProductDetails = ({ selectedProduct, setShowDetails }) => {

  if (!selectedProduct) {
    return (
      <div className="p-6 bg-gray-100 min-h-screen flex items-center justify-center">
        <p className="text-gray-600 text-lg">No product selected</p>
      </div>
    );
  }

  const {
    title,
    description,
    category,
    ratings,
    inOffer,
    upcoming,
    forReseller,
    colorSizeVariants,
    sizeVariants,
  } = selectedProduct;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-2/3 max-h-[80vh] overflow-auto">
        <h3 className="text-xl font-semibold mb-4">Product Details</h3>
         {/* Product Header */}
      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-2xl font-bold text-gray-800">{title}</h1>
        <p className="text-gray-600 text-sm mt-2">
          Created on: <span className="font-medium">{selectedProduct.createdAt}</span>
        </p>
      </div>

      {/* Product Overview */}
      <div className="mt-6 bg-white shadow-md rounded-lg p-6">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">Product Overview</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <p>
              <strong>Category:</strong>{" "}
              <span className="text-gray-600">{category?.join(", ") || "N/A"}</span>
            </p>
            <p>
              <strong>Description:</strong>{" "}
              <span className="text-gray-600">{description || "No description provided."}</span>
            </p>
          </div>
          <div>
            <p>
              <strong>Ratings:</strong>{" "}
              <span className="text-yellow-500">{ratings?.rate || 0} ★</span> (
              {ratings?.count || 0} reviews)
            </p>
            <p>
              <strong>Status:</strong>
              {inOffer && (
                <span className="px-2 py-1 bg-green-100 text-green-800 rounded-md ml-2">
                  In Offer
                </span>
              )}
              {upcoming && (
                <span className="px-2 py-1 bg-blue-100 text-blue-800 rounded-md ml-2">
                  Upcoming
                </span>
              )}
              {forReseller && (
                <span className="px-2 py-1 bg-yellow-100 text-yellow-800 rounded-md ml-2">
                  For Reseller
                </span>
              )}
            </p>
          </div>
        </div>
      </div>

      {/* Variant Details */}
      <div className="mt-6 bg-white shadow-md rounded-lg p-6">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">Variants</h2>
        {colorSizeVariants && colorSizeVariants.length > 0 ? (
          <div>
            <h3 className="text-md font-bold text-gray-700 mb-2">Color-Size Variants</h3>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              {colorSizeVariants.map((variant, index) => (
                <div key={index} className="p-4 border rounded-lg">
                  <div className="w-full h-32 overflow-hidden rounded-md mb-3">
                    <img
                      src={variant.image}
                      alt={variant.sizeName}
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <p>
                    <strong>Color:</strong>{" "}
                    <span className="text-gray-600">{variant.colorName}</span>
                  </p>
                  <div className="mt-2">
                    <h4 className="text-sm font-semibold">Sizes:</h4>
                    <ul className="list-disc list-inside">
                      {variant.sizes.map((size, sizeIndex) => (
                        <li key={sizeIndex}>
                          {size.sizeName} - Price: TK.{size.price} Offer Price: TK.{size.offerPrice} Reseller Price: TK.{size.resellerPrice} Affiliate Commission: {size.affiliateCommission}% (Stock: {size.stock})
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : sizeVariants && sizeVariants.length > 0 ? (
          <div>
            <h3 className="text-md font-bold text-gray-700 mb-2">Size-Quantity Variants</h3>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              {sizeVariants.map((variant, index) => (
                <div key={index} className="p-4 border rounded-lg">
                  <div className="w-full h-32 overflow-hidden rounded-md mb-3">
                    <img
                      src={variant.image}
                      alt={variant.sizeName}
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <p>
                    <strong>Size:</strong>{" "}
                    <span className="text-gray-600">{variant.sizeName}</span>
                  </p>
                  <p>
                    <strong>Price:</strong>{" "}
                    <span className="text-gray-600">Tk. {variant.price}</span>
                  </p>
                  <p>
                    <strong>Offer Price:</strong>{" "}
                    <span className="text-green-600">TK. {variant.offerPrice || "N/A"}</span>
                  </p>
                  <p>
                    <strong>Affiliate Commission:</strong>{" "}
                    <span className="text-gray-600"> {variant.affiliateCommission || "N/A"}% </span>
                  </p>
                  <p>
                    <strong>Stock:</strong>{" "}
                    <span className="text-gray-600">{variant.stock}</span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p className="text-gray-600">No variants available for this product.</p>
        )}
      </div>

        <button
          onClick={() => setShowDetails(false)}
          className="mt-4 bg-blue-600 text-white p-2 rounded hover:bg-blue-700 transition-all"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ProductDetails;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";

// get all commissions by affiliate -- this is hit the backend to get all commissions for the affiliate getting by affiliate referral -related with orders and commissions
export const getAllReferralsByAffiliate = createAsyncThunk(
  "allReferral/getAllReferralsByAffiliate",
  async (token) => {
    const response = await api.get("/referrals/affiliates", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  }
);

// get all referrals by affiliate -- this is hit the backend to get all referrals for the affiliate getting by affiliate referral -related with to refer a user to become an affiliate
export const getAllReferCommission = createAsyncThunk(
  "allReferral/getAllReferCommission",
  async (token) => {
    const response = await api.get("/refer-commissions/affiliates", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  }
);

// get all refer commission by reseller -- this is hit the backend to get all referrals for the reseller getting by reseller referral -related with to refer a user to become a reseller
export const getAllReferCommissionByReseller = createAsyncThunk(
  "allReferral/getAllReferCommissionByReseller",
  async (token) => {
    const response = await api.get("/refer-commissions/resellers", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  }
)

// get all reseller commission by reseller -- this is hit the backend to get all commissions for the reseller getting by reseller referral -related with  orders from store and commissions
export const getAllResellerCommission = createAsyncThunk(
  "allReferral/getAllResellerCommission",
  async (token) => {
    const response = await api.get("/resellers/commissions", {
      headers: { Authorization: `Bearer ${token}` },    
    });
    return response.data;
    
  });

const allReferralSlice = createSlice({
  name: "allReferral",
  initialState: {
    allReferralsByAffiliate: [],
    allReferCommission: [],
    allReferCommissionByReseller: [],
    allResellerCommission: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllReferralsByAffiliate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllReferralsByAffiliate.fulfilled, (state, action) => {
        state.loading = false;
        state.allReferralsByAffiliate = action.payload;
        state.error = null;
      })
      .addCase(getAllReferralsByAffiliate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllReferCommission.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllReferCommission.fulfilled, (state, action) => {
        state.loading = false;
        state.allReferCommission = action.payload;
        state.error = null;
      })
      .addCase(getAllReferCommission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllReferCommissionByReseller.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllReferCommissionByReseller.fulfilled, (state, action) => {
        state.loading = false;
        state.allReferCommissionByReseller = action.payload;
        state.error = null;
      })
      .addCase(getAllReferCommissionByReseller.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllResellerCommission.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllResellerCommission.fulfilled, (state, action) => {
        state.loading = false;
        state.allResellerCommission = action.payload;
      })
      .addCase(getAllResellerCommission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

  },
});

export default allReferralSlice.reducer;

import CreateCategoryForm from "./CreateCategoryForm";

const ShowCreateCategoryFormModal = ({setShowCreateForm}) => {
    
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3 max-h-[90vh] overflow-auto">
            <h3 className="text-xl text-center bg-green-900 text-white p-2 font-semibold mb-4">Create Create Categories</h3>
            <CreateCategoryForm />
            <button
              onClick={() => setShowCreateForm(false)}
              className="w-full mt-4 bg-red-600 text-white text-center p-2 rounded hover:bg-red-700 transition-all"
            >
              Cancel
            </button>
          </div>
        </div>
      )
};


export default ShowCreateCategoryFormModal
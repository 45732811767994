const Card = ({ title="product title", count = 14 }) => {
    return (
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-lg font-semibold">{title}</h2>
        <p className="mt-2 text-3xl font-bold">{count}</p>
      </div>
    );
  };
  
  export default Card;
  
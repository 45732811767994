import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  createOfferByCategory,
  createOfferAllProducts,
  createOfferSpecificProducts,
  stopOffer,
} from "../../features/offers/offerSlice";
import { fetchCategories } from "../../features/category/categorySlice";
import { fetchProductsForOffer } from "../../features/products/productsSlice";
import LoaderComponent from "../LoaderComponent";

const Offer = () => {
  const dispatch = useDispatch();
  const { productsAvailableForOffer, loading } = useSelector(
    (state) => state.products
  );
  const { categories } = useSelector((state) => state.category);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    dispatch(fetchProductsForOffer());
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleSuccess = (message) => {
    setSuccessMessage(message);
    setTimeout(() => setSuccessMessage(""), 3000); // Clear message after 3 seconds
  };

  const handleOfferByCategory = () => {
    dispatch(createOfferByCategory(selectedCategory.value)).then(() => {
      handleSuccess("Offer created for the selected category!");
      setSelectedCategory(null); // Reset form
    });
  };

  const handleOfferAllProducts = () => {
    dispatch(createOfferAllProducts()).then(() =>
      handleSuccess("Offer created for all products!")
    );
  };

  const handleOfferSpecificProducts = () => {
    const productIds = selectedProducts.map((product) => product.value);
    dispatch(createOfferSpecificProducts(productIds)).then(() => {
      handleSuccess("Offer created for the selected products!");
      setSelectedProducts([]); // Reset form
    });
  };

  const handleStopOffer = () => {
    dispatch(stopOffer()).then(() => handleSuccess("All offers stopped!"));
  };

  if (loading) return <LoaderComponent />;

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold text-center mb-8">Manage Offers</h1>

      {successMessage && (
        <div className="bg-green-100 text-green-800 p-4 rounded mb-6">
          {successMessage}
        </div>
      )}

      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Offer by Category</h2>
        <Select
          options={categories.map((cat) => ({
            value: cat._id,
            label: cat.name,
          }))}
          value={selectedCategory}
          onChange={(selectedOption) =>
            setSelectedCategory(selectedOption || null)
          }
          placeholder="Select a Category"
          className="mb-4"
        />
        <button
          onClick={handleOfferByCategory}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          disabled={!selectedCategory}
        >
          Create Offer by Category
        </button>
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Offer for All Products</h2>
        <button
          onClick={handleOfferAllProducts}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Create Offer for All Products
        </button>
      </div>

      {productsAvailableForOffer?.length > 0 && (
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">
            Offer for Specific Products
          </h2>
          <Select
            options={productsAvailableForOffer.map((prod) => ({
              value: prod._id,
              label: prod.title,
            }))}
            value={selectedProducts}
            onChange={(selectedOptions) =>
              setSelectedProducts(selectedOptions || [])
            }
            isMulti
            placeholder="Select Products"
            className="mb-4"
          />
          <button
            onClick={handleOfferSpecificProducts}
            className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600"
            disabled={selectedProducts.length === 0}
          >
            Create Offer for Specific Products
          </button>
        </div>
      )}

      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Stop Offer</h2>
        <button
          onClick={handleStopOffer}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
        >
          Stop Offer
        </button>
      </div>
    </div>
  );
};

export default Offer;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllOrdersByAdmin,
  updateOrderStatus,
} from "../../features/orders/ordersSlice";
import {  FaTrash, FaEye, FaSort } from "react-icons/fa";
import LoaderComponent from "../LoaderComponent";
import Pagination from "../Pagination";

const OrdersList = () => {
  const { orders,pagination, loading } = useSelector((state) => state.orders);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [filterStatus, setFilterStatus] = useState("all");
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersByAdmin({ page }));
  }, [dispatch,page]);


  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleDelete = (orderId) => {
    if (window.confirm("Are you sure you want to delete this order?")) {
      // dispatch(deleteOrder(orderId));
    }
  };

  // const handleEdit = (orderId) => {
  //   alert(`Edit order with ID: ${orderId}`);
  // };

  const handleViewDetails = (order) => {
    setSelectedOrder(order);
    setShowDetails(true);
  };

  const handleStatusChange = (orderId, newStatus) => {
    dispatch(
      updateOrderStatus({
        orderId,
        orderData: { status: newStatus },
        token: JSON.parse(localStorage.getItem("access_token")),
      })
    );
  };

  const filteredOrders = orders.filter((order) =>
    filterStatus === "all" ? true : order.status === filterStatus
  );

  const sortedOrders = filteredOrders.sort((a, b) => {
    if (sortField) {
      if (sortOrder === "asc") {
        return a[sortField] > b[sortField] ? 1 : -1;
      } else {
        return a[sortField] < b[sortField] ? 1 : -1;
      }
    }
    return 0;
  });

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  if (loading) return <LoaderComponent />;
 

  return (
    <div className="container mx-auto p-6 mb-8 h-screen">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Orders List</h2>

      {/* Filter by Status */}
      <div className="mb-4 flex justify-between items-center">
        <div>
          <label className="block text-gray-700">Filter by Status: </label>
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="all">All</option>
            <option value="processing">Processing</option>
            <option value="pending">Pending</option>
            <option value="shipped">Shipped</option>
            <option value="delivered">Delivered</option>
            <option value="completed">Completed</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>
      </div>

      {/* Orders Table */}
      <table className="min-w-full bg-white shadow-lg rounded-lg overflow-hidden">
        <thead className="bg-gray-100">
          <tr>
            <th className="py-3 px-6 text-left font-semibold text-gray-700">
              <button
                onClick={() => handleSort("_id")}
                className="flex items-center text-gray-600"
              >
                Order ID <FaSort className="ml-2 text-sm" />
              </button>
            </th>
            <th className="py-3 px-6 text-left font-semibold text-gray-700">
              Products
            </th>
            <th className="py-3 px-6 text-left font-semibold text-gray-700">
              <button
                onClick={() => handleSort("total_price")}
                className="flex items-center text-gray-600"
              >
                Total Price <FaSort className="ml-2 text-sm" />
              </button>
            </th>
            <th className="py-3 px-6 text-left font-semibold text-gray-700">
              Status
            </th>
            <th className="py-3 px-6 text-left font-semibold text-gray-700">
              Order By <FaSort className="ml-2 text-sm" />
            </th>
            <th className="py-3 px-6 text-left font-semibold text-gray-700">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedOrders.map((order) => (
            <tr key={order._id} className="border-t hover:bg-gray-50">
              <td className="py-3 px-6">{order._id}</td>
              <td className="py-3 px-6">
                {order.products.map((product) => product.id?.title).join(", ")}
              </td>
              <td className="py-3 px-6">${order.totalPrice.toFixed(2)}</td>
              <td className="py-3 px-6">
                <select
                  value={order.status}
                  onChange={(e) =>
                    handleStatusChange(order._id, e.target.value)
                  }
                  className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="processing">Processing</option>
                  <option value="pending">Pending</option>
                  <option value="shipped">Shipped</option>
                  <option value="delivered">Delivered</option>
                  <option value="completed">Completed</option>
                  <option value="cancelled">Cancelled</option>
                </select>
              </td>
              <td className="py-3 px-6">{order.billingAddress?.fullName}</td>
              <td className="py-3 px-6 flex gap-2">
                <button
                  onClick={() => handleViewDetails(order)}
                  className="bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700 transition duration-200"
                >
                  <FaEye />
                </button>
                {/* <button
                  onClick={() => handleEdit(order._id)}
                  className="bg-green-600 text-white p-2 rounded-lg hover:bg-green-700 transition duration-200"
                >
                  <FaEdit />
                </button> */}
                <button
                  onClick={() => handleDelete(order._id)}
                  className="bg-red-600 text-white p-2 rounded-lg hover:bg-red-700 transition duration-200"
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <Pagination
        currentPage={page}
        totalPages={pagination.totalPages}
        onPageChange={handlePageChange}
      />

      {/* Modal for Order Details */}
      {showDetails && selectedOrder && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50 transition duration-300 ease-in-out">
          <div className="bg-white p-8 rounded-lg shadow-2xl max-h-[90vh] w-full md:w-2/3 lg:w-1/2 overflow-y-auto transform transition-all ease-in-out">
            {/* Header */}
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-2xl font-bold text-gray-800">
                Order Details
              </h3>
              <button
                onClick={() => setShowDetails(false)}
                className="text-gray-500 hover:text-red-500 transition duration-200"
              >
                ✕
              </button>
            </div>

            {/* Order Info */}
            <div className="mb-6">
              <p className="text-lg font-semibold text-gray-700 mb-2">
                <strong>Order ID:</strong> {selectedOrder._id}
              </p>
              <p className="text-lg text-gray-700">
                <strong>Status:</strong>{" "}
                <span
                  className={`font-semibold ${
                    selectedOrder.status === "completed"
                      ? "text-green-600"
                      : selectedOrder.status === "processing"
                      ? "text-yellow-500"
                      : "text-gray-700"
                  }`}
                >
                  {selectedOrder.status}
                </span>
              </p>
            </div>

            {/* Products List */}
            <div className="mb-6">
              <h4 className="text-xl font-semibold text-gray-800 mb-4">
                Products:
              </h4>
              <div className="space-y-4">
                {selectedOrder.products.map((product) => (
                  <div
                    key={product.id._id}
                    className="flex items-center justify-between p-4 bg-gray-50 rounded-lg shadow-sm"
                  >
                    <div className="flex items-center">
                      <img
                        src={product.image}
                        alt={product.id.title}
                        className="w-14 h-14 rounded-lg mr-4"
                      />
                      <div>
                        <p className="text-gray-800 font-medium">
                          {product.title}
                        </p>
                        <p className="text-gray-800 font-medium">
                          {product.color || '-'}
                        </p>
                        <p className="text-gray-800 font-medium">
                          {product.size}
                        </p>
                        <p className="text-sm text-gray-600">
                          Quantity: {product.quantity}
                        </p>
                        <p className="text-sm text-gray-600">
                          Price: TK. {product.soldPrice.toFixed(2)}
                        </p>
                      </div>
                    </div>
                    <p className="text-lg font-semibold text-blue-500">
                      ${product.soldPrice * product.quantity}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {/* Address Details */}
            <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <h4 className="text-xl font-semibold text-gray-800 mb-2">
                  Billing Address:
                </h4>
                <p className="text-gray-700">
                  {selectedOrder.billingAddress.fullName}
                </p>
                <p className="text-gray-700">
                  {selectedOrder.billingAddress.address},{" "}
                  {selectedOrder.billingAddress.city}
                </p>
                <p className="text-gray-700">
                  {selectedOrder.billingAddress.region},{" "}
                  {selectedOrder.billingAddress.zone} -{" "}
                  {selectedOrder.billingAddress.postalCode}
                </p>
                <p className="text-gray-700">
                  Phone: {selectedOrder.billingAddress.phone}
                </p>
              </div>
              <div>
                <h4 className="text-xl font-semibold text-gray-800 mb-2">
                  Shipping Address:
                </h4>
                <p className="text-gray-700">
                  {selectedOrder.shippingAddress.full_name}
                </p>
                <p className="text-gray-700">
                  {selectedOrder.shippingAddress.address},{" "}
                  {selectedOrder.shippingAddress.city}
                </p>
                <p className="text-gray-700">
                  {selectedOrder.shippingAddress.region},{" "}
                  {selectedOrder.shippingAddress.zone} -{" "}
                  {selectedOrder.shippingAddress.postalCode}
                </p>
                <p className="text-gray-700">
                  Phone: {selectedOrder.shippingAddress.phone}
                </p>
              </div>
            </div>

            {/* Payment & Transaction Details */}
            <div className="mb-6">
              <h4 className="text-xl font-semibold text-gray-800 mb-2">
                Payment Information:
              </h4>
              <p className="text-gray-700">
                <strong>Method:</strong> {selectedOrder.paymentMethod}
              </p>
              <p className="text-gray-700">
                <strong>Status:</strong>{" "}
                <span
                  className={`font-semibold ${
                    selectedOrder.paymentStatus === "paid"
                      ? "text-green-600"
                      : "text-red-500"
                  }`}
                >
                  {selectedOrder.paymentStatus}
                </span>
              </p>
              <p className="text-gray-700">
                <strong>Payment ID:</strong>{" "}
                {selectedOrder.transaction.payment_id}
              </p>
            </div>

            {/* Footer with Total Price */}
            <div className="flex justify-between items-center mt-8 border-t pt-4">
              <p className="text-lg font-semibold text-gray-800">
                Total Price:
              </p>
              <p className="text-2xl font-bold text-blue-600">
                TK. {selectedOrder.totalPrice.toFixed(2)}
              </p>
            </div>
            

            {/* Close Button */}
            <div className="flex justify-end mt-6">
              <button
                onClick={() => setShowDetails(false)}
                className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrdersList;

// // src/components/OrdersList.js
// import React, { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { getAllOrdersByAdmin } from '../../features/orders/ordersSlice';
// import { FaEdit, FaTrash, FaEye, FaSort } from 'react-icons/fa';
// import LoaderComponent from '../LoaderComponent';

// const OrdersList = () => {

//   const { orders,loading } = useSelector((state) => state.orders);
//   const [selectedOrder, setSelectedOrder] = useState(null);
//   const [showDetails, setShowDetails] = useState(false);
//   const [filterStatus, setFilterStatus] = useState('all');
//   const [sortField, setSortField] = useState('');
//   const [sortOrder, setSortOrder] = useState('asc');
//   const dispatch = useDispatch();
//   // const orders = useSelector((state) => state.orders);

//   useEffect(() => {
//     dispatch(getAllOrdersByAdmin());
//   }, [dispatch]);

//   const handleDelete = (orderId) => {
//     if (window.confirm('Are you sure you want to delete this order?')) {
//     //   dispatch(deleteOrder(orderId));
//     }
//   };

//   const handleEdit = (orderId) => {
//     // Logic for editing the order
//     alert(`Edit order with ID: ${orderId}`);
//   };

//   const handleViewDetails = (order) => {
//     setSelectedOrder(order);
//     setShowDetails(true);
//   };

//   const handleStatusChange = (orderId, newStatus) => {
//     //dispatch(updateOrderStatus({ orderId, status: newStatus }));
//   };

//   //Filter orders based on status
//   const filteredOrders = orders.filter((order) =>
//     filterStatus === 'all' ? true : order.status === filterStatus
//   );

//   //Sort orders
//   const sortedOrders = filteredOrders.sort((a, b) => {
//     if (sortField) {
//       if (sortOrder === 'asc') {
//         return a[sortField] > b[sortField] ? 1 : -1;
//       } else {
//         return a[sortField] < b[sortField] ? 1 : -1;
//       }
//     }
//     return 0;
//   });

//   // Handle sorting by field
//   const handleSort = (field) => {
//     if (sortField === field) {
//       setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
//     } else {
//       setSortField(field);
//       setSortOrder('asc');
//     }
//   };

//   console.log("orders",orders);

//   if(loading) return <LoaderComponent />

//   return (
//     <div className="container mx-auto p-6">
//       <h2 className="text-2xl font-semibold mb-4">Orders List</h2>

//       {/* Filter by Status */}
//       <div className="mb-4">
//         <label className="block text-gray-700">Filter by Status: </label>
//         <select
//           value={filterStatus}
//           onChange={(e) => setFilterStatus(e.target.value)}
//           className="border border-gray-300 rounded p-2"
//         >
//           <option value="all">All</option>
//           <option value="pending">Pending</option>
//           <option value="shipped">Shipped</option>
//           <option value="delivered">Delivered</option>
//         </select>
//       </div>

//       {/* Orders Table */}
//       <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
//         <thead className="bg-gray-200">
//           <tr>
//             <th className="py-2 px-4">
//               <button onClick={() => handleSort('_id')} className="flex items-center">
//                 Order ID <FaSort className="ml-2" />
//               </button>
//             </th>
//             <th className="py-2 px-4">Products</th>
//             <th className="py-2 px-4">
//               <button onClick={() => handleSort('total_price')} className="flex items-center">
//                 Total Price <FaSort className="ml-2" />
//               </button>
//             </th>
//             <th className="py-2 px-4">Status</th>
//             <th className="py-2 px-4">

//                 Order By <FaSort className="ml-2" />

//             </th>
//             <th className="py-2 px-4">Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {sortedOrders.map((order) => (
//             <tr key={order._id} className="border-t">
//               <td className="py-2 px-4">{order._id}</td>
//               <td className="py-2 px-4">
//                 {order.products.map((product) => product.id?.title).join(', ')}
//               </td>
//               <td className="py-2 px-4">${order.total_price.toFixed(2)}</td>
//               <td className="py-2 px-4">
//                 <select
//                   value={order.status}
//                   onChange={(e) => handleStatusChange(order._id, e.target.value)}
//                   className="border border-gray-300 rounded p-1"
//                 >
//                   <option value="pending">Pending</option>
//                   <option value="shipped">Shipped</option>
//                   <option value="delivered">Delivered</option>
//                 </select>
//               </td>
//               <td className="py-2 px-4">{order.billing_address?.full_name}</td>
//               <td className="py-2 px-4 flex gap-2">
//                 <button
//                   onClick={() => handleViewDetails(order)}
//                   className="bg-blue-600 text-white p-2 rounded hover:bg-blue-700"
//                 >
//                   <FaEye />
//                 </button>
//                 <button
//                   onClick={() => handleEdit(order._id)}
//                   className="bg-green-600 text-white p-2 rounded hover:bg-green-700"
//                 >
//                   <FaEdit />
//                 </button>
//                 <button
//                   onClick={() => handleDelete(order._id)}
//                   className="bg-red-600 text-white p-2 rounded hover:bg-red-700"
//                 >
//                   <FaTrash />
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* Modal for Order Details */}
//       {showDetails && selectedOrder && (
//         <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded shadow-lg w-1/2">
//             <h3 className="text-xl font-semibold mb-4">Order Details</h3>
//             <p><strong>Order ID:</strong> {selectedOrder.id}</p>
//             <p><strong>Products:</strong> {selectedOrder.products.map(product => product.id.title).join(', ')}</p>
//             <p><strong>Total Price:</strong> ${selectedOrder.total_price.toFixed(2)}</p>
//             <p><strong>Status:</strong> {selectedOrder.status}</p>
//             <p><strong>Order By:</strong> {selectedOrder.billing_address.full_name}</p>
//             <button
//               onClick={() => setShowDetails(false)}
//               className="mt-4 bg-blue-600 text-white p-2 rounded hover:bg-blue-700"
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default OrdersList;

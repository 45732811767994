import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword , resetPasswordReseller} from "../features/forgotPassword/forgotPasswordSlice";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../components/LoaderComponent";

const ResetPassword = () => {

  const { status, loading, error, message } = useSelector(
    (state) => state.forgotPassword
  )
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Extract email and token from the URL query parameters
  const location = useLocation();
  const {from } = location.state || { from: 'user' };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get("email");
    const tokenParam = queryParams.get("token");

    if (emailParam && tokenParam) {
      setEmail(emailParam);
      setToken(tokenParam);
    } else {
      alert("Invalid or missing reset link parameters.");
    }
  }, [location]);

  // Validation schema
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if( from === 'user'){
      dispatch(resetPassword({ email, token, newPassword: values.password, requesterType: 'user' }));
    }else{
      dispatch(resetPasswordReseller({ email, token, newPassword: values.password, requesterType: 'reseller' }));
    }
    setSubmitting(false);
    resetForm();

  };

  //@TODO user navigate to /login after reset password and for reseller navigate to /reseller/login
  useEffect(() => {
    if (status === "success") {
      if(from === 'user'){
        navigate("/login");
      }else{
        navigate("/reseller/login");
      }
    }
  }, [status, message,navigate,from]);

  if (!email || !token) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-red-500">Invalid or missing reset link parameters.</p>
      </div>
    );
  }

  if(loading) return <LoaderComponent />;

  if (status === "failed") {
    return (
      <div className="min-h-screen flex items-center justify-center">  
        <p className="text-red-500">{error}</p>
      </div>
    );
  };
  
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
          Reset Password
        </h2>
        <Formik
          initialValues={{ password: "", confirmPassword: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  New Password
                </label>
                <Field
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter your new password"
                  className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Confirm Password
                </label>
                <Field
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Confirm your new password"
                  className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full py-2 px-4 rounded-lg text-white font-medium ${
                  isSubmitting
                    ? "bg-blue-300 cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700"
                }`}
              >
                {isSubmitting ? "Submitting..." : "Reset Password"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;

import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionOrders , deleteSubscriptionOrder} from '../../features/subscriptionOrder/subscriptionOrderSlice';
import LoaderComponent from '../LoaderComponent';
import EditSubscriptionOrderModal from './EditSubscriptionOrder';

const SubscriptionOrdersList = () => {
  const { subscriptionOrders,loading } = useSelector((state) => state.subscriptionOrders);
  const [selectedSubOrder, setSelectedSubOrder] = useState(null);
  const [isEditing, setIsEditing] = useState(false);


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubscriptionOrders());
  }, [dispatch]);




  const handleDelete = (orderId) => {
    if (window.confirm('Are you sure you want to delete this subscription order?')) {
      dispatch(deleteSubscriptionOrder({subscriptionOrderId:orderId, token: JSON.parse(localStorage.getItem("access_token"))}));
      alert('Subscription order deleted successfully!');
    }
  };

  const handleEdit = (order) => {
    setSelectedSubOrder(order);
    setIsEditing(true);
  };


 

 if(loading) return <LoaderComponent message="Loading subscription orders..." />

  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Subscription Orders</h2>
        
      </div>
      <div className="shadow-lg rounded-lg overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-2 px-4">SubO ID</th>
              <th className="py-2 px-4">Order By</th>
              <th className="py-2 px-4">Phone</th>
              <th className="py-2 px-4">package Name</th>
              <th className="py-2 px-4">status</th>
              <th className="py-2 px-4">payment id</th>
              <th className="py-2 px-4">manual payment id</th>
              <th className="py-2 px-4">provider</th>
              <th className="py-2 px-4">amount</th>
              <th className="py-2 px-4">payment status</th>
              <th className="py-2 px-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {subscriptionOrders.map((subscriptionOrder) => (
              <tr key={subscriptionOrder._id} className="border-t">
                <td className="py-2 px-4">{subscriptionOrder._id}</td>
                <td className="py-2 px-4">{subscriptionOrder.name}</td>
                <td className="py-2 px-4">{subscriptionOrder.phone}</td>
                <td className="py-2 px-4">{subscriptionOrder.subscriptionPackageId?.name}</td>
                <td className="py-2 px-4">{subscriptionOrder.status}</td>
                <td className="py-2 px-4">{subscriptionOrder.paymentId}</td>
                <td className="py-2 px-4">{subscriptionOrder.manualPaymentId}</td>
                <td className="py-2 px-4">{subscriptionOrder.provider}</td>
                <td className="py-2 px-4">{subscriptionOrder.amount}</td>
                <td className="py-2 px-4">{subscriptionOrder.paymentStatus}</td>
                <td className="py-2 px-4 flex gap-2">
                  
                  <button
                    onClick={() => handleEdit(subscriptionOrder)}
                    className="bg-green-500 text-white p-2 rounded-full shadow-md hover:bg-green-600 transition-all"
                  >
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => handleDelete(subscriptionOrder._id)}
                    className="bg-red-500 text-white p-2 rounded-full shadow-md hover:bg-red-600 transition-all"
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {
        isEditing && selectedSubOrder && <EditSubscriptionOrderModal subscriptionOrderId={selectedSubOrder._id} currentStatus={selectedSubOrder.status} currentPaymentStatus={selectedSubOrder.paymentStatus} setEditing={setIsEditing} /> 
      }
  </div>
  )
}

export default SubscriptionOrdersList
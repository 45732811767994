

const ShowUserDetails = ({selectedUser, setShowDetails}) => {

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <h3 className="text-xl font-semibold mb-4">User Details</h3>
            <p><strong>User ID:</strong> {selectedUser.id}</p>
            <p><strong>Name:</strong> {selectedUser.name}</p>
            <p><strong>Email:</strong> {selectedUser.email}</p>
            <p><strong>Role:</strong> {selectedUser.role}</p>
            <button
              onClick={() => setShowDetails(false)}
              className="mt-4 bg-blue-600 text-white p-2 rounded hover:bg-blue-700 transition-all"
            >
              Close
            </button>
          </div>
        </div>
      )
};


export default ShowUserDetails;
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ShowCompanyEmploys = ({ employees = [] }) => {
  const isSingleItem = employees.length === 1;

  const settings = {
    dots: true,
    infinite: !isSingleItem, // Disable infinite scroll if there's only one employee
    speed: 500,
    slidesToShow: isSingleItem ? 1 : 3, // Show 1 slide if only one employee
    slidesToScroll: isSingleItem ? 1 : 3,
    autoplay: !isSingleItem, // Disable autoplay for a single item
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: isSingleItem ? 1 : 3,
          slidesToScroll: isSingleItem ? 1 : 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: isSingleItem ? 1 : 2,
          slidesToScroll: isSingleItem ? 1 : 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full mx-auto my-10">
      <h2 className="text-3xl font-bold text-green-600 mb-6 text-center">
        Our Company Employees
      </h2>
      {employees.length > 0 ? (
        <Slider {...settings}>
          {employees.map((employee, index) => (
            <div key={index} className="px-4">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden text-center p-8 transition-transform transform hover:scale-105 hover:shadow-2xl duration-300">
                {/* Employee Picture */}
                <img
                  src={employee.profilePicture}
                  alt={employee.name}
                  className="w-24 h-24 mx-auto rounded-full object-cover mb-4 border-4 border-green-500"
                />
                {/* Employee Name */}
                <p className="text-xl font-semibold text-green-600">
                  Name: {employee.name}
                </p>
                {/* Employee Role */}
                <p className="text-gray-600 mt-1">
                  Designation: {employee.role}
                </p>
                {/* Employee Contact */}
                <p className="text-gray-600 mt-1">
                  Phone: {employee.contact?.phone || "N/A"}
                </p>
                <p className="text-gray-600 mt-1">
                  Email: {employee.contact?.email || "N/A"}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <p className="text-center text-gray-500">No employees found.</p>
      )}
    </div>
  );
};

export default ShowCompanyEmploys;

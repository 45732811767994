import React, { useEffect,} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFilteredProducts, incrementPage, resetFilteredProducts, setFilter } from '../features/products/productsSlice';
import ProductCard from '../components/ProductCard';
import Carousel from '../components/Carousel';
import LoaderComponent from '../components/LoaderComponent';
import { useLocation } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

const CategorizedProducts = () => {
  const { filteredProducts, loading, error, filters, page, hasMore } = useSelector((state) => state.products);
  
  const dispatch = useDispatch();
  const location = useLocation();

  const { ref, inView } = useInView({
    threshold: 0.5,
    rootMargin: "100px",
  });

  // track ref query params for affiliates
  const queryParams = new URLSearchParams(location.search);
  const refCode = queryParams.get("ref");
  const categoryQuery = queryParams.get("category");

  if(refCode){
    localStorage.setItem("ref_code", refCode);
  }


  useEffect(() => {
    if (inView && hasMore && !loading) {
      dispatch(incrementPage());
    }
  }, [inView, hasMore, loading, dispatch]);

  useEffect(() => {
    if (categoryQuery) {
      dispatch(getFilteredProducts({ filters:{...filters, category: categoryQuery}, page }));
    }
  }, [filters, page, dispatch, categoryQuery]);

  useEffect(() => {
    // Reset filters when the component unmounts
    return () => {
      dispatch(resetFilteredProducts()); // Reset products when unmounting
      dispatch(setFilter({ category: "", minPrice: 0, maxPrice: 100000, inStock: false, rating: 0 }));
    };
  }, [dispatch]);


  if (loading) return <LoaderComponent message='loading products' />;
  if (error) return <p>Error: {error}</p>;
  if(!categoryQuery) return <div>No Products to show</div>
  return (
    <div className="container mx-auto px-4 py-8">
      <Carousel />
      <h2 className="text-2xl font-bold mb-4 mt-4">Products For : {categoryQuery.toUpperCase()}</h2>
      <div className="grid grid-cols-1 md:grid-cols-6 gap-2">
        {filteredProducts && filteredProducts.map((product) => (
          <ProductCard key={product._id} product={product} />
        ))}
        {/* infinite scroll observer */}
        {!loading && hasMore && <div ref={ref} className="h-4"></div>}
      </div>
    </div>
  );
};

export default CategorizedProducts;
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';


const PackageForm = ({ onSubmit }) => {

    
  const formik = useFormik({
    initialValues: {
      name: 'basic',
      price: '',
      offerPrice: 0
    },
    validationSchema: Yup.object({
      name: Yup.string().oneOf(['basic', 'standard'], 'Invalid Package Type').required('Package Type is required'),
      price: Yup.number().typeError('Must be a number').positive('Must be a positive number').required('Price is required'),
      offerPrice: Yup.number().typeError('Must be a number').min(0, 'Offer Price cannot be negative').max(Yup.ref('price'), 'Offer Price cannot exceed Price')
    }),
    onSubmit: (values) => {
      onSubmit(values);
    }
  });

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
      {/* Package Type */}
      <div>
        <label className="block text-gray-700">Package Type</label>
        <select
          id="name"
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-blue-500"
        >
          <option value="basic">Basic</option>
          <option value="standard">Standard</option>
        </select>
        {formik.touched.name && formik.errors.name ? (
          <div className="text-red-500 text-sm">{formik.errors.name}</div>
        ) : null}
      </div>

      {/* Price */}
      <div>
        <label className="block text-gray-700">Price</label>
        <input
          type="number"
          id="price"
          name="price"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.price}
          className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-blue-500"
        />
        {formik.touched.price && formik.errors.price ? (
          <div className="text-red-500 text-sm">{formik.errors.price}</div>
        ) : null}
      </div>

      {/* Offer Price */}
      <div>
        <label className="block text-gray-700">Offer Price</label>
        <input
          type="number"
          id="offerPrice"
          name="offerPrice"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.offerPrice}
          className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-blue-500"
        />
        {formik.touched.offerPrice && formik.errors.offerPrice ? (
          <div className="text-red-500 text-sm">{formik.errors.offerPrice}</div>
        ) : null}
      </div>

      <button
        type="submit"
        className="bg-blue-500 text-white py-2 rounded shadow-md hover:bg-blue-600 transition-all"
      >
        Submit
      </button>
    </form>
  );
};

export default PackageForm;

import React from 'react';
import { Link } from 'react-router-dom';

const StoreProductCard = ({ product, storeId }) => {

    

  return (
    <Link to={`/reseller/store/${storeId}/products/${product.title}`}  className="border rounded-lg hover:shadow-lg hover:border-1 p-3">
      <div className="relative w-full h-56">
        {product.colorSizeVariants && product.colorSizeVariants.length > 0 ? (
          <img
            src={product.colorSizeVariants[0]?.image}
            alt={product.title}
            className="w-full h-full object-contain transition-all duration-300 ease-in-out transform hover:scale-110"
          />
        ) : (
          <img
            src={product.sizeVariants[0]?.image}
            alt={product.title}
            className="w-full h-full object-contain transition-all duration-300 ease-in-out transform hover:scale-110"
          />
        )}
      </div>
      <div className="p-4">
        <h2 className="text-lg font-bold">{product.title}</h2>
       
        {product.colorSizeVariants && product.colorSizeVariants.length > 0 && (
          <div className="mt-2">
            
            {product.colorSizeVariants[0].sizes[0] &&
              <div
                key={product.colorSizeVariants[0].sizes[0].sizeName}
                className="flex justify-between items-center mt-1"
              >
                <span>{product.colorSizeVariants[0].sizes[0].sizeName}</span>
                <span>${product.colorSizeVariants[0].sizes[0].customPrice.toFixed(2)}</span>
                <span>
                  {product.colorSizeVariants[0].sizes[0].stock > 0 ? `${product.colorSizeVariants[0].sizes[0].stock} in stock` : "Out of stock"}
                </span>
              </div>
           }
          </div>
        )}

        {product.sizeVariants && product.sizeVariants.length > 0 && (
          <div className="mt-2">
            <h3 className="font-semibold">Sizes:</h3>
            {product.sizeVariants[0] && (
              <div
                key={product.sizeVariants[0].sizeName}
                className="flex justify-between items-center mt-1"
              >
                <span>{product.sizeVariants[0].sizeName}</span>
                <span>${product.sizeVariants[0].customPrice.toFixed(2)}</span>
                <span>
                  {product.sizeVariants[0].stock > 0
                    ? `${product.sizeVariants[0].stock} in stock`
                    : "Out of stock"}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      
    </Link>
  );
};

export default StoreProductCard;

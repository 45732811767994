import React, { useState, useRef } from 'react';

const ReturnPolicy = () => {
  const [isOpen, setIsOpen] = useState(null);

  // Create references for each section
  const returnEligibilityRef = useRef(null);
  const returnProcessRef = useRef(null);
  const refundPolicyRef = useRef(null);
  const nonReturnableItemsRef = useRef(null);
  const internationalReturnsRef = useRef(null);

  // Scroll to section when clicked
  const scrollToSection = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - 100, // 100px for top offset
      behavior: 'smooth',
    });
  };

  const toggleSection = (index) => {
    setIsOpen(isOpen === index ? null : index);
  };

  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-green-600 to-teal-500 text-white py-20">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl md:text-5xl font-bold leading-tight mb-4">Return Policy</h1>
          <p className="text-lg md:text-xl mb-8">Our return and refund policy is designed to ensure a hassle-free experience for you. Please read through our policies for more information.</p>
        </div>
      </section>

      {/* Table of Contents */}
      <section className="container mx-auto py-12 px-6 md:px-12">
        <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg space-y-6">
          <h2 className="text-2xl font-semibold text-gray-800">Table of Contents</h2>
          <ul className="list-none space-y-2 text-gray-600">
            {['Return Eligibility', 'Return Process', 'Refund Policy', 'Non-Returnable Items', 'International Returns'].map((item, index) => (
              <li key={index}>
                <button
                  onClick={() => {
                    // Scroll to corresponding section
                    const refs = [returnEligibilityRef, returnProcessRef, refundPolicyRef, nonReturnableItemsRef, internationalReturnsRef];
                    scrollToSection(refs[index]);
                  }}
                  className="text-teal-600 hover:text-teal-800 font-medium transition-all ease-in-out duration-300 transform hover:scale-105"
                >
                  {item}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </section>

      {/* Return Policy Content */}
      <section className="container mx-auto py-12 px-6 md:px-12">
        <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg space-y-6">
          {/* Section 1: Return Eligibility */}
          <div
            ref={returnEligibilityRef}
            className={`transition-all duration-300 rounded-lg ${isOpen === 0 ? 'bg-teal-100' : 'bg-white'}`}
            onClick={() => toggleSection(0)}
          >
            <h3 className="text-xl font-semibold text-gray-800 cursor-pointer p-4">
              Return Eligibility
            </h3>
            {isOpen === 0 && (
              <p className="text-gray-600 p-4">
                To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging with all tags intact.
              </p>
            )}
          </div>

          {/* Section 2: Return Process */}
          <div
            ref={returnProcessRef}
            className={`transition-all duration-300 rounded-lg ${isOpen === 1 ? 'bg-teal-100' : 'bg-white'}`}
            onClick={() => toggleSection(1)}
          >
            <h3 className="text-xl font-semibold text-gray-800 cursor-pointer p-4">
              Return Process
            </h3>
            {isOpen === 1 && (
              <p className="text-gray-600 p-4">
                To initiate a return, please contact our customer support team within 30 days of receiving the product. We will provide you with a return label and instructions on how to send the item back.
              </p>
            )}
          </div>

          {/* Section 3: Refund Policy */}
          <div
            ref={refundPolicyRef}
            className={`transition-all duration-300 rounded-lg ${isOpen === 2 ? 'bg-teal-100' : 'bg-white'}`}
            onClick={() => toggleSection(2)}
          >
            <h3 className="text-xl font-semibold text-gray-800 cursor-pointer p-4">
              Refund Policy
            </h3>
            {isOpen === 2 && (
              <p className="text-gray-600 p-4">
                Refunds will be processed after we receive the returned item and inspect its condition. Refunds will be issued to the original payment method, usually within 7-10 business days.
              </p>
            )}
          </div>

          {/* Section 4: Non-Returnable Items */}
          <div
            ref={nonReturnableItemsRef}
            className={`transition-all duration-300 rounded-lg ${isOpen === 3 ? 'bg-teal-100' : 'bg-white'}`}
            onClick={() => toggleSection(3)}
          >
            <h3 className="text-xl font-semibold text-gray-800 cursor-pointer p-4">
              Non-Returnable Items
            </h3>
            {isOpen === 3 && (
              <p className="text-gray-600 p-4">
                Some items are non-returnable due to hygiene or safety concerns. These items include personal care products, gift cards, and items marked as final sale.
              </p>
            )}
          </div>

          {/* Section 5: International Returns */}
          <div
            ref={internationalReturnsRef}
            className={`transition-all duration-300 rounded-lg ${isOpen === 4 ? 'bg-teal-100' : 'bg-white'}`}
            onClick={() => toggleSection(4)}
          >
            <h3 className="text-xl font-semibold text-gray-800 cursor-pointer p-4">
              International Returns
            </h3>
            {isOpen === 4 && (
              <p className="text-gray-600 p-4">
                If you are returning an item from outside the country, you will be responsible for the return shipping costs. International returns must be made within 45 days of receiving the product.
              </p>
            )}
          </div>
        </div>
      </section>

      
    </div>
  );
};

export default ReturnPolicy;

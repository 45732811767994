import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api/api';


// get admin dashboard metrics
export const getDashboard = createAsyncThunk('dashboard/getDashboard', async (token) => {
    const response = await api.get('/admin/dashboard',{
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
});

// get reseller  dashboard metrics
export const getResellerDashboard = createAsyncThunk('dashboard/getResellerDashboard', async ({resellerId,token}) => {
    const response = await api.get(`/reseller/dashboard/${resellerId}`,{
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
});


const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        dashboard: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDashboard.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getDashboard.fulfilled, (state, action) => {
                state.loading = false;
                state.dashboard = action.payload;
            })
            .addCase(getDashboard.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getResellerDashboard.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getResellerDashboard.fulfilled, (state, action) => {
                state.loading = false;
                state.dashboard = action.payload;
            })
            .addCase(getResellerDashboard.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default dashboardSlice.reducer;
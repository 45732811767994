import React from 'react';

const LoaderComponent = ({ message = 'Loading...' }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
      <div className="relative">
        {/* Pulsing circle */}
        <div className="w-24 h-24 rounded-full bg-gradient-to-tr from-purple-500 to-pink-500 opacity-75 animate-pulse"></div>

        {/* Rotating border gradient */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-16 h-16 rounded-full border-4 border-dotted border-purple-400 animate-spin-slow" />
        </div>

        {/* Center dot */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-8 h-8 bg-purple-500 rounded-full animate-bounce"></div>
        </div>
      </div>

      {/* Loading message */}
      <p className="mt-4 text-lg text-gray-600 font-semibold">{message}</p>
    </div>
  );
};

export default LoaderComponent;

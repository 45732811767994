import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { loginAdmin } from '../../features/auth/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../images/logo.png'

const AdminLogin = () => {
  const {isAuthenticated,loading} = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();



  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  });

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema,
    onSubmit: async (values) => {
      dispatch(loginAdmin(values));
    },
    
  });
  const [prevAuthState, setPrevAuthState] = useState(isAuthenticated);

  useEffect(() => {
      if (isAuthenticated && !prevAuthState) {
          navigate("/admin/dashboard");
      }
      setPrevAuthState(isAuthenticated);
  }, [isAuthenticated, navigate, prevAuthState]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-slate-800">
      <div className="bg-white shadow-lg rounded-lg p-10 max-w-sm w-full space-y-6">
        {/* Company Logo */}
        <div className="w-full flex justify-center mb-4">
          <img src={logo} alt="Company Logo" className="w-20 h-auto" />
        </div>

        {/* Page Title */}
        <h2 className="text-center text-2xl font-bold text-slate-700 mb-2">Admin Login</h2>

        {/* Login Form */}
        <form onSubmit={formik.handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-600">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your email"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500 text-sm">{formik.errors.email}</div>
            )}
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-600">Password</label>
            <input
              id="password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your password"
            />
            {formik.touched.password && formik.errors.password && (
              <div className="text-red-500 text-sm">{formik.errors.password}</div>
            )}
          </div>

          <div className="flex items-center justify-between">
            <Link to="/forgot-password" className="text-sm text-blue-600 hover:text-blue-700">
              Forgot Password?
            </Link>
          </div>

          <div>
            <button
              type="submit"
              disabled={formik.isSubmitting}
              className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              { loading ? 'Loading...' : 'Login'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContacts,deleteContact } from '../../features/contact/contactSlice';
import { FaEye, FaTrash } from 'react-icons/fa';
import ContactDetailsModal from './ContactDetailsModal';
import LoaderComponent from '../LoaderComponent';

const ContactList = () => {
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contact.contacts);
  const loading = useSelector((state) => state.contact.loading);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);

  const handleView = (contact) => {
    setSelectedContact(contact);
    setModalOpen(true);
  };

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this contact?')) {
      dispatch(deleteContact(id));
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedContact(null);
  };

  if(loading) return <LoaderComponent />

  return (
    <div className="p-6 container mx-auto bg-white rounded-lg shadow-md mb-8 h-screen">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">Contact List</h2>
      { contacts.length > 0 ? (
        <div className='shadow-lg rounded-lg overflow-x-auto'>
        <table className="w-full border-collapse bg-white rounded-lg">
          <thead>
            <tr className="bg-gray-100 text-gray-800 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">Name</th>
              <th className="py-3 px-6 text-left">Email</th>
              <th className="py-3 px-6 text-left">Message</th>
              <th className="py-3 px-6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-700 text-sm font-light">
            {contacts.map((contact) => (
              <tr key={contact._id} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="py-3 px-6 text-left whitespace-nowrap">{contact.name}</td>
                <td className="py-3 px-6 text-left">{contact.email}</td>
                <td className="py-3 px-6 text-left">
                  {contact.message.length > 50 ? `${contact.message.substring(0, 50)}...` : contact.message}
                </td>
                <td className="py-3 px-6 text-center">
                  <div className="flex item-center justify-center space-x-4">
                    <button
                      onClick={() => handleView(contact)}
                      className="text-blue-500 hover:text-blue-700"
                      title="View"
                    >
                      <FaEye />
                    </button>
                    <button
                      onClick={() => handleDelete(contact._id)}
                      className="text-red-500 hover:text-red-700"
                      title="Delete"
                    >
                      <FaTrash />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      ) : (
        <p className="text-gray-500">No contacts found.</p>
      )}
      <ContactDetailsModal contact={selectedContact} isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default ContactList;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api/api';

// create video course by admin
export const createVideoCourse = createAsyncThunk('videoCourse/createVideoCourse', async ({videoCourse,token}) => {
    const response = await api.post('/video-courses', videoCourse,{headers: {
        "Content-Type":"application/json",
        "Authorization": `Bearer ${token}`
    }});
    return response.data;
});

// get video course by reseller
export const getVideoCourses = createAsyncThunk('videoCourse/getVideoCourses', async ({token})=>{
    const response = await api.get('/video-courses',{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
});

/// update video course by admin

export const updateVideoCourse = createAsyncThunk('videoCourse/updateVideoCourse', async ({videoCourseId,videoCourseData,token}) => {
    const response = await api.put(`/video-courses/${videoCourseId}`, videoCourseData, {headers: {
        "Content-Type":"application/json",
        "Authorization": `Bearer ${token}`
    }});
    return response.data;
});

// get all video course by admin
export const getAllVideoCourses = createAsyncThunk('videoCourse/getAllVideoCourses', async () => {
    const response = await api.get('/admin/video-courses');
    return response.data;
});

// delete video course by admin
export const deleteVideoCourse = createAsyncThunk('videoCourse/deleteVideoCourse', async (videoCourseId) => {
    const response = await api.delete(`/video-courses/${videoCourseId}`);
    return response.data;    
});

const videoCourseSlice = createSlice({
    name: 'videoCourse',
    initialState: {
        videoCourses: [],
        videoCourseList: [],
        singleVideoCourse: {},
        loading: false,
        error: null
        },
        reducers: {},
        extraReducers: (builder) => {
            builder
            .addCase(createVideoCourse.pending, (state) => {
                state.loading = true;
            })
            .addCase(createVideoCourse.fulfilled, (state, action) => {
                state.loading = false;
                state.videoCourses.push(action.payload);
                state.videoCourseList.push(action.payload);
            })
            .addCase(createVideoCourse.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getVideoCourses.pending, (state) => {
                state.loading = true;
            })
            .addCase(getVideoCourses.fulfilled, (state, action) => {
                state.loading = false;
                state.videoCourses = action.payload;
            })
            .addCase(getVideoCourses.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(updateVideoCourse.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateVideoCourse.fulfilled, (state, action) => {
                state.loading = false;
                const index = state.videoCourses.findIndex(videoCourse => videoCourse._id === action.payload._id);
                state.videoCourses[index] = action.payload;
                state.videoCourseList[index] = action.payload;
            })
            .addCase(updateVideoCourse.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getAllVideoCourses.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllVideoCourses.fulfilled, (state, action) => {
                state.loading = false;
                state.videoCourseList = action.payload;
            })
            .addCase(getAllVideoCourses.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(deleteVideoCourse.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteVideoCourse.fulfilled, (state, action) => {
                state.loading = false;
                state.videoCourses = state.videoCourses.filter(videoCourse => videoCourse._id !== action.payload._id);
                state.videoCourseList = state.videoCourseList.filter(videoCourse => videoCourse._id !== action.payload._id);
            })
            .addCase(deleteVideoCourse.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
        }
});


export default videoCourseSlice.reducer;
   
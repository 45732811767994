import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api/api';


// async thunk for creating an affiliate
export const createAffiliate = createAsyncThunk('affiliate/createAffiliate', async (affiliate) => {
    const response = await api.post('/affiliates', affiliate,{headers: {
        "Content-Type":"application/json"
    }});
    return response.data;
});

// async thunk for getting all affiliates
export const getAffiliates = createAsyncThunk('affiliate/getAffiliates', async (token) => {
    const response = await api.get('/affiliates',{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
});

// async thunk for update an affiliate - admin
export const updateAffiliate = createAsyncThunk('affiliate/updateAffiliate', async ({id,affiliateData,token}) => {
    const response = await api.put(`/affiliates/${id}`, affiliateData, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
});

// async thunk for delete an affiliate - admin
export const deleteAffiliate = createAsyncThunk('affiliate/deleteAffiliate', async ({id,token}) => {
    const response = await api.delete(`/affiliates/${id}`,{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
});

// get affiliate by userId
export const getAffiliateByUserId = createAsyncThunk('affiliate/getAffiliateByUserId', async ({userId,token}) => {
    const response = await api.get(`/affiliates/user/${userId}`,{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
});



const affiliateSlice = createSlice({
    name: 'affiliate',
    initialState: {
        affiliates: [],
        affiliate: null,
        loading: false,
        error: null
        },
        reducers: {
        },
        extraReducers: (builder) => {
            builder
                .addCase(createAffiliate.pending, (state) => {
                    state.loading = true;
                    state.error = null;
                })
                .addCase(createAffiliate.fulfilled, (state, action) => {
                    state.loading = false;
                    state.affiliate = action.payload;
                    state.error = null;
                })
                .addCase(createAffiliate.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action.error.message;
                })
                .addCase(getAffiliates.pending, (state) => {
                    state.loading = true;
                    state.error = null;
                })  
                .addCase(getAffiliates.fulfilled, (state, action) => {
                    state.loading = false;
                    state.affiliates = action.payload;
                    state.error = null;
                })
                .addCase(getAffiliates.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action.error.message;
                })
                .addCase(updateAffiliate.pending, (state) => {
                    state.loading = true;
                    state.error = null;
                })
                .addCase(updateAffiliate.fulfilled, (state, action) => {
                    state.loading = false;
                    const index = state.affiliates.findIndex((affiliate) => affiliate._id === action.payload._id);
                    state.affiliates[index] = action.payload;
                    state.error = null;
                })
                .addCase(updateAffiliate.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action.error.message;
                })
                .addCase(getAffiliateByUserId.pending, (state) => {
                    state.loading = true;
                    state.error = null;
                })
                .addCase(getAffiliateByUserId.fulfilled, (state, action) => {
                    state.loading = false;
                    state.affiliate = action.payload;
                    state.error = null;
                })
                .addCase(getAffiliateByUserId.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action.error.message;
                })
                .addCase(deleteAffiliate.pending, (state) => {
                    state.loading = true;
                    state.error = null;
                })
                .addCase(deleteAffiliate.fulfilled, (state, action) => {
                    state.loading = false;
                    state.affiliates = state.affiliates.filter((affiliate) => affiliate.id !== action.payload.id);
                    state.error = null;
                })
                .addCase(deleteAffiliate.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action.error.message;
                });
        }
});

export const { resetAffiliate } = affiliateSlice.actions;
export default affiliateSlice.reducer;
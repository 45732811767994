import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrderBonuses,
  deleteOrderBonus,
} from "../../features/orderBonus/orderBonusSlice";
import { FaEye, FaTrash } from "react-icons/fa";
import OrderBonusCreateForm from "./OrderBonusCreateForm";
import OrderBonusDetails from "./OrderBonusDetails";
import LoaderComponent from "../LoaderComponent";

const OrderBonusList = () => {
  const { orderBonuses, loading } = useSelector((state) => state.orderBonus);
  const [showForm, setShowForm] = useState(false);
  const [selectedBonus, setSelectedBonus] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrderBonuses());
  }, [dispatch]);

  // handle delete bonus
  const handleDelete = (bonusId) => {
    if (window.confirm("Are you sure you want to delete this bonus?")) {
      dispatch(
        deleteOrderBonus({
          id: bonusId,
          token: JSON.parse(localStorage.getItem("token")),
        })
      );
    }
  };

  if (loading) return <LoaderComponent />;

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Order Bonuses</h2>
        <button
          onClick={() => setShowForm(true)}
          className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded"
        >
          Create Bonus
        </button>
      </div>

      {orderBonuses.length > 0 ? (
        <div className="shadow-lg overflow-x-auto">
        <table className="w-full border border-gray-300 rounded-md overflow-hidden">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2 text-left">Title</th>
              <th className="px-4 py-2 text-left">Description</th>
              <th className="px-4 py-2 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {orderBonuses.map((bonus) => (
              <tr key={bonus._id} className="border-b">
                <td className="px-4 py-2">{bonus.title}</td>
                <td className="px-4 py-2 truncate" title={bonus.description}>
                  {bonus.description.length > 50
                    ? bonus.description.slice(0, 50) + "..."
                    : bonus.description}
                </td>
                <td className="px-4 py-2 text-center">
                  <button
                    onClick={() => setSelectedBonus(bonus)}
                    className="mr-2 text-blue-500"
                  >
                    <FaEye />
                  </button>
                  <button
                    onClick={() => handleDelete(bonus._id)}
                    className="text-red-500"
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      ) : (
        <p>No order bonuses found.</p>
      )}

      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-full md:w-1/3 p-4 rounded">
            <button
              onClick={() => setShowForm(false)}
              className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
            >
              Close
            </button>
            <OrderBonusCreateForm onClose={() => setShowForm(false)} />
          </div>
        </div>
      )}

      {selectedBonus && (
        <OrderBonusDetails
          bonus={selectedBonus}
          onClose={() => setSelectedBonus(null)}
        />
      )}
    </div>
  );
};

export default OrderBonusList;

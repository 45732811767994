import React, { useEffect, useState } from 'react';
import { getPackages } from '../../features/package/packageSlice';
import { useDispatch, useSelector } from 'react-redux';
import LoaderComponent from '../../components/LoaderComponent';
import { useNavigate } from 'react-router-dom';

const PackageComponent = () => {
  const { packages, loading } = useSelector((state) => state.packages);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedPackage, setSelectedPackage] = useState('basic');
  const [chosenPackage, setChosenPackage] = useState(null);

  const index = packages.findIndex((pkg) => pkg.name === selectedPackage);
  const featuresPackage = packages[index];

  useEffect(() => {
    dispatch(getPackages());
  }, [dispatch]);

  const handleChoosePackage = (pkg) => {
    setChosenPackage(pkg);
  };

  useEffect(() => {
    if (chosenPackage) {
      navigate(`/reseller/package/${chosenPackage.name}/order`, { state: { chosenPackage } });
    }
  }, [chosenPackage, navigate]);

  if (loading) return <LoaderComponent message="Loading packages..." />;

  return (
    <div className="max-w-lg mx-auto bg-white rounded-lg shadow-md overflow-hidden border border-purple-300 transform transition duration-500 hover:shadow-xl mt-4 mb-4">
      <div className="flex justify-between items-center py-2 px-4 bg-gradient-to-r from-green-500 to-teal-500 rounded-t-lg text-white">
        <button
          className={`font-semibold text-lg py-1 px-3 rounded-lg transition-all ${
            selectedPackage === 'basic' ? 'bg-white text-green-600' : 'bg-transparent'
          }`}
          onClick={() => setSelectedPackage('basic')}
        >
          Basic
        </button>
        <button
          className={`font-semibold text-lg py-1 px-3 rounded-lg transition-all ${
            selectedPackage === 'standard' ? 'bg-white text-green-600' : 'bg-transparent'
          }`}
          onClick={() => setSelectedPackage('standard')}
        >
          Standard
        </button>
      </div>

      <div className="text-center py-6 bg-gray-100 border-b border-purple-300">
        <span className={`${featuresPackage?.offerPrice ? 'text-red-500 line-through' : 'text-black font-semibold'} text-lg`}>
          ৳ {featuresPackage?.price}
        </span>
        {featuresPackage?.offerPrice > 0 && (
          <span className="text-2xl text-black font-bold mx-2">৳ {featuresPackage?.offerPrice}</span>
        )}
        <span className="text-gray-600 text-sm">/ 12 months</span>
      </div>

      <ul className="px-6 py-4 space-y-2 text-gray-700">
        {featuresPackage?.facilities.map((feature, index) => (
          <li key={index} className="flex items-center space-x-2">
            {feature.available ? (
              <svg className="w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg className="w-5 h-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M5.293 4.293a1 1 0 011.414 0L10 7.586l3.293-3.293a1 1 0 111.414 1.414L11.414 9l3.293 3.293a1 1 0 01-1.414 1.414L10 10.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 9 5.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            <span>{feature.name}</span>
          </li>
        ))}
      </ul>

      <div className="px-6 py-4">
        <button
          onClick={() => handleChoosePackage(featuresPackage)}
          className="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-3 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
        >
          Choose Package
        </button>
      </div>
    </div>
  );
};

export default PackageComponent;






















// import React, { useEffect, useState } from 'react';
// import { getPackages } from '../../features/package/packageSlice';
// import { useDispatch, useSelector } from 'react-redux';
// import LoaderComponent from '../../components/LoaderComponent';
// import { useNavigate } from 'react-router-dom';

// const PackageComponent = () => {

//   const { packages, loading } = useSelector((state) => state.packages);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   // State to track selected package
//   const [selectedPackage, setSelectedPackage] = useState('basic');
//   // state to check if user chose any package
//   const [chosenPackage, setChosenPackage] = useState(null);

//   // Select the feature list based on the selected package
//   const index = packages.findIndex((pkg) => pkg.name === selectedPackage);
//   const featuresPackage = packages[index];

//   useEffect(() => {
//     dispatch(getPackages());
//   }, [dispatch]);

//   const handleChoosePackage = (pkg) => {
//     setChosenPackage(pkg);
//   };

//   useEffect(() => {
//     if (chosenPackage) {
//       navigate(`/reseller/package/${chosenPackage.name}/order`,{ state: { chosenPackage } });
//     }
//   }, [chosenPackage,navigate]);

//   if (loading) return <LoaderComponent message='loading packages'/>;

//   return (
//     <div className="max-w-sm mx-auto bg-white rounded-lg shadow-lg border border-purple-300">
//       {/* Header with toggle buttons */}
//       <div className="flex justify-between items-center py-2 px-4">
//         <button
//           className={`font-semibold text-lg py-1 px-3 rounded-tl-lg ${selectedPackage === 'basic' ? 'bg-green-600 text-white' : 'bg-gray-200 text-gray-700'}`}
//           onClick={() => setSelectedPackage('basic')}
//         >
//           Basic
//         </button>
//         <button
//           className={`font-semibold text-lg py-1 px-3 rounded-tr-lg ${selectedPackage === 'standard' ? 'bg-green-600 text-white' : 'bg-gray-200 text-gray-700'}`}
//           onClick={() => setSelectedPackage('standard')}
//         >
//           Standard
//         </button>
//       </div>

//       {/* Pricing Section */}
//       <div className="text-center py-4 border-b border-purple-300">
//         {selectedPackage === 'basic' ? (
//           <>
//             <span className={`${featuresPackage?.offerPrice ? 'text-red-500 line-through' : 'text-red-500 text-lg font-semibold'} `}>৳ {featuresPackage?.price}</span>
//             {
//               featuresPackage?.offerPrice > 0 && (
//                 <span className="text-black font-bold text-2xl mx-2">৳ {featuresPackage?.offerPrice}</span>
//               )
//             }
//             <span className="text-gray-600">/ ১২ মাস</span>
//           </>
//         ) : (
//           <>
//           <span className={`${featuresPackage?.offerPrice ? 'text-red-500 line-through' : 'text-red-500 text-lg font-semibold'} `}>৳ {featuresPackage?.price}</span>
//           {
//             featuresPackage?.offerPrice > 0 && (
//               <span className="text-black font-bold text-2xl mx-2">৳ {featuresPackage?.offerPrice}</span>
//             )
//           }
//           <span className="text-gray-600">/ ১২ মাস</span>
//         </>
//         )}
//       </div>

//       {/* Feature List */}
//       <ul className="px-6 py-4 space-y-2 text-gray-700">
//         {featuresPackage?.facilities.map((feature, index) => (
//           <li key={index} className="flex items-center space-x-2">
//             {feature.available ? (
//               <svg className="w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
//                 <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
//               </svg>
//             ) : (
//               <svg className="w-5 h-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
//                 <path fillRule="evenodd" d="M5.293 4.293a1 1 0 011.414 0L10 7.586l3.293-3.293a1 1 0 111.414 1.414L11.414 9l3.293 3.293a1 1 0 01-1.414 1.414L10 10.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 9 5.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
//               </svg>
//             )}
//             <span>{feature.name}</span>
//           </li>
//         ))}
//       </ul>

//       {/* Button */}
//       <div className="px-6 py-4">
//         <button onClick={() => handleChoosePackage(featuresPackage)} className="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 rounded-lg">
//           Choose Package
//         </button>
//       </div>
//     </div>
//   );
// };

// export default PackageComponent;


import React, { useEffect } from 'react';
import {  FaTrash } from 'react-icons/fa';
import { getReviews,deleteReview } from '../../features/reviews/reviewSlice';
import { useDispatch, useSelector } from 'react-redux';
import LoaderComponent from '../LoaderComponent';

const ReviewList = () => {
  const { reviews, loading, error} = useSelector((state) => state.review);



  const dispatch = useDispatch();

  // fetch all resellers on mount
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('access_token'));
    dispatch(getReviews(token));
  }, [dispatch]);

  const handleDelete = (reviewId) => {
    if (window.confirm('Are you sure you want to delete this reseller?')) {
      dispatch(deleteReview({id:reviewId, token: JSON.parse(localStorage.getItem("access_token"))})); 
    }
  };



  if(loading) return <LoaderComponent />
  if(error) return <p className="text-red-500">{error}</p>

  return (
    <div className="container mx-auto p-6 mb-8 h-screen ">
     
      <div className="shadow-lg rounded-lg overflow-hidden">
       {
        reviews.length > 0 ? (
          <div className='shadow-lg rounded-lg overflow-x-auto'>
          <table className="min-w-full bg-white">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-2 px-4">UserId</th>
              <th className="py-2 px-4">ProductId</th>
              <th className="py-2 px-4">Rating</th>
              <th className="py-2 px-4">Comment</th>
              <th className="py-2 px-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {reviews.map((review) => (
              <tr key={review._id} className="border-t">
                <td className="py-2 px-4">{review.user}</td>
                <td className="py-2 px-4">{review.product}</td>
                <td className="py-2 px-4">{review.rating}</td>
                <td className="py-2 px-4">{review.comment} </td>
                <td className="py-2 px-4 flex gap-2">
                
                  <button
                    onClick={() => handleDelete(review._id)}
                    className="bg-red-500 text-white p-2 rounded-full shadow-md hover:bg-red-600 transition-all"
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        ):(
          <p>No resellers found.</p>
        )
       }
      </div>

      
  </div>
  )
}

export default ReviewList
import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = ({ slides = [] }) => {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    arrows: false,
    infinite: slides.length > 1,
    speed: 3000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div
        style={{
          position: 'absolute',
          bottom: '10px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {dots}
      </div>
    ),
    customPaging: (i) => (
      <div className="h-2 w-2 md:h-3 md:w-3 rounded-full bg-gray-400 transition duration-300 hover:bg-gray-600"></div>
    ),
  };

  const nextSlide = () => sliderRef.current.slickNext();
  const prevSlide = () => sliderRef.current.slickPrev();

  return (
    <div className="relative w-full overflow-hidden bg-white ">
      <Slider ref={sliderRef} {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="relative w-full h-[40vh] md:h-[70vh] lg:h-[calc(100vh-200px)] min-h-[50vh]">
            <a href={slide.url} className="block h-full">
              <img
                src={slide.image}
                alt={`Slide ${index}`}
                className="object-cover w-full h-full transition-transform duration-500 hover:scale-105"
              />
            </a>
          </div>
        ))}
      </Slider>

      {/* Manual Navigation Buttons */}
      <button
        onClick={prevSlide}
        className="absolute left-2 md:left-5 top-1/2 transform -translate-y-1/2 bg-gradient-to-r from-pink-500 to-orange-500 text-white p-2 md:p-3 rounded-full shadow-lg hover:bg-gradient-to-l transition-all duration-300"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 md:h-6 md:w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <button
        onClick={nextSlide}
        className="absolute right-2 md:right-5 top-1/2 transform -translate-y-1/2 bg-gradient-to-r from-pink-500 to-orange-500 text-white p-2 md:p-3 rounded-full shadow-lg hover:bg-gradient-to-l transition-all duration-300"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 md:h-6 md:w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
  );
};

export default Carousel;











import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api/api';


// create a subscription order by reseller
export const createSubscriptionOrder = createAsyncThunk('subscriptionOrder/createSubscriptionOrder', async ({subscriptionOrder,token}) => {
    const response = await api.post('/subscription-orders', subscriptionOrder,{headers: {
        "Content-Type":"application/json",
        "Authorization": `Bearer ${token}`
    }});
    return response.data;
});

// get a single subscription order by  reseller with reseller id
export const getSingleSubscriptionOrder = createAsyncThunk('subscriptionOrder/getSingleSubscriptionOrder', async ({resellerId,token}) => {
    const response = await api.get(`/subscription-orders/reseller/${resellerId}`,{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
})

// get all subscription orders by admin
export const getSubscriptionOrders = createAsyncThunk('subscriptionOrder/getSubscriptionOrders', async (token) => {
    const response = await api.get('/subscription-orders',{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
});

// delete subscription order by admin
export const deleteSubscriptionOrder = createAsyncThunk('subscriptionOrder/deleteSubscriptionOrder', async ({subscriptionOrderId,token}) => {
    const response = await api.delete(`/subscription-orders/${subscriptionOrderId}`,{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
});

// subscription order update by reseller when make payment request

export const updateSubscriptionOrder = createAsyncThunk('subscriptionOrder/updateSubscriptionOrder', async ({subscriptionOrderId,subscriptionOrderData,token}) => {
    const response = await api.put(`/subscription-orders/${subscriptionOrderId}`, subscriptionOrderData, {headers: {
        "Content-Type":"application/json",
        "Authorization": `Bearer ${token}`
    }});
    return response.data;
});

// update subscription order by admin
export const updateSubscriptionOrderAdmin = createAsyncThunk('subscriptionOrder/updateSubscriptionOrderAdmin', async ({subscriptionOrderId,subscriptionOrderData,token}) => {
    const response = await api.put(`/admin/subscription-orders/${subscriptionOrderId}`, subscriptionOrderData, {headers: {
        "Content-Type":"application/json",
        "Authorization": `Bearer ${token}`
    }});
    return response.data;
});

const  subscriptionOrderSlice = createSlice({
    name: 'subscriptionOrder',
    initialState: {
        subscriptionOrders: [],
        singleSubscriptionOrder: {},
        updatedSubscriptionOrder: {},
        subscriptionOrderByReseller: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createSubscriptionOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(createSubscriptionOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.subscriptionOrders.push(action.payload);
                state.singleSubscriptionOrder = action.payload;
            })
            .addCase(createSubscriptionOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getSubscriptionOrders.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSubscriptionOrders.fulfilled, (state, action) => {
                state.loading = false;
                state.subscriptionOrders = action.payload;
            })
            .addCase(getSubscriptionOrders.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(deleteSubscriptionOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteSubscriptionOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.subscriptionOrders = state.subscriptionOrders.filter((subscriptionOrder) => subscriptionOrder._id !== action.payload._id);
            })
            .addCase(deleteSubscriptionOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(updateSubscriptionOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateSubscriptionOrder.fulfilled, (state, action) => {
                state.subscriptionOrders = state.subscriptionOrders.map((subscriptionOrder) => subscriptionOrder._id === action.payload._id ? action.payload : subscriptionOrder);
                state.updatedSubscriptionOrder = action.payload;
                state.loading = false;
            })
            .addCase(updateSubscriptionOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getSingleSubscriptionOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleSubscriptionOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.subscriptionOrderByReseller = action.payload;
            })
            .addCase(getSingleSubscriptionOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(updateSubscriptionOrderAdmin.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateSubscriptionOrderAdmin.fulfilled, (state, action) => {
                state.subscriptionOrders = state.subscriptionOrders.map((subscriptionOrder) => subscriptionOrder._id === action.payload._id ? action.payload : subscriptionOrder);
                state.updatedSubscriptionOrder = action.payload;
                state.loading = false;
            })
            .addCase(updateSubscriptionOrderAdmin.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
    },
});
      
export default subscriptionOrderSlice.reducer;


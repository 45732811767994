import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ManualSuccessPayment = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white relative overflow-hidden">
      {/* Confetti background effect */}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="w-full h-full bg-confetti-pattern opacity-20"></div>
      </div>
      
      {/* Success card */}
      <div className="relative z-10 bg-white p-6 rounded-lg shadow-lg text-center max-w-md">
        <FaCheckCircle className="text-green-500 text-5xl mx-auto mb-4" />
        <h2 className="text-2xl font-bold text-gray-800 mb-2">Payment Request Succeed!</h2>
        <p className="text-gray-600 mb-4">
          Thank you for processing your most recent payment.
          {/* <br />
          Your premium subscription will expire on June 2, 2024. */}
          our team will update you as an active reseller. If any issue occurs, please contact us.
        </p>
        <button
          className="bg-gray-800 text-white font-semibold py-2 px-4 rounded hover:bg-gray-700 transition-colors"
          onClick={() => navigate( "/reseller/dashboard")}
        >
          go to Your dashboard
        </button>
      </div>
    </div>
  );
};

export default ManualSuccessPayment;

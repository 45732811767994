import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUpcomingProducts,
  getNewArrivalProducts,
  getJustForYouProducts,
  getProductsOnOffer,
  resetOfferProducts
} from "../features/products/productsSlice";
import { getSliderImagesForEcommerce } from "../features/sliderImage/sliderImageSlice";
import ProductCard from "../components/ProductCard";
import Carousel from "../components/Carousel";
import LoaderComponent from "../components/LoaderComponent";
import CategoryMenu from "../components/CategoryMenu";
import { Link } from "react-router-dom";
import SEO from "../components/Seo";

const Shop = () => {
  const dispatch = useDispatch();
  const {
    newArrival,
    justForYouProducts,
    upcomingProducts,
    productsOnOffer,
    loading,
    error,
  } = useSelector((state) => state.products);

  const { sliderImagesForEcommerce } = useSelector(
    (state) => state.sliderImage
  );

  useEffect(() => {
    dispatch(getUpcomingProducts());
    dispatch(getNewArrivalProducts());
    dispatch(getJustForYouProducts());
    dispatch(getSliderImagesForEcommerce());
    dispatch(getProductsOnOffer({ page: 1, limit: 5 }));
  }, [dispatch]);

 
  // reset the products on offer state when the component unmounts
  useEffect(() => {
    return () => {
      dispatch(resetOfferProducts());
    };
  }, [dispatch]);

  if (loading) return <LoaderComponent message="loading products" />;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="container mx-auto px-4 py-8">
      <SEO
        title={"Paribar || Shop"}
        url={"https://www.paribar.com/shop"}
        description={
          "Welcome to Paribar Shop. We are dedicated to providing innovative solutions that push the boundaries and deliver unmatched quality to our clients."
        }
      />
      {sliderImagesForEcommerce && sliderImagesForEcommerce.length > 0 && (
        <Carousel slides={sliderImagesForEcommerce} />
      )}
      <CategoryMenu />
      {productsOnOffer && productsOnOffer.length > 0 && (
        <>
          <div className="flex  justify-between w-full  p-4">
            <h2 className="text-xs sm:text-sm md:text-lg font-bold line-clamp-2">PRODUCTS ON OFFER</h2>
            <Link
              to="/shop/products/on-offer"
              className="bg-gray-600 hover:bg-gray-700 text-center text-xs sm:text-sm md:text-lg font-bold line-clamp-2 text-white p-1"
            >
              SEE ALL OFFER PRODUCTS
            </Link>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
            {productsOnOffer.map((product) => (
              <ProductCard key={product._id} product={product} />
            ))}
          </div>
        </>
      )}
      {newArrival && newArrival.length > 0 && <>
      <h2 className="text-2xl font-bold mb-4 mt-4">NEW ARRIVAL</h2>
      <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
        {newArrival.map((product) => (
          <ProductCard key={product._id} product={product} />
        ))}
      </div>
      </>
      }
      {upcomingProducts && upcomingProducts.length > 0 && (
        <>
          <h2 className="text-2xl font-bold mb-4 mt-4">UPCOMING</h2>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
            {upcomingProducts.map((product) => (
              <ProductCard key={product._id} product={product} />
            ))}
          </div>
        </>
      )}
      { 
      justForYouProducts && justForYouProducts.length > 0 && 
      <>
      <div className="flex  justify-between w-full  p-4">
        <h2 className="text-xs sm:text-sm md:text-lg font-bold line-clamp-2">JUST FOR YOU</h2>
        <Link
          to="/shop/products"
          className="bg-gray-600 hover:bg-gray-700 text-center text-xs sm:text-sm md:text-lg font-bold line-clamp-2 text-white p-1"
        >
          SEE ALL PRODUCTS
        </Link>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
        {justForYouProducts.map((product) => (
          <ProductCard key={product._id} product={product} />
        ))}
      </div>

      </>
      }
    </div>
  );
};

export default Shop;

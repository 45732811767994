import React, { useState } from 'react';
import { updateSubscriptionOrderAdmin } from '../../features/subscriptionOrder/subscriptionOrderSlice';
import { useDispatch } from 'react-redux';

const EditSubscriptionOrderModal = ({subscriptionOrderId, currentStatus, currentPaymentStatus, setEditing }) => {
  const [status, setStatus] = useState(currentStatus);
  const [paymentStatus, setPaymentStatus] = useState(currentPaymentStatus);

  const dispatch = useDispatch();


  const handleSave = () => {
    dispatch(updateSubscriptionOrderAdmin({subscriptionOrderId, subscriptionOrderData: {status, paymentStatus}, token: JSON.parse(localStorage.getItem("access_token"))}));
    setEditing(false); 
  };

  const handleClose = () => {
    setEditing(false); 
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg w-1/3 p-6">
        <h2 className="text-xl font-semibold mb-4">Edit Subscription Order</h2>
        
        {/* Status Field */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Status</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="pending">Pending</option>
            <option value="active">Active</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>

        {/* Payment Status Field */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Payment Status</label>
          <select
            value={paymentStatus}
            onChange={(e) => setPaymentStatus(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="pending">Pending</option>
            <option value="succeed">Succeed</option>
            <option value="failed">Failed</option>
          </select>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-4">
          <button
            onClick={handleClose}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSubscriptionOrderModal;

import React from 'react';
import PackageForm from './PackageForm';
import { useDispatch } from 'react-redux';
import { createPackage } from '../../features/package/packageSlice';

const ShowPackageFormModal = ({ setShowCreateForm }) => {

  const dispatch = useDispatch();

  const handleFormSubmit = (values) => {
    dispatch(createPackage(values));
    setShowCreateForm(false);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md mx-4 sm:mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Create Package</h2>
          <button
            onClick={() => setShowCreateForm(false)}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>
        <PackageForm onSubmit={handleFormSubmit} />
      </div>
    </div>
  );
};

export default ShowPackageFormModal;

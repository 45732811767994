import React from "react";
import IntroductionImage from "../images/introduction.jpg";
import OverviewImage from "../images/overview.jpg";
import MissionImage from "../images/mission.jpg";
import MissionImage1 from "../images/mission1.jpg";
import MissionImage2 from "../images/mission2.jpg";
import MissionImage3 from "../images/mission3.jpg";
import VisionImage from "../images/vision.jpg";
import VisionImage1 from "../images/vision1.jpg";
import VisionImage2 from "../images/vision2.jpg";
import VisionImage3 from "../images/vision3.jpg";
import SeeMore from "./SeeMore";

const CompanyProfile = () => {

  const missionTxt = ` Our vision is to revolutionize industries by reducing the
              dependency on human labor, thereby creating opportunities for
              additional income streams and fostering economic growth. By
              leveraging innovation and technology, we aim to empower
              individuals and businesses, streamlining processes while enhancing
              productivity. Over the next five years, our efforts will focus on
              driving sustainable economic development, enabling equitable
              progress for all. We are committed to building a future where
              automation and human ingenuity coexist harmoniously, propelling
              our nation toward prosperity and global competitiveness. Together,
              we envision a thriving economy built on efficiency, inclusively,
              and innovation. `;
  
  const visionTxt = `Our mission is to create opportunities for individuals to earn
              additional income while continuing their existing occupations. We
              aim to empower people by ensuring they have access to high quality
              products at fair prices delivered conveniently to their homes. By
              doing so, we reduce the need for excessive labor, allowing
              individuals to focus on living more fulfilling and balanced lives.
              We are dedicated to building a system that not only improves
              financial stability but also promotes convenience, fairness, and
              trust in every transaction. Through innovation and commitment, we
              strive to simplify daily lives, reduce unnecessary stress, and
              provide more time for people to enjoy meaningful experiences. By
              prioritizing customer satisfaction and ethical practices, our
              mission is to contribute to a society where individuals can work
              less, live better, and pursue their aspirations in harmony with
              economic and social growth.`

  return (
    <div className="container mx-auto p-8 bg-white">
      {/* Introduction Section */}
      <section className="mb-16 flex flex-col lg:flex-row items-center">
        <div className="lg:w-1/2 mb-8 mr-4 lg:mb-0">
          <img
            src={IntroductionImage}
            alt="Introduction"
            className="rounded-lg shadow-lg w-full p-4 animate-bounceSlow" // Infinite bounce
          />
        </div>
        <div className="lg:w-1/2 text-center lg:text-left">
          <h2 className="text-4xl font-bold text-gray-800 mb-4">
            Our Introduction
          </h2>
          <p className="text-gray-600 text-lg align-justify">
            We are dedicated to providing innovative solutions that push the
            boundaries and deliver unmatched quality to our clients.
          </p>
        </div>
      </section>

      {/* Overview Section */}
      <section className="mb-16 flex flex-col lg:flex-row items-center">
        <div className="lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0">
          <h2 className="text-4xl font-bold text-gray-800 mb-4">
            Our Overview
          </h2>
          <p className="text-gray-600 text-lg align-justify">
            Our journey began with a vision to redefine the industry standards
            and make a positive impact through our products and services.
          </p>
        </div>
        <div className="lg:w-1/2">
          <img
            src={OverviewImage}
            alt="Overview"
            className="rounded-lg shadow-lg w-full p-4 animate-fadeIn" // Fade-in animation
          />
        </div>
      </section>

      {/* Mission Section */}
      <section className="mb-16">
        <div className="flex flex-col lg:flex-row items-center">
          <div className="lg:w-1/2 flex justify-center">
            <div className="grid grid-cols-2 gap-4 animate-bounceSlow">
              {" "}
              {/* Slow bounce on the image grid */}
              <img
                src={MissionImage}
                alt="Mission1"
                className="rounded-lg shadow-lg"
              />
              <img
                src={MissionImage1}
                alt="Mission2"
                className="rounded-lg shadow-lg"
              />
              <img
                src={MissionImage2}
                alt="Mission3"
                className="rounded-lg shadow-lg"
              />
              <img
                src={MissionImage3}
                alt="Mission4"
                className="rounded-lg shadow-lg"
              />
            </div>
          </div>
          <div className="lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0 ml-4">
            <h2 className="text-4xl font-bold text-gray-800 mb-4 text-center">
              Our Mission
            </h2>
            <SeeMore text={missionTxt} />
          </div>
        </div>
      </section>

      {/* Vision Section */}
      <section className="mb-16">
        <div className="flex flex-col lg:flex-row items-center">
          <div className="lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0">
            <h2 className="text-4xl font-bold text-gray-800 mb-4 text-center">
              Our Vision
            </h2>
            <SeeMore text={visionTxt} />
          </div>
          <div className="lg:w-1/2 flex justify-center ml-4">
            <div className="grid grid-cols-2 gap-4 animate-zoomIn">
              {" "}
              {/* Subtle zoom effect */}
              <img
                src={VisionImage}
                alt="Vision1"
                className="rounded-lg shadow-lg"
              />
              <img
                src={VisionImage1}
                alt="Vision2"
                className="rounded-lg shadow-lg"
              />
              <img
                src={VisionImage2}
                alt="Vision3"
                className="rounded-lg shadow-lg"
              />
              <img
                src={VisionImage3}
                alt="Vision4"
                className="rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CompanyProfile;

import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import locationImage from "../images/location.png";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-gray-900 to-gray-800 text-gray-300 py-12">
      <div className="container mx-auto grid-cols-1 grid grid-cols-2 gap-6 md:grid-cols-5 md:gap-12 px-8">
        {/* Contact Info Section */}
        <div className="flex flex-col items-center md:items-start">
          <img src={logo} alt="Paribar Logo" className="w-36 mb-4" />
          <ul className="text-sm space-y-2">
            <li>📞 +880 1900 985575</li>
            <li>📧 paribarmart24@gmail.com</li>
            <li>🌐 www.paribarmart.com</li>
            <li>📍 Mirpur 10, Kajipara, Dhaka</li>
          </ul>
        </div>

        <div className="flex flex-col items-center md:items-start">
          <h3 className="text-lg font-semibold text-green-400 mb-4">
            Our Groups
          </h3>
          <div className="space-y-3">
            {/* Facebook Group */}
            <a
              href="https://www.facebook.com/paribarfashion?mibextid=ZbWKwL"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-gray-400 sm:text-sm md:text-md hover:text-blue-500 transition transform hover:scale-105 duration-200"
            >
              <FaFacebook size={24} className="mr-2" />
              <span className="text-sm font-sm">Facebook Group</span>
            </a>

            {/* Reseller Group */}
            <a
              href="https://www.facebook.com/share/g/19o3hV9EGL/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-gray-400 hover:text-blue-500 transition transform hover:scale-105 duration-200"
            >
              <FaFacebook size={24} className="mr-2" />
              <span className="text-sm font-medium">Reseller Group</span>
            </a>

            {/* YouTube */}
            <a
              href="http://www.youtube.com/@paribarmart"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-gray-400 hover:text-red-500 transition transform hover:scale-105 duration-200"
            >
              <FaYoutube size={24} className="mr-2" />
              <span className="text-sm font-medium">YouTube</span>
            </a>

            {/* Twitter */}
            <a
              href="https://x.com/Paribar2024?t=4SN1pAoeZOdSZQs-7iG9_A&s=09"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-gray-400 hover:text-blue-400 transition transform hover:scale-105 duration-200"
            >
              <FaTwitter size={24} className="mr-2" />
              <span className="text-sm font-medium">Twitter</span>
            </a>

            {/* Instagram */}
            <a
              href="https://www.instagram.com/paribarbd2023?igsh=MXc1bGpybHU4dDN5"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-gray-400 hover:text-pink-500 transition transform hover:scale-105 duration-200"
            >
              <FaInstagram size={24} className="mr-2" />
              <span className="text-sm font-medium">Instagram</span>
            </a>
          </div>
        </div>

        {/* Information Section */}
        <div className="text-center md:text-left">
          <h3 className="text-lg font-semibold text-green-400 mb-4">
            INFORMATION
          </h3>
          <ul className="flex flex-col text-sm space-y-2">
            <Link
              to="/"
              className="hover:text-green-400 cursor-pointer transition duration-200"
            >
              Home
            </Link>
            {/* <Link
              to="/about"
              className="hover:text-green-400 cursor-pointer transition duration-200"
            >
              About Us
            </Link> */}
            <Link
              to="/contacts"
              className="hover:text-green-400 cursor-pointer transition duration-200"
            >
              Contact Us
            </Link>
            <Link
              to="/affiliate"
              className="hover:text-green-400 cursor-pointer transition duration-200"
            >
              Become an Affiliate
            </Link>
            <Link
              to="/terms-and-conditions"
              className="hover:text-green-400 cursor-pointer transition duration-200"
            >
              Terms & Conditions
            </Link>
          </ul>
        </div>

        {/* Help Center Section */}
        <div className="text-center md:text-left">
          <h3 className="text-lg font-semibold text-green-400 mb-4">
            HELP CENTER
          </h3>
          <ul className="flex flex-col text-sm space-y-2">
            <Link
              to="/faq"
              className="hover:text-green-400 cursor-pointer transition duration-200"
            >
              FAQ
            </Link>
            <Link
              to="/return-policy"
              className="hover:text-green-400 cursor-pointer transition duration-200"
            >
              Return Policy
            </Link>
            <Link
              to="/track-order"
              className="hover:text-green-400 cursor-pointer transition duration-200"
            >
              Track Order
            </Link>
          </ul>
        </div>

        {/* Map Section */}
        <div className="flex flex-col items-center">
          <h3 className="text-lg font-semibold text-green-400 mb-4">
            Location
          </h3>
          <img
            src={locationImage}
            alt="Map showing Paribar location"
            className="w-full rounded-lg shadow-lg"
          />
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="text-center mt-12 border-t border-gray-700 pt-6">
        <p className="text-sm text-gray-500">
          © {new Date().getFullYear()} Paribar. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;

// import React from "react";
// import { FaFacebook, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
// import locationImage from "../images/location.png";
// import logo from "../images/logo.png";
// import { Link } from "react-router-dom";

// const Footer = () => {
//   return (
//     <footer className="bg-gradient-to-r from-gray-900 to-gray-800 text-gray-300 py-12">
//       <div className="container mx-auto grid grid-cols-2 gap-6 md:grid-cols-4 md:gap-12 px-8">
//         {/* Contact Info Section */}
//         <div className="flex flex-col items-center md:items-start">
//           <img src={logo} alt="Paribar Logo" className="w-36 mb-4" />
//           <ul className="text-sm space-y-2">
//             <li>📞 +880 1900 985575</li>
//             <li>📧 paribarmart@gmail.com</li>
//             <li>🌐 www.paribarmart.com</li>
//             <li>📍 Mirpur 10, Kajipara, Dhaka</li>
//           </ul>
//           <div className="mt-6 grid gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 justify-items-center">
//             <a
//               href="https://www.facebook.com/paribarfashion?mibextid=ZbWKwL"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="flex items-center space-x-2 text-gray-400 hover:text-blue-500 transition transform hover:scale-105 duration-200"
//             >
//               <FaFacebook size={28} className="align-middle" />
//               <span className="text-lg font-medium leading-none">Facebook</span>
//             </a>

//             <a
//               href="https://www.facebook.com/share/g/19o3hV9EGL/"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="flex items-center space-x-2 text-gray-400 hover:text-blue-500 transition transform hover:scale-105 duration-200"
//             >
//               <FaFacebook size={28} className="align-middle" />
//               <span className="text-lg font-medium leading-none">Reseller</span>
//             </a>

//             <a
//               href="http://www.youtube.com/@paribarmart"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="flex items-center space-x-2 text-gray-400 hover:text-red-500 transition transform hover:scale-105 duration-200"
//             >
//               <FaYoutube size={28} className="align-middle" />
//               <span className="text-lg font-medium leading-none">YouTube</span>
//             </a>

//             <a
//               href="https://x.com/Paribar2024?t=4SN1pAoeZOdSZQs-7iG9_A&s=09"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="flex items-center space-x-2 text-gray-400 hover:text-blue-400 transition transform hover:scale-105 duration-200"
//             >
//               <FaTwitter size={28} className="align-middle" />
//               <span className="text-lg font-medium leading-none">Twitter</span>
//             </a>

//             <a
//               href="https://www.instagram.com/paribarbd2023?igsh=MXc1bGpybHU4dDN5"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="flex items-center space-x-2 text-gray-400 hover:text-pink-500 transition transform hover:scale-105 duration-200"
//             >
//               <FaInstagram size={28} className="align-middle" />
//               <span className="text-lg font-medium leading-none">
//                 Instagram
//               </span>
//             </a>
//           </div>
//         </div>

//         {/* Information Section */}
//         <div className="text-center md:text-left">
//           <h3 className="text-lg font-semibold text-green-400 mb-4">
//             INFORMATION
//           </h3>
//           <ul className="flex flex-col text-sm space-y-2">
//             <Link
//               to="/"
//               className="hover:text-green-400 cursor-pointer transition duration-200"
//             >
//               Home
//             </Link>
//             <Link
//               to="/about"
//               className="hover:text-green-400 cursor-pointer transition duration-200"
//             >
//               About Us
//             </Link>
//             <Link
//               to="/contacts"
//               className="hover:text-green-400 cursor-pointer transition duration-200"
//             >
//               Contact Us
//             </Link>
//             <Link
//               to="/affiliate"
//               className="hover:text-green-400 cursor-pointer transition duration-200"
//             >
//               Become an Affiliate
//             </Link>
//             <Link
//               to="/terms-and-conditions"
//               className="hover:text-green-400 cursor-pointer transition duration-200"
//             >
//               Terms & Conditions
//             </Link>
//           </ul>
//         </div>

//         {/* Help Center Section */}
//         <div className="text-center md:text-left">
//           <h3 className="text-lg font-semibold text-green-400 mb-4">
//             HELP CENTER
//           </h3>
//           <ul className="flex flex-col text-sm space-y-2">
//             <Link
//               to="/faq"
//               className="hover:text-green-400 cursor-pointer transition duration-200"
//             >
//               FAQ
//             </Link>
//             <Link
//               to="/return-policy"
//               className="hover:text-green-400 cursor-pointer transition duration-200"
//             >
//               Return Policy
//             </Link>
//             <Link
//               to="/track-order"
//               className="hover:text-green-400 cursor-pointer transition duration-200"
//             >
//               Track Order
//             </Link>
//           </ul>
//         </div>

//         {/* Map Section */}
//         <div className="flex flex-col items-center">
//           <h3 className="text-lg font-semibold text-green-400 mb-4">
//             Location
//           </h3>
//           <img
//             src={locationImage}
//             alt="Map showing Paribar location"
//             className="w-full rounded-lg shadow-lg"
//           />
//         </div>
//       </div>

//       <div className="text-center mt-12 border-t border-gray-700 pt-6">
//         <p className="text-sm text-gray-500">
//           © {new Date().getFullYear()} Paribar. All rights reserved.
//         </p>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllReferCommission, getAllReferralsByAffiliate } from '../../features/allReferrals/allReferralSlice';
import LoaderComponent from '../LoaderComponent';

const ReferralContent = () => {
  const { allReferralsByAffiliate, allReferCommission, loading } = useSelector((state) => state.allReferral);
  const [activeTab, setActiveTab] = useState('affiliateReferral');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllReferralsByAffiliate());
    dispatch(getAllReferCommission());
  }, [dispatch]);

  const totalAffiliateCommission = allReferralsByAffiliate.reduce((sum, referral) => sum + referral.commission, 0);
  const totalReferCommission = allReferCommission.reduce((sum, commission) => sum + commission.commission, 0);

  if(loading) return <LoaderComponent message="Loading referrals..." />

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4">My Referrals</h2>

      {/* Tab Navigation */}
      <div className="flex justify-center mb-6">
        <button
          onClick={() => setActiveTab('affiliateReferral')}
          className={`px-4 py-2 mx-2 font-semibold rounded ${
            activeTab === 'affiliateReferral' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
          }`}
        >
          Affiliate Referral
        </button>
        <button
          onClick={() => setActiveTab('referCommission')}
          className={`px-4 py-2 mx-2 font-semibold rounded ${
            activeTab === 'referCommission' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
          }`}
        >
          Refer Commission
        </button>
      </div>

      {/* Summary Section */}
      <div className="grid grid-cols-3 gap-4 mb-6">
        <SummaryCard label="Total Commission" amount={`TK. ${activeTab === 'affiliateReferral' ? totalAffiliateCommission : totalReferCommission}`} />
        <SummaryCard label="Total Earned" amount={`$TK. ${activeTab === 'affiliateReferral' ? totalAffiliateCommission : totalReferCommission}`} />
      </div>

      {/* Referrals List */}
      <div className="bg-white p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">{activeTab === 'affiliateReferral' ? 'Affiliate Referral Details' : 'Refer Commission Details'}</h3>
        <div className="divide-y">
          {(activeTab === 'affiliateReferral' ? allReferralsByAffiliate : allReferCommission).length === 0 ? (
            <p className="text-gray-500 text-center">You have no {activeTab === 'affiliateReferral' ? 'affiliate referrals' : 'refer commissions'}.</p>
          ) : (
            (activeTab === 'affiliateReferral' ? allReferralsByAffiliate : allReferCommission).map((referral, index) => (
              <ReferralCard
                key={index}
                referral={referral}
                isAffiliate={activeTab === 'affiliateReferral'}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

// Summary Card Component
const SummaryCard = ({ label, amount }) => (
  <div className="p-4 bg-blue-500 text-white rounded-lg shadow-md">
    <p className="text-sm font-medium">{label}</p>
    <p className="text-xl font-bold">{amount}</p>
  </div>
);

// Referral Card Component
const ReferralCard = ({ referral, isAffiliate }) => (
  <div className="flex justify-between items-center py-3">
    <div>
      {isAffiliate ? (
        <>
          <p className="text-gray-700 font-medium">Order ID: {referral.order}</p>
          <p className="text-sm text-gray-500">Commission: TK. {referral.commission}</p>
        </>
      ) : (
        <>
          <p className="text-gray-700 font-medium">User ID: {referral.referralOfAffiliate}</p>
          <p className="text-sm text-gray-500">Commission: TK. {referral.commission}</p>
        </>
      )}
    </div>
    {/* {isAffiliate && (
      <button className="text-blue-500 text-sm underline hover:text-blue-700">
        View Order
      </button>
    )} */}
  </div>
);

export default ReferralContent;

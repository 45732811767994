import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center p-8 bg-white shadow-xl rounded-lg max-w-md transform transition duration-300 ease-in-out hover:scale-105">
        {/* 404 Icon or Image */}
        <h1 className="text-6xl font-bold text-blue-500 mb-4">404</h1>

        {/* Message */}
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">
          Page Not Found
        </h2>
        <p className="text-gray-500 mb-6">
          Oops! The page you're looking for doesn't exist.
        </p>

        {/* Go Home Button */}
        <button
          onClick={handleGoHome}
          className="flex items-center justify-center bg-blue-600 text-white text-lg font-medium py-2 px-6 rounded-full shadow-lg hover:bg-blue-700 transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-xl"
        >
          <FaHome className="mr-2" />
          Go to Home Page
        </button>
      </div>
    </div>
  );
};

export default NotFound;

import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createExecutive,
  updateExecutive,
} from "../../features/executive/executiveSlice";
import { useDispatch } from "react-redux";

const ExecutiveForm = ({ initialValues, onClose, isEditing }) => {

  const [image, setImage] = useState(initialValues?.profilePicture || null);
  const dispatch = useDispatch();

  const { name, contact, profilePicture, role, message, socialLinks } =
    initialValues || {};
  const newValues = {
    name,
    contact,
    profilePicture,
    role,
    message,
    socialLinks,
  };

  const handleImageChange = (e) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const imagePreview = reader.result;
      setImage(imagePreview);
      formik.setFieldValue("profilePicture", imagePreview);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const formik = useFormik({
    initialValues: newValues || {
      name: "",
      contact: { phone: "", email: "", address: "" },
      profilePicture: "",
      role: "Managing Director",
      message: { title: "", body: "" },
      socialLinks: { linkedin: "", twitter: "", facebook: "", instagram: "" },
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      contact: Yup.object({
        phone: Yup.string().required("Phone is required"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
        address: Yup.string(),
      }),
      role: Yup.string().oneOf([
        "Managing Director",
        "CEO",
        "COO",
        "CFO",
        "CTO",
        "Employee",
      ]),
      message: Yup.object({
        title: Yup.string().required("Message title is required"),
        body: Yup.string().required("Message body is required"),
      }),
      socialLinks: Yup.object({
        linkedin: Yup.string().url("Invalid URL format"),
        twitter: Yup.string().url("Invalid URL format"),
        facebook: Yup.string().url("Invalid URL format"),
        instagram: Yup.string().url("Invalid URL format"),
      }),
    }),
    onSubmit: (values,{resetForm}) => {
      if (isEditing) {
        dispatch(updateExecutive({executiveId:initialValues._id,executiveData:values}));
        resetForm()
        onClose(); // Close the modal after submission
      } else {
        dispatch(createExecutive(values));
        resetForm()
        onClose()
      }
    },
  });

 

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-md shadow-md">
      <form onSubmit={formik.handleSubmit} className="space-y-4">
        {/* name */}
        <div>
          <label className="block text-sm font-medium text-gray-600">
            Name
          </label>
          <input
            type="text"
            name="name"
            className="w-full p-2 border rounded-md"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name && (
            <p className="text-red-500 text-xs mt-1">{formik.errors.name}</p>
          )}
        </div>

        {/* Contact */}
        <div>
          <h3 className="text-lg font-medium text-gray-700">Contact</h3>
          <label className="block text-sm font-medium text-gray-600">
            Phone
          </label>
          <input
            type="text"
            name="contact.phone"
            className="w-full p-2 border rounded-md"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contact?.phone}
          />
          {formik.touched.contact?.phone && formik.errors.contact?.phone && (
            <p className="text-red-500 text-xs mt-1">
              {formik.errors.contact?.phone}
            </p>
          )}

          <label className="block text-sm font-medium text-gray-600 mt-2">
            Email
          </label>
          <input
            type="email"
            name="contact.email"
            className="w-full p-2 border rounded-md"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contact?.email}
          />
          {formik.touched.contact?.email && formik.errors.contact?.email && (
            <p className="text-red-500 text-xs mt-1">
              {formik.errors.contact?.email}
            </p>
          )}

          <label className="block text-sm font-medium text-gray-600 mt-2">
            Address
          </label>
          <input
            type="text"
            name="contact.address"
            className="w-full p-2 border rounded-md"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contact?.address}
          />
        </div>

        {/* Role */}
        <div>
          <label className="block text-sm font-medium text-gray-600">
            Role
          </label>
          <select
            name="role"
            className="w-full p-2 border rounded-md"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.role}
          >
            <option value="Managing Director">Managing Director</option>
            <option value="CEO">CEO</option>
            <option value="COO">COO</option>
            <option value="CFO">CFO</option>
            <option value="CTO">CTO</option>
            <option value="Employee">Employee</option>
          </select>
        </div>

        {/* Message */}
        <div>
          <label className="block text-sm font-medium text-gray-600">
            Message Title
          </label>
          <input
            type="text"
            name="message.title"
            className="w-full p-2 border rounded-md"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message?.title}
          />
          {formik.touched.message?.title && formik.errors.message?.title && (
            <p className="text-red-500 text-xs mt-1">
              {formik.errors.message?.title}
            </p>
          )}

          <label className="block text-sm font-medium text-gray-600 mt-2">
            Message Body
          </label>
          <textarea
            name="message.body"
            className="w-full p-2 border rounded-md"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message?.body}
          />
          {formik.touched.message?.body && formik.errors.message?.body && (
            <p className="text-red-500 text-xs mt-1">
              {formik.errors.message?.body}
            </p>
          )}
        </div>

        {/* Social Links */}
        <div>
          <h3 className="text-lg font-medium text-gray-700">Social Links</h3>
          <label className="block text-sm font-medium text-gray-600">
            LinkedIn
          </label>
          <input
            type="url"
            name="socialLinks.linkedin"
            className="w-full p-2 border rounded-md"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.socialLinks?.linkedin}
          />
          <label className="block text-sm font-medium text-gray-600 mt-2">
            Twitter
          </label>
          <input
            type="url"
            name="socialLinks.twitter"
            className="w-full p-2 border rounded-md"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.socialLinks?.twitter}
          />
          <label className="block text-sm font-medium text-gray-600 mt-2">
            Facebook
          </label>
          <input
            type="url"
            name="socialLinks.facebook"
            className="w-full p-2 border rounded-md"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.socialLinks?.facebook}
          />
          <label className="block text-sm font-medium text-gray-600 mt-2">
            Instagram
          </label>
          <input
            type="url"
            name="socialLinks.instagram"
            className="w-full p-2 border rounded-md"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.socialLinks?.instagram}
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Upload Images</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="w-full mt-2 p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="flex flex-wrap mb-4">
          {image && (
            <img
              src={image}
              alt="Preview profile"
              className="w-24 h-24 object-cover mr-2 mb-2 rounded"
            />
          )}
        </div>

        <button
          type="submit"
          className="w-full mt-4 p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          {isEditing ? "Update Executive" : "Create Executive"}
        </button>
      </form>
    </div>
  );
};

export default ExecutiveForm;

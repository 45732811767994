import React from 'react';

const PackageDetails = ({ packageData = {}, setShowDetails }) => {
  // Destructure packageData properties with default values to avoid undefined errors
  const { name = 'N/A', price = 0, offerPrice = 0, facilities = [] } = packageData;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 overflow-y-auto transition-all duration-300 ease-in-out">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg mx-4 sm:mx-auto transform transition-all duration-500 ease-in-out">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">{name} Package Details</h2>
          <button
            onClick={() => setShowDetails(false)}
            className="text-gray-500 hover:text-gray-700 text-xl"
          >
            ✕
          </button>
        </div>

        {/* Scrollable content area */}
        <div className="space-y-4 max-h-96 overflow-y-auto">
          {/* Package Name */}
          <div>
            <h3 className="text-gray-700 font-medium">Package Type</h3>
            <p className="text-gray-600">{name}</p>
          </div>

          {/* Price */}
          <div>
            <h3 className="text-gray-700 font-medium">Price</h3>
            <p className="text-gray-600">${price.toFixed(2)}</p>
          </div>

          {/* Offer Price */}
          {offerPrice > 0 && (
            <div>
              <h3 className="text-gray-700 font-medium">Offer Price</h3>
              <p className="text-red-500 text-lg font-semibold">${offerPrice.toFixed(2)}</p>
            </div>
          )}

          {/* Facilities */}
          {facilities.length > 0 && (
            <div>
              <h3 className="text-gray-700 font-medium">Facilities</h3>
              <ul className="list-disc pl-5 space-y-1 text-gray-600">
                {facilities.map((facility, index) => (
                  <li key={index} className="flex items-center">
                    <span>{facility.name}</span>
                    <span
                      className={`ml-2 text-sm font-semibold ${
                        facility.available ? 'text-green-500' : 'text-red-500'
                      }`}
                    >
                      {facility.available ? 'Available' : 'Not Available'}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {/* Close Button */}
        <div className="mt-6">
          <button
            onClick={() => setShowDetails(false)}
            className="w-full bg-blue-500 text-white py-2 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none transition-all duration-300"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PackageDetails;
















// import React from 'react';

// const PackageDetails = ({ packageData = {}, setShowDetails}) => {
//   // Destructure packageData properties with default values to avoid undefined errors
//   const {
//     name = 'N/A',
//     price = 0,
//     offerPrice = 0,
//     facilities = [],
//   } = packageData;

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 overflow-y-auto">
//       <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md mx-4 sm:mx-auto">
//         <div className="flex justify-between items-center mb-4">
//           <h2 className="text-xl font-semibold">{name} Package Details</h2>
//           <button
//             onClick={() => setShowDetails(false)}
//             className="text-gray-500 hover:text-gray-700"
//           >
//             ✕
//           </button>
//         </div>

//         {/* Scrollable content area */}
//         <div className="space-y-4 max-h-80 overflow-y-auto">
//           {/* Package Name */}
//           <div>
//             <h3 className="text-gray-700 font-medium">Package Type</h3>
//             <p className="text-gray-600">{name}</p>
//           </div>

//           {/* Price */}
//           <div>
//             <h3 className="text-gray-700 font-medium">Price</h3>
//             <p className="text-gray-600">${price.toFixed(2)}</p>
//           </div>

//           {/* Offer Price */}
//           {offerPrice > 0 && (
//             <div>
//               <h3 className="text-gray-700 font-medium">Offer Price</h3>
//               <p className="text-gray-600 text-red-500">${offerPrice.toFixed(2)}</p>
//             </div>
//           )}

//           {/* Facilities */}
//           {facilities.length > 0 && (
//             <div>
//               <h3 className="text-gray-700 font-medium">Facilities</h3>
//               <ul className="list-disc pl-5 space-y-1 text-gray-600">
//                 {facilities.map((facility, index) => (
//                   <li key={index} className="flex items-center">
//                     <span>{facility.name}</span>
//                     <span className={`ml-2 text-sm font-semibold ${facility.available ? 'text-green-500' : 'text-red-500'}`}>
//                       {facility.available ? 'Available' : 'Not Available'}
//                     </span>
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           )}
//         </div>

//         {/* Close Button */}
//         <div className="mt-6">
//           <button
//             onClick={() => setShowDetails(false)}
//             className="w-full bg-blue-500 text-white py-2 rounded shadow-md hover:bg-blue-600 transition-all"
//           >
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PackageDetails;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/api";

const initialState = {
  products: [],
  pagination: {},
  offerPagination: {},
  singleProduct: {},
  upcomingProducts: [],
  productsOnOffer: [],
  newArrival: [],
  justForYouProducts: [],
  recommendedProducts: [],
  recommendedProductsForReseller: [],
  filteredProducts: [],
  productsAvailableForOffer: [],
  status: "idle",
  loading: false,
  error: null,
  creating: false,
  createError: null,
  filters: {
    category: "",
    minPrice: 0,
    maxPrice: 100000,
    inStock: false,
    rating: 0,
    sortBy: "title",
    sortType: "asc",
    search: "",
    reseller: false,
  },
  page: 1,
  limit: 12,
  hasMore: true,
};

// Fetch products from API
export const getAllProducts = createAsyncThunk(
  "products/getAllProducts",
  async ({ filters, page }, { getState }) => {
    const { products } = getState();

    // Construct the query string based on filters
    const queryString = new URLSearchParams({
      page,
      limit: products.limit,
      sortBy: filters.sortBy,
      sortType: filters.sortType,
      search: filters.search,
      categoryName: filters.category,
      minPrice: filters.minPrice,
      maxPrice: filters.maxPrice,
      minRating: filters.rating,
      inStock: filters.inStock,
      reseller: filters.reseller,
    }).toString();
    const response = await api.get(`/products?${queryString}`);
    return response.data;
  }
);

// Fetch single product from API
export const getSingleProduct = createAsyncThunk(
  "products/fetchSingleProduct",
  async (productTitle) => {
    const response = await api.get(`/products/title/${productTitle}`);

    return response.data;
  }
);

// fetch recommendation products
export const getRecommendationProducts = createAsyncThunk(
  "products/fetchRecommendationProducts",
  async ({ category, productId }) => {
    const response = await api.get(
      `/products/recommendations?categoryIds=${category}&productId=${productId}`
    );
    return response.data;
  }
);

// fetch recommendation for reseller
export const getRecommendationProductsByReseller = createAsyncThunk(
  "products/fetchRecommendationProductsByReseller",
  async ({ category, productId }) => {
    const response = await api.get(
      `/products/recommendations/reseller?categoryIds=${category}&productId=${productId}`
    );
    return response.data;
  }
);

// creating a new product
export const createProduct = createAsyncThunk(
  "products/createProduct",
  async (product) => {
    const response = await api.post("/products", product, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

// update a product
export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async ({ id, product }) => {
    const response = await api.put(`/products/${id}`, product, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

// get upcoming products
export const getUpcomingProducts = createAsyncThunk(
  "products/getUpcomingProducts",
  async () => {
    const response = await api.get("/products/upcoming");
    return response.data;
  });

// get new arrival products
export const getNewArrivalProducts = createAsyncThunk(
  "products/getNewArrivalProducts",
  async () => {
    const response = await api.get("/new-arrival/products");
    return response.data;
  }
);

// get just for you products
export const getJustForYouProducts = createAsyncThunk(
  "products/getJustForYouProducts",
  async () => {
    const response = await api.get("/just-for-you/products");
    return response.data;
  }
);

// FilteredProducts fetch based on category
export const getFilteredProducts = createAsyncThunk(
  "products/getCategorizedFilteredProducts",
  async ({ filters, page }, { getState }) => {
    const { products } = getState();

    // Construct the query string based on filters
    const queryString = new URLSearchParams({
      page,
      limit: products.limit,
      sortBy: filters.sortBy,
      sortType: filters.sortType,
      search: filters.search,
      categoryName: filters.category,
      minPrice: filters.minPrice,
      maxPrice: filters.maxPrice,
      minRating: filters.rating,
      inStock: filters.inStock,
      reseller: filters.reseller,
    }).toString();
    const response = await api.get(`/products?${queryString}`);
    return response.data;
  }
);

// delete a product by id -- admin
export const deleteProduct = createAsyncThunk(
  "products/deleteProduct",
  async (id) => {
    const response = await api.delete(`/products/${id}`);
    return response.data;
  }
);

// get all products which are in offer
export const getProductsOnOffer = createAsyncThunk(
  "products/getProductsOnOffer",
  async ({page=1,limit=6}) => {

    const response = await api.get(`/products/on-offer?page=${page}&limit=${limit}`);
    return response.data;
  }
);

// fetch products for offer
export const fetchProductsForOffer = createAsyncThunk(
  "products/fetchProductsForOffer",
  async ()=> {
    const response = await api.get("/products/available-for-offer");
    return response.data;
  }
);


// download excel template for products
export const downloadTemplate = createAsyncThunk(
  "products/downloadTemplate",
  async () => {
    const response = await api.get("/products/download-template", {
      responseType: "blob", // Ensure the response is in blob format
    });
    return response.data; // Return the raw Blob data
  }
);

// bulk upload products
export const bulkUploadProducts = createAsyncThunk(
  "products/bulkUploadProducts",
  async (formData) => {
    const response = await api.post("/products/bulk-upload", formData);
    return response.data;
  }
);


const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.products = []; // Clear current items for fresh load
      state.filters = { ...state.filters, ...action.payload };
      state.page = 1;
    },
    incrementPage: (state) => {
      state.page += 1;
    },
    resetProducts: (state) => {
      state.products = []; // Clear products
      state.page = 1; // Reset pagination
      state.hasMore = true; // Reset hasMore to true
    },
    resetFilteredProducts: (state) => {
      state.filteredProducts = []; // Clear products
      state.page = 1; // Reset pagination
      state.hasMore = true; // Reset hasMore to true
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    resetOfferProducts: (state) => {
      state.productsOnOffer = []; // Clear products
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.loading = false;
        const { products, pagination } = action.payload;
        // Append new products
        state.products = [...state.products, ...products];
        state.hasMore = pagination.next > state.page;
        state.pagination = pagination;
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSingleProduct.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSingleProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.singleProduct = action.payload;
      })
      .addCase(getSingleProduct.rejected, (state, action) => {
        state.loading = false;
        state.singleProduct = action.error.message;
      })
      .addCase(createProduct.pending, (state, action) => {
        state.creating = true;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.creating = false;
        state.products.push(action.payload);
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.creating = false;
        state.createError = action.error.message;
      })
      .addCase(getNewArrivalProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNewArrivalProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.newArrival = action.payload;
      })
      .addCase(getNewArrivalProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getRecommendationProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRecommendationProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.recommendedProducts = action.payload;
      })
      .addCase(getRecommendationProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getFilteredProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFilteredProducts.fulfilled, (state, action) => {
        state.loading = false;
        const { products, pagination } = action.payload;
        state.filteredProducts = [...state.filteredProducts, ...products];
        state.hasMore = pagination.next > state.page;
      })
      .addCase(getFilteredProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getRecommendationProductsByReseller.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getRecommendationProductsByReseller.fulfilled,
        (state, action) => {
          state.loading = false;
          state.recommendedProductsForReseller = action.payload;
        }
      )
      .addCase(
        getRecommendationProductsByReseller.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )
      .addCase(getJustForYouProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getJustForYouProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.justForYouProducts = action.payload;
      })
      .addCase(getJustForYouProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUpcomingProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUpcomingProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.upcomingProducts = action.payload;
      })
      .addCase(getUpcomingProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.products = state.products.filter(
          (product) => product._id !== action.payload
        );
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchProductsForOffer.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductsForOffer.fulfilled, (state, action) => {
        state.loading = false;
        state.productsAvailableForOffer = action.payload;
      })
      .addCase(fetchProductsForOffer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProductsOnOffer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductsOnOffer.fulfilled, (state, action) => {
        state.loading = false;
       state.offerPagination = action.payload.pagination;
        state.productsOnOffer = [...state.productsOnOffer, ...action.payload.products];
      })
      .addCase(getProductsOnOffer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(bulkUploadProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(bulkUploadProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = [...state.products, ...action.payload];
      })
      .addCase(bulkUploadProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export const {
  setFilter,
  incrementPage,
  resetProducts,
  resetFilteredProducts,
  setPage,
  resetOfferProducts
} = productsSlice.actions;

export default productsSlice.reducer;

import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createPaymentOption } from '../../features/paymentOptions/paymentOptionSlice';
import { useDispatch } from 'react-redux';

const HeadlineForm = () => {
  const dispatch = useDispatch();
  // Formik validation schema for the CreateCategoryForm
  const formik = useFormik({
    initialValues: {
      provider: "",
      accountNumber: "",
    },
    validationSchema: Yup.object({
      provider: Yup.string().required('Provider name is required'),
      accountNumber: Yup.string().min(11, 'Account number must be 11 digits').required('Account number is required'),
    }),
    onSubmit: (values, { resetForm }) => {
      // Perform the API request to create the category 
     
      dispatch(createPaymentOption({
        paymentOptionData: values,
        token: JSON.parse(localStorage.getItem("access_token"))
    }));
      resetForm();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className='w-full'>
      <div className="mb-4">
        <label className="block text-gray-700">Provider</label>
        <input
          type="text"
          name="provider"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.provider}
          className="mt-1 p-2 block w-full border rounded-md"
        />
        {formik.touched.body && formik.errors.provider ? (
          <div className="text-red-500 text-sm">{formik.errors.provider}</div>
        ) : null}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Account Number</label>
        <input
          type="text"
          name="accountNumber"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.accountNumber}
          className="mt-1 p-2 block w-full border rounded-md"
        />
        {formik.touched.body && formik.errors.accountNumber ? (
          <div className="text-red-500 text-sm">{formik.errors.accountNumber}</div>
        ) : null}
      </div>

      <button type="submit" className="w-full text-center bg-blue-600 text-white p-2 rounded hover:bg-blue-700">
        Create payment Options
      </button>
    </form>
  );
};

export default HeadlineForm;
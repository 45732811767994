
// src/api/axiosConfig.js
import axios from "axios";
// Import a notification library if desired (like react-toastify)
import { toast } from "react-toastify";
import { logout } from "../auth/authSlice";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_PRODUCTION_URL,
});

// Function to set up interceptors

export const setupInterceptors = (store, navigate) => {
  
  
  // Request Interceptor
  api.interceptors.request.use(
    (config) => {
      // Retrieve the access token from localStorage
      let token = null;
    
      token = store.getState().auth.access_token;
      
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response Interceptor
  api.interceptors.response.use(
    (response) => response,
     (error) => {
              
      if (error.response) {
        // Handle 401 Unauthorized
        if (error.response?.status === 401) {
          toast.error("Session expired. Please log in again.");
          // redirect to login 
          // determine the user role from the state
          
          const {userInfo} = store.getState().auth;
          if (userInfo && userInfo.role === "admin") {
            navigate("/admin/login");
          } else if (userInfo && userInfo.role === "reseller") {
            navigate("/reseller/login");
          } else if (userInfo && userInfo.role === "user") {
            navigate("/login");
          }
           store.dispatch(logout());
        }
        // Handle client errors (400-499)
        else if (error.response.status >= 400 && error.response.status < 500) {
          
          const message =
            error.response.data?.message ||
            "An error occurred. Please check your input.";
          toast.error(message); // Use notification instead of alert for UX
        }
        // Handle server errors (500-599)
        else if (error.response.status >= 500) {
          toast.error("Server error. Please try again later.");
        }
      } else if (error.request) {
      
        // Handle network issues
        toast.error("Network error. Please check your internet connection.");
      } else {
        // Unexpected errors
        toast.error(`Unexpected error: ${error.message}`);
      }
      return Promise.reject(error);
    }
  );
};

export default api;

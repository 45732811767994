import React from 'react';

const VideosList = ({ videos }) => {
  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Our Video Tutorials</h2>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6"> 
        {videos && videos.map((video) => (
          <div key={video._id} className="bg-white rounded-lg shadow-md p-4 hover:shadow-lg transition-shadow duration-300">
            <div className="aspect-w-16 aspect-h-9 mb-4">
              <iframe
                className="w-full h-full rounded-lg"
                src={video.url.replace('watch?v=', 'embed/')}
                title={video.title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <h3 className="text-lg font-bold text-gray-700 mb-2">{video.title}</h3>
            <p className="text-gray-600 text-sm">{video.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideosList;

import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../../components/LoaderComponent";
import { createSubscriptionOrder } from "../../features/subscriptionOrder/subscriptionOrderSlice";

// Validation Schema
const CheckoutSchema = Yup.object().shape({
  name: Yup.string().required("Full Name is required"),
  phone: Yup.string().required("Phone is required"),
  address: Yup.string().required("Address is required"),
  paymentMethod: Yup.string().required("Please choose a payment option"),
});

const PackageOrderCheckout = () => {

    const { singleSubscriptionOrder, loading} = useSelector((state) => state.subscriptionOrders);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { chosenPackage } = location.state || {};

  useEffect(() => {
    if (!chosenPackage) {
      navigate("/reseller/packages");
    }
  }, [chosenPackage, navigate]);

  //@TODo after getting order response. navigate or redirect to profile or online payment gateway.
  useEffect(() => {
    if (singleSubscriptionOrder && singleSubscriptionOrder.paymentMethod === "manual-pay") {
      navigate(`/reseller/package/${chosenPackage.name}/order/manual-payment`, { state: { singleSubscriptionOrder } });
    }
  },[singleSubscriptionOrder,navigate,chosenPackage]);

  const initialValues = {
    name: "",
    phone: "",
    address: "",
    paymentMethod: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    const orderDetails = {
        name: values.name,
        phone: values.phone,
        address: values.address,
        subscriptionPackageId: chosenPackage._id,
        amount: Number(chosenPackage.price),
        paymentMethod: values.paymentMethod
    };
    dispatch(createSubscriptionOrder(
        {subscriptionOrder:orderDetails,token:JSON.parse(localStorage.getItem("token"))},
      )
    );

    resetForm();
  };

  if(loading) return <LoaderComponent />

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6">Checkout</h2>

      {/* Product Details Section */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-4">
        <h3 className="text-xl text-white text-center bg-green-700 font-semibold mb-4 p-2">
          package Summary
        </h3>

        <div className="flex justify-between border-b pb-2 mb-2">
          <span>{chosenPackage.name}</span>
          <span>amount: {chosenPackage.price}</span>
        </div>

        <p className="text-lg font-semibold">Total: ${chosenPackage.price}</p>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={CheckoutSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {/* Billing Address */}
            <div className="bg-white p-6 rounded-lg shadow-md mb-4">
              <h3 className="text-xl text-white text-center bg-green-700 font-semibold mb-4 p-2">
                Filling Your Address
              </h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block mb-1 font-medium">Full Name</label>
                  <Field
                    name="name"
                    type="text"
                    className="w-full p-2 border rounded"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div>
                  <label className="block mb-1 font-medium">Phone</label>
                  <Field
                    name="phone"
                    type="text"
                    className="w-full p-2 border rounded"
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="col-span-2">
                  <label className="block mb-1 font-medium">Address</label>
                  <Field
                    name="address"
                    type="text"
                    className="w-full p-2 border rounded"
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
              </div>
            </div>
            {/* Payment Method */}
            <div className="bg-white p-6 rounded-lg shadow-md mb-4">
              <h3 className="text-xl font-semibold mb-4">Payment Method</h3>
              <div className="flex items-center">
                <label className="flex items-center mr-4">
                  <Field
                    type="radio"
                    name="paymentMethod"
                    value="manual-pay"
                    className="mr-2"
                  />
                  manual-payment
                </label>
                <label className="flex items-center">
                  <Field
                    disabled
                    type="radio"
                    name="paymentMethod"
                    value="online-pay"
                    className="mr-2"
                  />
                  Online Payment
                </label>
              </div>
              <ErrorMessage
                name="paymentMethod"
                component="div"
                className="text-red-500 text-sm mt-2"
              />
            </div>

            <button
              type="submit"
              className="w-full p-3 bg-blue-600 text-white rounded mt-4"
            >
              place order & pay
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PackageOrderCheckout;

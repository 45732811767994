import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProducts,
  incrementPage,
  setFilter,
  resetProducts,
} from "../features/products/productsSlice";
import { fetchCategories } from "../features/category/categorySlice";
import LoaderComponent from "../components/LoaderComponent";
import { useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import ProductCard from "../components/ProductCard";

const ProductList = () => {
  const { products, loading, error, filters, page, hasMore } = useSelector(
    (state) => state.products
  );
  const { categories } = useSelector((state) => state.category);
  const dispatch = useDispatch();
  const location = useLocation();

  const { ref, inView } = useInView({
    threshold: 0.5,
    rootMargin: "100px",
  });

  const [filtersUpdated, setFiltersUpdated] = useState(false);

  const [minPrice, setMinPrice] = useState(filters.minPrice || 0);
  const [maxPrice, setMaxPrice] = useState(filters.maxPrice || 100000);

  const handleFilterChange = (newFilter) => {
    dispatch(setFilter({ ...filters, ...newFilter }));
  };

  const handlePriceUpdate = () => {
    const updatedFilters = {
      ...filters,
      minPrice: Number(minPrice),
      maxPrice: Number(maxPrice),
    };
    dispatch(setFilter(updatedFilters));
  };

  const queryParams = new URLSearchParams(location.search);
  const refCode = queryParams.get("ref");
  const search = queryParams.get("search");

  if (refCode) {
    localStorage.setItem("ref_code", refCode);
  }

  useEffect(() => {
    if (search) {
      dispatch(resetProducts());
      dispatch(
        setFilter({
          category: "",
          minPrice: 0,
          maxPrice: 100000,
          inStock: false,
          rating: 0,
          search,
        })
      );
      setFiltersUpdated(true);
    } else {
      dispatch(
        setFilter({
          category: "",
          minPrice: 0,
          maxPrice: 100000,
          inStock: false,
          rating: 0,
          search: "",
        })
      );
      setFiltersUpdated(true);
    }
  }, [search, dispatch]);

  useEffect(() => {
    if (inView && hasMore && !loading) {
      dispatch(incrementPage());
    }
  }, [inView, hasMore, loading, dispatch]);

  useEffect(() => {
    if (filtersUpdated) {
      dispatch(getAllProducts({ filters, page }));
      dispatch(fetchCategories());
    }
  }, [filters, page, dispatch, filtersUpdated]);

  useEffect(() => {
    return () => {
      dispatch(
        setFilter({
          category: "",
          minPrice: 0,
          maxPrice: 100000,
          inStock: false,
          rating: 0,
          search: "",
        })
      );
      dispatch(resetProducts());
    };
  }, [dispatch]);

  const resetFilters = () => {
    dispatch(
      setFilter({
        category: "",
        minPrice: 0,
        maxPrice: 100000,
        inStock: false,
        rating: 0,
        search: "",
      })
    );
  };

  if (loading) return <LoaderComponent message="Loading products..." />;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="container mx-auto p-4">
      {/* Filter Sidebar */}
      <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
        {/* Sidebar */}
        <div className="md:col-span-1 bg-white p-6 rounded-lg shadow-lg "> {/* sticky top-0 */}
          <h3 className="text-xl font-semibold mb-4 flex justify-between items-center">
            Filters
            <button
              onClick={resetFilters}
              className="text-sm text-blue-600 hover:text-blue-800"
            >
              Reset Filters
            </button>
          </h3>

          {/* Category Filter */}
          <div className="mb-6">
            <h4 className="font-medium text-lg mb-2">Category</h4>
            <select
              onChange={(e) => handleFilterChange({ category: e.target.value })}
              value={filters.category}
              className="w-full border px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">All Categories</option>
              {categories.map((category, index) => (
                <option key={index} value={category.name}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          {/* Price Filter */}
          <div className="mb-6">
            <h4 className="font-medium text-lg mb-2">Price Range</h4>
            <div className="flex justify-between">
              <input
                type="number"
                onChange={(e) => setMinPrice(e.target.value)}
                value={minPrice}
                placeholder="Min Price"
                className="w-1/2 border px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="number"
                onChange={(e) => setMaxPrice(e.target.value)}
                value={maxPrice}
                placeholder="Max Price"
                className="w-1/2 border px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <button
              onClick={handlePriceUpdate}
              className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Apply Price Filter
            </button>
          </div>

          {/* In Stock Filter */}
          <div className="mb-6">
            <h4 className="font-medium text-lg mb-2">Availability</h4>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                onChange={(e) =>
                  handleFilterChange({ inStock: e.target.checked })
                }
                checked={filters.inStock}
                className="h-4 w-4"
              />
              <span>In Stock</span>
            </label>
          </div>

          {/* Rating Filter */}
          <div className="mb-6">
            <h4 className="font-medium text-lg mb-2">Rating</h4>
            <select
              onChange={(e) => handleFilterChange({ rating: e.target.value })}
              value={filters.rating}
              className="w-full border px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value={0}>All Ratings</option>
              <option value={1}>1 Star & above</option>
              <option value={2}>2 Stars & above</option>
              <option value={3}>3 Stars & above</option>
              <option value={4}>4 Stars & above</option>
              <option value={5}>5 Stars</option>
            </select>
          </div>
        </div>
        <div className="md:col-span-4">
          <h2 className="text-2xl font-bold mb-6">
            {search ? `Search results for: ${search}` : "All Products"}
          </h2>

          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {products &&
              products.map((product, index) => (
                <ProductCard key={index} product={product} />
              ))}
          {/* Infinite scroll observer */}
          {!loading && hasMore && <div ref={ref} className="h-4">
             <LoaderComponent />
            </div>}
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default ProductList;

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, role, redirectPath }) => {
  const {userInfo: user, access_token: token} = useSelector((state) => state.auth);
  // const isRehydrated = useSelector((state) => state._persist.rehydrated); // Check rehydration status
  // console.log("from protected route:",user,token)

  // // Wait until rehydration is complete
  // if (!isRehydrated) return null;

  if (!token || !user) {
    return <Navigate to={`${redirectPath || '/login'}`} />; // Redirect to login if no token/user
  }

  if (role && user.role !== role) {
    return <Navigate to="/" />; // Redirect to homepage if user role doesn’t match
  }

  return children; // Render the protected component if everything is valid
};

export default ProtectedRoute;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCategories } from "../features/category/categorySlice";
import LoaderComponent from "./LoaderComponent";

const CategoryMenu = ({type="shop"}) => {
  const { categories, loading } = useSelector((state) => state.category);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  if (loading) return <LoaderComponent message="Loading categories..." />;

  return (
    <div className="bg-white py-8 px-4 md:px-8 lg:px-16 mt-4">
      <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">Explore Our Categories</h2>
      <nav className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-6">
        {categories &&
          categories.map((category, index) => (
            <Link
              key={index}
              to= { type === "shop" ? `/shop/products/categories?category=${category.name}` : `/reseller/products/categories?category=${category.name}`}
              className="group flex flex-col items-center text-center"
            >
              {/* Circular Image */}
              <div className="w-20 h-20 rounded-full overflow-hidden bg-gray-200 mb-2 shadow-lg">
                <img
                  src={category.image}
                  alt={category.name}
                  className="w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                />
              </div>
              {/* Category Title */}
              <span className="text-sm font-semibold text-gray-900">
                {category.name}
              </span>
            </Link>
          ))}
      </nav>
    </div>
  );
};

export default CategoryMenu;

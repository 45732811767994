import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createSliderImage } from '../../features/sliderImage/sliderImageSlice';
import { fetchCategories } from '../../features/category/categorySlice';
import LoaderComponent from '../LoaderComponent';

const availableLinks = [
  { value: '/', label: 'Home' },
  { value: '/shop', label: '/shop' },
  { value: '/reseller', label: '/reseller' },
  { value: '/shop/products', label: '/shop/products' },
  { value: '/reseller/products', label: '/reseller/products' },
  { value: '/reseller/signup', label: '/reseller/signup' },
  { value: '/signup', label: '/signup' },  
]

const SliderImageForm = () => {

  const { categories, loading } = useSelector((state) => state.category);
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState(null);
  const [alLinks, setAlLinks] = useState(availableLinks);

  // fetch categories on mount
  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

 

  // generate all possible links when categories available
  useEffect(() =>{
    if(categories.length > 0){
      const links = categories.map((category) => ({
        value: `/shop/products/categories?category=${encodeURIComponent(category.name)}`,
        label: `/shop/products/categories?category=${encodeURIComponent(category.name)}`,
      }));
      setAlLinks((prevLinks) => [...prevLinks, ...links]);

      const resellerLinks = categories.map((category) => ({
        value: `/reseller/products/categories?category=${encodeURIComponent(category.name)}`,
        label: `/reseller/products/categories?category=${encodeURIComponent(category.name)}`,
      }));
      setAlLinks((prevLinks) => [...prevLinks, ...resellerLinks]);
    }
  },[categories])

  // Formik form submission handler
  const formik = useFormik({
    initialValues: {
      image: '',
      url: '/',
      for: '',
    },
    validationSchema: Yup.object({
      image: Yup.string().required('Image is required'),
      url: Yup.string().optional(),
      for: Yup.string()
        .oneOf(['home', 'e-commerce', 'reseller'], 'Invalid section')
        .required('Section is required'),
    }),
    onSubmit: (values,{resetForm}) => {
      const token = JSON.parse(localStorage.getItem("access_token"));

      dispatch(createSliderImage({ sliderImageData: values, token }));
      
      resetForm();
    },
  });



  // Handle image upload and convert to base64
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        formik.setFieldValue('image', reader.result); // Set base64 encoded image string
        setImagePreview(reader.result); // Show preview
      };
      reader.readAsDataURL(file);
    }
  };

  if(loading) return <LoaderComponent />

  return (
    <form onSubmit={formik.handleSubmit} className="p-4 bg-white rounded shadow-md">
      <div className="mb-4">
        <label className="block font-semibold mb-2">Image</label>
        <input 
          type="file" 
          accept="image/*" 
          onChange={handleImageChange} 
          className="w-full border border-gray-300 p-2 rounded"
          required
        />
        {formik.errors.image && formik.touched.image ? (
          <div className="text-red-500 text-sm mt-1">{formik.errors.image}</div>
        ) : null}
        {imagePreview && (
          <div className="mt-4">
            <img 
              src={imagePreview} 
              alt="Preview" 
              className="w-32 h-32 object-cover rounded" 
            />
          </div>
        )}
      </div>

      <div className="mb-4">
        <label className="block font-semibold mb-2">URL</label>
        <select 
          name="url"
          value={formik.values.url}
          onChange={formik.handleChange}
          className="w-full border border-gray-300 p-2 rounded"
          >
            <option value="">Select URL</option>
            {alLinks.map((link) => (
              <option key={link.id} value={link.value}>{link.label}</option>
            ))}
          </select>
        {formik.errors.url && formik.touched.url ? (
          <div className="text-red-500 text-sm mt-1">{formik.errors.url}</div>
        ) : null}
      </div>

      <div className="mb-4">
        <label className="block font-semibold mb-2">Section</label>
        <select 
          name="for"
          value={formik.values.for} 
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="w-full border border-gray-300 p-2 rounded"
        >
          <option value="" disabled>Select Section</option>
          <option value="home">Home</option>
          <option value="e-commerce">E-commerce</option>
          <option value="reseller">Reseller</option>
        </select>
        {formik.errors.for && formik.touched.for ? (
          <div className="text-red-500 text-sm mt-1">{formik.errors.for}</div>
        ) : null}
      </div>

      <button 
        type="submit" 
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
      >
        Submit
      </button>
    </form>
  );
};

export default SliderImageForm;

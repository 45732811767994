import React from "react";

const AffiliatePage = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-10 px-4 sm:px-10">
      <div className="max-w-screen-xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        {/* Header Section */}
        <div className="text-center mb-10">
          <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-4">
            Join Our Affiliate Program
          </h1>
          <p className="text-lg text-gray-600">
            Earn commission by sharing our products with your audience!
          </p>
        </div>

        {/* How it Works Section */}
        <div className="text-center mb-16">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            How It Works
          </h2>
          <p className="text-lg text-gray-600 mb-8">
            Becoming an affiliate is easy! Simply follow the steps below to
            start earning money by promoting our products.
          </p>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-10">
            <div className="p-6 bg-blue-50 rounded-lg shadow-md transition-transform transform hover:scale-105">
              <h3 className="text-xl font-semibold text-blue-600 mb-2">
                1. Sign Up
              </h3>
              <p className="text-gray-600">
                Register for our affiliate program and get instant access to
                your unique affiliate link.
              </p>
            </div>
            <div className="p-6 bg-blue-50 rounded-lg shadow-md transition-transform transform hover:scale-105">
              <h3 className="text-xl font-semibold text-blue-600 mb-2">
                2. Share Your Link
              </h3>
              <p className="text-gray-600">
                Share your affiliate link on social media, blogs, or websites
                and start reaching customers.
              </p>
            </div>
            <div className="p-6 bg-blue-50 rounded-lg shadow-md transition-transform transform hover:scale-105">
              <h3 className="text-xl font-semibold text-blue-600 mb-2">
                3. Earn Commissions
              </h3>
              <p className="text-gray-600">
                Every sale made through your link earns you a commission. It's
                that simple!
              </p>
            </div>
          </div>
        </div>

        {/* Commission Rates Section */}
        <div className="mb-16">
          <h2 className="text-2xl font-semibold text-gray-800 text-center mb-4">
            Our Commission Rates
          </h2>
          <p className="text-lg text-gray-600 text-center mb-8">
            We offer competitive commission rates to make sure you earn well for
            your efforts.
          </p>
          <div className="flex justify-center space-x-8">
            <div className="bg-blue-50 p-6 rounded-lg shadow-lg w-64 text-center transition-all hover:bg-blue-100">
              <h3 className="text-xl font-semibold text-blue-600 mb-2">
                Standard Commission
              </h3>
              <p className="text-2xl font-bold text-gray-800 mb-4">10%</p>
              <p className="text-gray-600">
                Earn 10% for every sale made through your affiliate link.
              </p>
            </div>
            <div className="bg-blue-50 p-6 rounded-lg shadow-lg w-64 text-center transition-all hover:bg-blue-100">
              <h3 className="text-xl font-semibold text-blue-600 mb-2">
                Exclusive Partners
              </h3>
              <p className="text-2xl font-bold text-gray-800 mb-4">15%</p>
              <p className="text-gray-600">
                Exclusive partners can earn 15% commission with additional
                benefits.
              </p>
            </div>
          </div>
        </div>

        {/* How to Become an Affiliate Section */}
        <div className="bg-blue-100 py-12 px-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6">
            How to Become an Affiliate
          </h2>
          <p className="text-lg text-center text-gray-600 mb-6">
            Ready to start earning? Follow these easy steps to sign up as an
            affiliate and begin promoting our products.
          </p>
          <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-center mt-6">
            <div className="p-6 bg-white rounded-lg shadow-md w-full max-w-sm text-center transition-transform hover:scale-105">
              <h3 className="text-xl font-semibold text-blue-600 mb-4">
                Step 1: Sign Up
              </h3>
              <p className="text-gray-600">
                Create your account and gain access to your personalized
                affiliate dashboard.
              </p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-md w-full max-w-sm text-center transition-transform hover:scale-105">
              <h3 className="text-xl font-semibold text-blue-600 mb-4">
                Step 2: Get Your Link
              </h3>
              <p className="text-gray-600">
                Once approved, you'll receive a unique affiliate link to share.
              </p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-md w-full max-w-sm text-center transition-transform hover:scale-105">
              <h3 className="text-xl font-semibold text-blue-600 mb-4">
                Step 3: Start Earning
              </h3>
              <p className="text-gray-600">
                Share your link and start earning commissions on every sale made
                through it.
              </p>
            </div>
          </div>
        </div>

        {/* Call to Action Section */}
        <div className="text-center mt-12">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Start Earning Today!
          </h2>
          <p className="text-lg text-gray-600 mb-8">
            Ready to get started? Join our affiliate program and start earning
            commissions with every sale you drive.
          </p>
          <a
            href="/signup"
            className="bg-gradient-to-r from-pink-500 to-orange-500 text-white py-3 px-6 rounded-full text-lg font-semibold hover:bg-gradient-to-l transition-all duration-300"
          >
            Join Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default AffiliatePage;

import React, { useState } from "react";
import { uploadImages } from "../../features/uploadImages/uploadImageSlice";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../LoaderComponent";

const ImageUploader = () => {
  const { uploadedImages, loading } = useSelector(
    (state) => state.images
  );
  const [images, setImages] = useState([]);

  const dispatch = useDispatch();

  // Convert files to Base64 and update state
  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files); // Get all selected files
    const imagePromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result); // Base64 result
        reader.onerror = (error) => reject(error);
      });
    });

    // Wait for all conversions to complete
    Promise.all(imagePromises)
      .then((base64Images) => setImages([...images, ...base64Images]))
      .catch((error) => console.error("Error uploading images:", error));
  };

  // Remove an image from the preview
  const removeImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  // Handle submit
  const handleSubmit = () => {
    dispatch(uploadImages({ imageData:{ images}, token: localStorage.getItem("token") }));
    setImages([]); // Clear images after submission
  };

  // Handle copy to clipboard
  const handleCopy = (url) => {
    navigator.clipboard.writeText(url);
    alert("Image URL copied to clipboard!");
  };

   // Handle "Copy All" functionality
   const handleCopyAll = () => {
    const allUrls = uploadedImages.join("\n");
    navigator.clipboard.writeText(allUrls);
    alert("All image URLs copied to clipboard!");
  };

  if (loading) return <LoaderComponent />;
  

  return (
    <div className="max-w-xl mx-auto p-4">
      {/* File Upload Section */}
      <div className="mb-4">
        <label
          htmlFor="image-upload"
          className="flex flex-col items-center justify-center border-2 border-dashed border-gray-400 rounded-lg p-6 text-gray-500 hover:border-blue-500 hover:text-blue-500 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8 mb-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 16l5.5-5.5a1 1 0 011.414 0L14 14m5 5l-5.5-5.5a1 1 0 00-1.414 0L10 16M21 12v9a2 2 0 01-2 2H5a2 2 0 01-2-2v-9m16 4a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
          <span>Click to upload images (multiple)</span>
        </label>
        <input
          id="image-upload"
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageUpload}
          className="hidden"
        />
      </div>

      {/* Selected Images Preview */}
      {images.length > 0 && (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
          {images.map((image, index) => (
            <div
              key={index}
              className="relative border border-gray-300 rounded-lg overflow-hidden"
            >
              <img
                src={image}
                alt={`Preview ${index}`}
                className="w-full h-32 object-cover"
              />
              <button
                onClick={() => removeImage(index)}
                className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 shadow-lg hover:bg-red-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          ))}
        </div>
      )}

      {/* Submit Button */}
      {images.length > 0 && (
        <button
          onClick={handleSubmit}
          className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600"
        >
          Upload Images
        </button>
      )}

      {/* Uploaded Images Section */}
      {uploadedImages?.length > 0 && (
        <div className="mt-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Uploaded Images:</h2>
            <button
              onClick={handleCopyAll}
              className="bg-green-500 text-white py-1 px-4 rounded-lg hover:bg-green-600"
            >
              Copy All
            </button>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {uploadedImages.map((url, index) => (
              <div
                key={index}
                className="border border-gray-300 rounded-lg overflow-hidden"
              >
                <img
                  src={url}
                  alt={`Uploaded ${index}`}
                  className="w-full h-32 object-cover"
                />
                <div className="p-2">
                  <button
                    onClick={() => handleCopy(url)}
                    className="w-full bg-green-500 text-white py-1 rounded-lg hover:bg-green-600"
                  >
                    Copy URL
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageUploader;

import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { addServiceCharge } from '../../features/serviceCharge/serviceChargeSlice';

const ServiceChargeForm = ({ setShowForm }) => {
  const dispatch = useDispatch();

  const initialValues = {
    serviceCharge: '',
  };

  const validationSchema = Yup.object({
    serviceCharge: Yup.number()
      .required('Service charge is required')
      .positive('Must be a positive number'),
  });

  const onSubmit = (values, { resetForm }) => {
    dispatch(addServiceCharge({ ...values, token: JSON.parse(localStorage.getItem("access_token")) }));
    resetForm();
    setShowForm(false);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded shadow-lg w-2/3">
        <h2 className="text-2xl font-bold mb-4">Add Service Charge</h2>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          <Form>
            <div className="mb-4">
              <label htmlFor="serviceCharge" className="block text-gray-700">Service Charge</label>
              <Field
                type="number"
                id="serviceCharge"
                name="serviceCharge"
                className="w-full p-2 border border-gray-300 rounded mt-1"
              />
              <ErrorMessage name="serviceCharge" component="div" className="text-red-500 text-sm mt-1" />
            </div>

            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => setShowForm(false)}
                className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 mr-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
              >
                Submit
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ServiceChargeForm;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getWithdrawalMethods,
  getWithdrawalMethodsByReseller,
} from "../../features/withdrawalMethod/withdrawalSlice";
import {
  getWithdrawalRequests,
  createWithdrawalRequest,
  getWithdrawalRequestsByReseller,
  createWithdrawalRequestByReseller,
} from "../../features/withdrawalRequest/withdrawalRequestSlice";
import {
  fetchResellerProfile,
} from "../../features/user/userSlice";
import LoaderComponent from "../LoaderComponent";
import { getAffiliateByUserId } from "../../features/affiliate/affiliateSlice";

const Withdraw = ({ from = "affiliate" }) => {
  const { userDetails, loading: loadingUser } = useSelector((state) => state.user);
  const { affiliate,loading: loadingAffiliate } = useSelector((state) => state.affiliate);
  const { withdrawalMethods } = useSelector((state) => state.withdrawal);
  const { withdrawalRequests, loading } = useSelector(
    (state) => state.withdrawalRequest
  );
  const [paymentMethods] = useState(["mobileBanking", "bankTransfer"]);
  const [withdrawable, setWithdrawable] = useState(0);
  

  const [selectedMethod, setSelectedMethod] = useState("");
  const [amount, setAmount] = useState(0);
  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState("withdraw");
  const [filterStatus, setFilterStatus] = useState("all");
  const [selectedProvider, setSelectedProvider] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (from === "reseller") {
      dispatch(
        getWithdrawalMethodsByReseller({
          token: JSON.parse(localStorage.getItem("access_token")),
        })
      );
      dispatch(
        getWithdrawalRequestsByReseller({
          token: JSON.parse(localStorage.getItem("access_token")),
        })
      );
      dispatch(
        fetchResellerProfile(JSON.parse(localStorage.getItem("access_token")))
      );
    } else if (from === "affiliate") {
      dispatch(getWithdrawalMethods());
      dispatch(getWithdrawalRequests());
      dispatch(getAffiliateByUserId({userId: userDetails._id, token: JSON.parse(localStorage.getItem('access_token'))}));
    }
  }, [dispatch, from, userDetails._id]);

  useEffect(() => {
    if (userDetails && userDetails.total_earnings) {
      setWithdrawable(userDetails.total_earnings);
    }
   
  }, [userDetails]);

  useEffect(() => {
    if (affiliate && affiliate.total_commission) {
      setWithdrawable(affiliate.total_commission);
    }
  }, [affiliate]);


  // Filter methods by selected payment type
  const filteredMethods = withdrawalMethods.filter(
    (method) => method.paymentMethod === selectedMethod
  );

  const handleWithdraw = () => {
    const withdrawAmount = parseFloat(amount);
    if (withdrawAmount < 500) {
      setError("Minimum withdrawal amount is 500.");
    } else if (withdrawAmount > withdrawable) {
      setError(
        `Cannot withdraw more than available balance: TK. ${withdrawable}.`
      );
    } else {
      setError("");
     
      if (from === "reseller") {
        dispatch(
          createWithdrawalRequestByReseller({
            withdrawalRequestData: {
              amount: withdrawAmount,
              withdrawalMethodId: selectedProvider,
            },
            token: JSON.parse(localStorage.getItem("access_token")),
          })
        );
        setWithdrawable((prevBalance) => prevBalance - withdrawAmount);
        setAmount("");
        setSelectedMethod("");
        setSelectedProvider();
        setFilterStatus("all");
      } else if (from === "affiliate") {
        dispatch(
          createWithdrawalRequest({
            withdrawalRequestData: {
              amount: Number(withdrawAmount),
              withdrawalMethodId: selectedProvider,
            },
            token: JSON.parse(localStorage.getItem("access_token")),
          })
        );
        setWithdrawable((prevBalance) => prevBalance - withdrawAmount);
        setAmount("");
        setSelectedMethod("");
        setSelectedProvider();
        setFilterStatus("all");
      }
    }
  };

  const filteredHistory = withdrawalRequests.filter((entry) =>
    filterStatus === "all" ? true : entry.status === filterStatus
  );

  
  if(loadingUser || loadingAffiliate) return <LoaderComponent />
  if (loading) return <LoaderComponent />;

  return (
    <div className="max-w-lg mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4">Withdraw Funds</h2>

      {/* Tab Navigation */}
      <div className="flex mb-6">
        <button
          onClick={() => setActiveTab("withdraw")}
          className={`p-2 font-semibold w-1/2 ${
            activeTab === "withdraw" ? "bg-green-500 text-white" : "bg-gray-300"
          } rounded-l-lg`}
        >
          Withdraw
        </button>
        <button
          onClick={() => setActiveTab("history")}
          className={`p-2 font-semibold w-1/2 ${
            activeTab === "history" ? "bg-green-500 text-white" : "bg-gray-300"
          } rounded-r-lg`}
        >
          Withdraw History
        </button>
      </div>

      {/* Withdraw Form */}
      {activeTab === "withdraw" && 
      <div>
        {
          withdrawable >= 500 ? (
            <div>
          <div className="bg-blue-100 text-blue-700 p-4 rounded-lg mb-4">
            <p className="font-medium">
              Available to Withdraw: TK. {withdrawable}
            </p>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">
              Enter Amount
            </label>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter amount"
              className="w-full p-2 border rounded-lg"
              min="500"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">
              Select Withdrawal Method
            </label>
            <select
              value={selectedMethod}
              onChange={(e) => setSelectedMethod(e.target.value)}
              className="w-full p-2 border rounded-lg"
            >
              <option value="" disabled>
                Select a method
              </option>
              {paymentMethods.includes("mobileBanking") && (
                <option value="mobileBanking">Mobile Banking</option>
              )}
              {paymentMethods.includes("bankTransfer") && (
                <option value="bankTransfer">Bank Transfer</option>
              )}
            </select>
          </div>

          {selectedMethod && (
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Select Provider
              </label>
              <select
                value={selectedProvider}
                onChange={(e) => setSelectedProvider(e.target.value)}
                className="w-full p-2 border rounded-lg"
              >
                <option value="" >
                  Select a provider
                </option>
                {filteredMethods.map((method) => (
                  <option key={method._id} value={method._id}>
                    {method[selectedMethod].provider} -{" "}
                    {method[selectedMethod].accountNumber}
                  </option>
                ))}
              </select>
            </div>
          )}

          {error && (
            <div className="mb-4 p-2 text-red-600 bg-red-100 border border-red-500 rounded-lg">
              {error}
            </div>
          )}

          <button
            onClick={handleWithdraw}
            className="bg-green-500 text-white p-2 rounded-lg font-semibold hover:bg-green-600 w-full mt-4"
          >
            Confirm Withdraw
          </button>
        </div>
          ): (
            <div className="text-center text-gray-600 font-medium mb-4">
              Minimum Withdrawal Amount is TK. 500. you current balance is <span className="text-red-600">TK. {withdrawable} </span> . so you can not withdraw.
            </div>
          )
        }
      </div>
        
       
      }

      {/* Withdraw History */}
      {activeTab === "history" && (
        <div className="mt-4">
          <div className="flex justify-between mb-4">
            <button
              onClick={() => setFilterStatus("all")}
              className={`p-2 font-semibold ${
                filterStatus === "all"
                  ? "bg-green-500 text-white"
                  : "bg-gray-300"
              } rounded-lg`}
            >
              All
            </button>
            <button
              onClick={() => setFilterStatus("paid")}
              className={`p-2 font-semibold ${
                filterStatus === "paid"
                  ? "bg-green-500 text-white"
                  : "bg-gray-300"
              } rounded-lg`}
            >
              Paid
            </button>
            <button
              onClick={() => setFilterStatus("pending")}
              className={`p-2 font-semibold ${
                filterStatus === "pending"
                  ? "bg-green-500 text-white"
                  : "bg-gray-300"
              } rounded-lg`}
            >
              Pending
            </button>
          </div>

          <div className="max-h-60 overflow-y-auto">
            {filteredHistory.length > 0 ? (
              <ul className="space-y-4">
                {filteredHistory.map((withdraw) => (
                  <li
                    key={withdraw._id}
                    className="p-4 border rounded-lg flex justify-between items-center"
                  >
                    <div>
                      <p className="font-semibold">Order ID: {withdraw._id}</p>
                      <p>Amount: TK. {withdraw.amount}</p>
                      <p>
                        Method:
                        {withdraw.withdrawalMethod
                          ? `${
                              withdraw.withdrawalMethod.mobileBanking
                                ?.provider ||
                              withdraw.withdrawalMethod.bankTransfer
                                ?.provider ||
                              "N/A"
                            } - ${
                              withdraw.withdrawalMethod.mobileBanking
                                ?.accountNumber ||
                              withdraw.withdrawalMethod.bankTransfer
                                ?.accountNumber ||
                              "N/A"
                            }`
                          : "N/A"}
                      </p>
                    </div>
                    <span
                      className={`py-1 px-3 rounded-lg text-white text-sm ${
                        withdraw.status === "paid"
                          ? "bg-green-500"
                          : "bg-yellow-500"
                      }`}
                    >
                      {withdraw.status.charAt(0).toUpperCase() +
                        withdraw.status.slice(1)}
                    </span>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-center text-gray-500">
                No withdrawal history available.
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Withdraw;

// import React, { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { getWithdrawalMethods, getWithdrawalMethodsByReseller } from '../../features/withdrawalMethod/withdrawalSlice';
// import { getWithdrawalRequests, createWithdrawalRequest, getWithdrawalRequestsByReseller, createWithdrawalRequestByReseller } from '../../features/withdrawalRequest/withdrawalRequestSlice';
// import LoaderComponent from '../LoaderComponent';

// const Withdraw = ({from = 'affiliate', totalEarning}) => {

//   const { withdrawalMethods } = useSelector((state) => state.withdrawal);
//   const { withdrawalRequests, loading } = useSelector((state) => state.withdrawalRequest);
//   const [paymentMethods] = useState(['mobileBanking', 'bankTransfer']);
//   const [withdrawable] = useState(totalEarning);
//   const [withdrawHistory] = useState([
//     { id: 1, amount: 500, method: 'bkash', status: 'paid' },
//     { id: 2, amount: 700, method: 'bank', status: 'pending' },
//     { id: 3, amount: 300, method: 'nagad', status: 'paid' },
//     { id: 4, amount: 200, method: 'rocket', status: 'pending' },
//     // More entries for testing
//   ]);

//   const [selectedMethod, setSelectedMethod] = useState('');
//   const [amount, setAmount] = useState('');
//   const [error, setError] = useState('');
//   const [activeTab, setActiveTab] = useState('withdraw');
//   const [filterStatus, setFilterStatus] = useState('all');

//   const dispatch = useDispatch();

//   useEffect(() => {
//     if(from === 'reseller'){
//       dispatch(getWithdrawalMethodsByReseller({token: JSON.parse(localStorage.getItem('access_token'))}));
//       dispatch(getWithdrawalRequestsByReseller({token: JSON.parse(localStorage.getItem('access_token'))}));
//     }

//     if(from === 'affiliate'){
//       dispatch(getWithdrawalMethods());
//       dispatch(getWithdrawalRequests());
//     }

//     }, [dispatch, from]);

//     const chosenMethod = withdrawalMethods.find((method) => method.paymentMethod === selectedMethod);
//     console.log(chosenMethod);

//   const handleWithdraw = () => {
//     const withdrawAmount = parseFloat(amount);

//     if (withdrawAmount < 500) {
//       setError('Minimum withdrawal amount is 500.');
//     } else if (withdrawAmount > withdrawable) {
//       setError(`Cannot withdraw more than available balance: $${withdrawable}.`);
//     } else {
//       setError('');
//       console.log('Withdraw request:', {
//         amount: withdrawAmount,
//         method: selectedMethod,
//       });
//     }
//   };

//   const filteredHistory = withdrawHistory.filter((entry) =>
//     filterStatus === 'all' ? true : entry.status === filterStatus
//   );

//   if(loading) return <LoaderComponent />;

//   return (
//     <div className="max-w-lg mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
//       <h2 className="text-2xl font-bold mb-4">Withdraw Funds</h2>

//       {/* Tab Navigation */}
//       <div className="flex mb-6">
//         <button
//           onClick={() => setActiveTab('withdraw')}
//           className={`p-2 font-semibold w-1/2 ${
//             activeTab === 'withdraw' ? 'bg-green-500 text-white' : 'bg-gray-300'
//           } rounded-l-lg`}
//         >
//           Withdraw
//         </button>
//         <button
//           onClick={() => setActiveTab('history')}
//           className={`p-2 font-semibold w-1/2 ${
//             activeTab === 'history' ? 'bg-green-500 text-white' : 'bg-gray-300'
//           } rounded-r-lg`}
//         >
//           Withdraw History
//         </button>
//       </div>

//       {/* Withdraw Form */}
//       {activeTab === 'withdraw' && (
//         <div>
//           <div className="bg-blue-100 text-blue-700 p-4 rounded-lg mb-4">
//             <p className="font-medium">Available to Withdraw: ${withdrawable}</p>
//           </div>

//           <div className="mb-4">
//             <label className="block text-gray-700 font-medium mb-2">Enter Amount</label>
//             <input
//               type="number"
//               value={amount}
//               onChange={(e) => setAmount(e.target.value)}
//               placeholder="Enter amount"
//               className="w-full p-2 border rounded-lg"
//               min="500"
//             />
//           </div>

//           <div className="mb-4">
//             <label className="block text-gray-700 font-medium mb-2">Select Withdrawal Method</label>
//             <select
//               value={selectedMethod}
//               onChange={(e) => setSelectedMethod(e.target.value)}
//               className="w-full p-2 border rounded-lg"
//             >
//               <option value="" disabled>Select a method</option>
//               {paymentMethods.includes('mobileBanking') && <option value="mobileBanking">Mobile Banking</option>}
//               {paymentMethods.includes('bankTransfer') && <option value="bank">Bank Transfer</option>}
//             </select>
//           </div>

//           {error && (
//             <div className="mb-4 p-2 text-red-600 bg-red-100 border border-red-500 rounded-lg">
//               {error}
//             </div>
//           )}

//           <button
//             onClick={handleWithdraw}
//             className="bg-green-500 text-white p-2 rounded-lg font-semibold hover:bg-green-600 w-full mt-4"
//           >
//             Confirm Withdraw
//           </button>
//         </div>
//       )}

//       {/* Withdraw History */}
//       {activeTab === 'history' && (
//         <div className="mt-4">
//           {/* Filter Buttons */}
//           <div className="flex justify-between mb-4">
//             <button
//               onClick={() => setFilterStatus('all')}
//               className={`p-2 font-semibold ${
//                 filterStatus === 'all' ? 'bg-green-500 text-white' : 'bg-gray-300'
//               } rounded-lg`}
//             >
//               All
//             </button>
//             <button
//               onClick={() => setFilterStatus('paid')}
//               className={`p-2 font-semibold ${
//                 filterStatus === 'paid' ? 'bg-green-500 text-white' : 'bg-gray-300'
//               } rounded-lg`}
//             >
//               Paid
//             </button>
//             <button
//               onClick={() => setFilterStatus('pending')}
//               className={`p-2 font-semibold ${
//                 filterStatus === 'pending' ? 'bg-green-500 text-white' : 'bg-gray-300'
//               } rounded-lg`}
//             >
//               Pending
//             </button>
//           </div>

//           {/* History List */}
//           <div className="max-h-60 overflow-y-auto">
//             {filteredHistory.length > 0 ? (
//               <ul className="space-y-4">
//                 {filteredHistory.map((withdraw) => (
//                   <li
//                     key={withdraw.id}
//                     className="p-4 border rounded-lg flex justify-between items-center"
//                   >
//                     <div>
//                       <p className="font-semibold">Order ID: {withdraw.id}</p>
//                       <p>Amount: ${withdraw.amount}</p>
//                       <p>Method: {withdraw.method}</p>
//                     </div>
//                     <span
//                       className={`py-1 px-3 rounded-lg text-white text-sm ${
//                         withdraw.status === 'paid' ? 'bg-green-500' : 'bg-yellow-500'
//                       }`}
//                     >
//                       {withdraw.status.charAt(0).toUpperCase() + withdraw.status.slice(1)}
//                     </span>
//                   </li>
//                 ))}
//               </ul>
//             ) : (
//               <p className="text-center text-gray-500">No withdrawal history available.</p>
//             )}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Withdraw;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllReferCommissionByReseller, getAllResellerCommission } from '../../features/allReferrals/allReferralSlice';
import LoaderComponent from '../LoaderComponent';

const ResellerReferral = () => {
  const { allReferCommissionByReseller, allResellerCommission, loading } = useSelector((state) => state.allReferral);
  const [activeTab, setActiveTab] = useState('ordersCommission');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllReferCommissionByReseller());
    dispatch(getAllResellerCommission());
  }, [dispatch]);

  const totalOrderCommission = allResellerCommission.reduce((sum, referral) => sum + referral.commission, 0);
  const totalReferCommission = allReferCommissionByReseller.reduce((sum, commission) => sum + commission.commission, 0);

  if(loading) return <LoaderComponent message="Loading referrals..." />

  return (
    <div className="max-w-2xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
      <h2 className="sm:text-xl text-2xl font-bold mb-4">My Referrals</h2>

      {/* Tab Navigation */}
      <div className="flex justify-center mb-6">
        <button
          onClick={() => setActiveTab('ordersCommission')}
          className={`px-4 py-2 mx-2 font-semibold rounded ${
            activeTab === 'ordersCommission' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
          }`}
        >
          Commission of Order From Store
        </button>
        <button
          onClick={() => setActiveTab('referCommission')}
          className={`px-4 py-2 mx-2 font-semibold rounded ${
            activeTab === 'referCommission' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
          }`}
        >
          Refer Commission
        </button>
      </div>

      {/* Summary Section */}
      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 mb-6">
        <SummaryCard label="Total Commission" amount={`TK. ${activeTab === 'ordersCommission' ? totalOrderCommission : totalReferCommission}`} />
        <SummaryCard label="Total Earned" amount={`TK. ${activeTab === 'ordersCommission' ? totalOrderCommission : totalReferCommission}`} />
      </div>

      {/* Referrals List */}
      <div className="bg-white p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">{activeTab === 'ordersCommission' ? 'Commission Of Orders From Store' : 'Refer Commission Details'}</h3>
        <div className="divide-y">
          {(activeTab === 'ordersCommission' ? allResellerCommission : allReferCommissionByReseller).length === 0 ? (
            <p className="text-gray-500 text-center">You have no {activeTab === 'ordersCommission' ? 'orders from store' : 'refer commissions'}.</p>
          ) : (
            (activeTab === 'ordersCommission' ? allResellerCommission : allReferCommissionByReseller).map((referral, index) => (
              <ReferralCard
                key={index}
                referral={referral}
                isOrder={activeTab === 'ordersCommission'}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

// Summary Card Component
const SummaryCard = ({ label, amount }) => (
  <div className="p-4 bg-blue-500 text-white rounded-lg shadow-md">
    <p className="text-sm font-medium">{label}</p>
    <p className="text-xl font-bold">{amount}</p>
  </div>
);

// Referral Card Component
const ReferralCard = ({ referral, isOrder }) => (
  <div className="flex justify-between items-center py-3">
    <div>
      {isOrder ? (
        <>
          <p className="text-gray-700 font-medium">Order ID: {referral.order}</p>
          <p className="text-sm text-gray-500">Commission: TK. {referral.commission}</p>
        </>
      ) : (
        <>
          <p className="text-gray-700 font-medium">User ID: {referral.referralOfReseller}</p>
          <p className="text-sm text-gray-500">Commission: TK. {referral.commission}</p>
        </>
      )}
    </div>
    {/* {isAffiliate && (
      <button className="text-blue-500 text-sm underline hover:text-blue-700">
        View Order
      </button>
    )} */}
  </div>
);

export default ResellerReferral;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaCheckCircle, FaEye, FaSyncAlt } from 'react-icons/fa';
import { getWithdrawalRequestsByAdmin, updateWithdrawalRequestByAdmin } from '../../features/withdrawalRequest/withdrawalRequestSlice';
import LoaderComponent from '../LoaderComponent';

const WithdrawalRequestsList = () => {
  const dispatch = useDispatch();
  const { withdrawalRequests, loading } = useSelector((state) => state.withdrawalRequest);
  const [selectedRequest, setSelectedRequest] = useState(null);

  useEffect(() => {
    dispatch(getWithdrawalRequestsByAdmin());
  }, [dispatch]);

  const handleUpdateStatus = (id, status) => {
    if (window.confirm(`Are you sure you want to mark this request as ${status}?`)) {
      dispatch(updateWithdrawalRequestByAdmin({ id, withdrawalRequest: { status } }));
    }
  };

  if (loading) return <LoaderComponent />;

  return (
    <div className="container mx-auto p-6 mb-8 h-screen">
      <h2 className="text-center text-2xl font-bold text-blue-600 mb-6">Withdrawal Requests</h2>
      <div className="overflow-x-auto shadow-lg rounded-lg bg-white">
        {withdrawalRequests.length > 0 ? (
          <table className="min-w-full bg-white border border-gray-200 rounded-md">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-3 px-4 text-left text-gray-600 font-semibold">Request ID</th>
                <th className="py-3 px-4 text-left text-gray-600 font-semibold">User</th>
                <th className="py-3 px-4 text-left text-gray-600 font-semibold">Amount</th>
                <th className="py-3 px-4 text-left text-gray-600 font-semibold">Method</th>
                <th className="py-3 px-4 text-left text-gray-600 font-semibold">Account No.</th>
                <th className="py-3 px-4 text-left text-gray-600 font-semibold">Provider</th>
                <th className="py-3 px-4 text-left text-gray-600 font-semibold">Status</th>
                <th className="py-3 px-4 text-center text-gray-600 font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody>
              {withdrawalRequests.length > 0 && withdrawalRequests.map((request) => (
                <tr key={request._id} className="border-t border-gray-200 hover:bg-gray-50">
                  <td className="py-4 px-4 text-gray-800">{request._id}</td>
                  <td className="py-4 px-4 text-gray-800">{request.user?.name}</td>
                  <td className="py-4 px-4 text-gray-800">Tk. {request?.amount}</td>
                  <td className="py-4 px-4 text-gray-800">{request.withdrawalMethod?.paymentMethod}</td>
                  <td className="py-4 px-4 text-gray-800">{request.withdrawalMethod[request.withdrawalMethod?.paymentMethod]?.accountNumber}</td>
                  <td className="py-4 px-4 text-gray-800">{request.withdrawalMethod[request.withdrawalMethod?.paymentMethod]?.provider}</td>
                  <td className={`py-4 px-4 text-center font-semibold ${
                    request.status === 'paid' ? 'text-green-600' :
                    'text-yellow-600'
                  }`}>
                    {request.status}
                  </td>
                  <td className="py-4 px-4 flex justify-center gap-4 text-gray-600">
                    <button
                      onClick={() => setSelectedRequest(request)}
                      className="text-blue-500 hover:text-blue-600 transition-all"
                      title="View Details"
                    >
                      <FaEye size={18} />
                    </button>
                    <button
                      onClick={() => handleUpdateStatus(request._id, 'paid')}
                      className="text-green-500 hover:text-green-600 transition-all"
                      title="Approve"
                    >
                      <FaCheckCircle size={18} />
                    </button>
                    
                    <button
                      onClick={() => handleUpdateStatus(request._id, 'pending')}
                      className="text-yellow-500 hover:text-yellow-600 transition-all"
                      title="Set to Pending"
                    >
                      <FaSyncAlt size={18} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center text-gray-500 py-8">No withdrawal requests found.</p>
        )}
      </div>

      {selectedRequest && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3">
            <h3 className="text-xl font-semibold mb-4 text-center">Request Details</h3>
            <p><strong>Request ID:</strong> {selectedRequest._id}</p>
            <p><strong>User:</strong> {selectedRequest.user.name}</p>
            <p><strong>Amount:</strong> ${selectedRequest.amount}</p>
            <p><strong>Method:</strong> {selectedRequest.withdrawalMethod?.paymentMethod}</p>
            <p><strong>Method:</strong> {selectedRequest.withdrawalMethod[selectedRequest.withdrawalMethod?.paymentMethod]?.accountNumber}</p>
            <p><strong>Account Number:</strong> {selectedRequest.withdrawalMethod[selectedRequest.withdrawalMethod?.paymentMethod]?.provider}</p>
            <p><strong>Status:</strong> {selectedRequest.status}</p>
            <p><strong>Requested On:</strong> {new Date(selectedRequest.createdAt).toLocaleString()}</p>
            <button
              onClick={() => setSelectedRequest(null)}
              className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-all"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WithdrawalRequestsList;

import React, { useEffect, useState } from "react";
import { getProductsOnOffer, resetOfferProducts } from "../features/products/productsSlice";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "../components/ProductCard";
import LoaderComponent from "../components/LoaderComponent";
import { useInView } from "react-intersection-observer";

const ProductsOnOffer = () => {
  const { productsOnOffer, offerPagination:pagination, loading } = useSelector((state) => state.products);
  const [page, setPage] = useState(1);
  const { ref, inView } = useInView({
    threshold: 0.5,
    rootMargin: "100px",
  });
  const dispatch = useDispatch();

  // Fetch products on offer 
  useEffect(() => {
  
      dispatch(getProductsOnOffer({ page, limit: 20 }));
  
  }, [dispatch, page]);

  // Increment page number when in view and next page exists
  useEffect(() => {
    if (inView && pagination?.next) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [inView, pagination]);

  // Reset products on offer when component unmounts
  useEffect(() => {
    return () => {
      dispatch(resetOfferProducts());
    };
  }, [dispatch]);

  if (loading && page === 1) return <LoaderComponent />;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {productsOnOffer?.map((product, index) => (
          <ProductCard key={index} product={product} />
        ))}
      </div>

      {loading && <LoaderComponent />}

      {/* Infinite Scroll Trigger */}
      {!loading && pagination?.next && (
        <div ref={ref} className="mt-8 text-center">
          <LoaderComponent />
        </div>
      )}
    </div>
  );
};

export default ProductsOnOffer;

















// import React, { useEffect, useState } from "react";
// import {getProductsOnOffer,resetOfferProducts} from "../features/products/productsSlice";
// import {useDispatch, useSelector} from "react-redux";
// import ProductCard from "../components/ProductCard";
// import LoaderComponent from "../components/LoaderComponent";
// import { useInView } from "react-intersection-observer";


// const ProductsOnOffer = () => {
    

//     const { productsOnOffer, pagination, loading } = useSelector((state) => state.products);
//     const [page, setPage] = useState(1);
//     const { ref, inView } = useInView({
//         threshold: 0.5,
//         rootMargin: "100px",
//     });
//     const dispatch = useDispatch();


//     // increment page
//     const handleLoadMore = () => {
//         setPage(page + 1);
//     };

//     // fetch products on offer
//     useEffect(() => {
//         dispatch(getProductsOnOffer({ page, limit: 5 }));
//     }, [dispatch,page]);


//     // reset the products on offer when the component unmounts
//     useEffect(() => {
//         return () => {
//             dispatch(resetOfferProducts());
//         };
//     }, [dispatch]);

//     if(loading) return <LoaderComponent />

//     return (
//         <div className="container mx-auto px-4 py-8">
//             <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
//             {productsOnOffer &&
//               productsOnOffer.map((product, index) => (
//                 <ProductCard key={index} product={product} />
//               ))}
//           {/* Infinite scroll observer */}

//           </div>
//         </div>
//     )
// }

// export default ProductsOnOffer;

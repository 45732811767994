import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api/api';


export const createHeadline = createAsyncThunk('headline/createHeadline', async (headlineData) => {
    const response = await api.post('/headlines', headlineData,{headers: {
        "Content-Type":"application/json"
    }});
    return response.data;
});

export const getHeadlines = createAsyncThunk('headline/getHeadlines', async () => {
    const response = await api.get('/headlines');
    return response.data;
});

export const updateHeadline = createAsyncThunk('headline/updateHeadline', async ({headlineId,headlineData}) => {
    const response = await api.put(`/headlines/${headlineId}`, headlineData, {headers: {
        "Content-Type":"application/json"
    }});
    return response.data;
});

export const deleteHeadline = createAsyncThunk('headline/deleteHeadline', async (headlineId) => {
    const response = await api.delete(`/headlines/${headlineId}`);
    return response.data;    
});

export const getAllHeadlines = createAsyncThunk('headline/getAllHeadlines', async (token) => {
    const response = await api.get('/headlines/admin', {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
})

const headlineSlice = createSlice({
    name: 'headline',
    initialState: {
        headlines: [],
        currentHeadLine: null,
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createHeadline.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createHeadline.fulfilled, (state, action) => {
                state.loading = false;
                state.headlines.push(action.payload);
            })
            .addCase(createHeadline.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; 
            })
            .addCase(getHeadlines.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getHeadlines.fulfilled, (state, action) => {
                state.loading = false;
                state.currentHeadLine = action.payload;
            })
            .addCase(getHeadlines.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; 
            })
            .addCase(updateHeadline.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateHeadline.fulfilled, (state, action) => {
                state.loading = false;
                state.headlines = state.headlines.map((headline) => {
                    if (headline._id === action.payload._id) {
                        return action.payload;
                    }
                    return headline;
                });
            })
            .addCase(updateHeadline.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; 
            })
            .addCase(deleteHeadline.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteHeadline.fulfilled, (state, action) => {
                state.loading = false;
                state.headlines = state.headlines.filter((headline) => headline._id !== action.payload._id);
            })
            .addCase(deleteHeadline.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; 
            })
            .addCase(getAllHeadlines.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllHeadlines.fulfilled, (state, action) => {
                state.loading = false;
                state.headlines = action.payload;
            })
            .addCase(getAllHeadlines.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; 
            });
            
    }
});

export default headlineSlice.reducer;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api/api';

export const createExecutive = createAsyncThunk('executive/createExecutive', async (executive) => {
    const response = await api.post('/executives', executive,{headers: {
        "Content-Type":"application/json"
    }});
    return response.data;
});

export  const getExecutives = createAsyncThunk('executive/getExecutives', async () => {
    const response = await api.get('/admin/members');
    return response.data;
});

export const updateExecutive = createAsyncThunk('executive/updateExecutive', async ({executiveId,executiveData}) => {
    const response = await api.put(`/executives/${executiveId}`, executiveData, {headers: {
        "Content-Type":"application/json"
    }});
    return response.data;
});

export  const deleteExecutive = createAsyncThunk('executive/deleteExecutive', async (executiveId) => {
    const response = await api.delete(`/executives/${executiveId}`);
    return response.data;    
});

export const getSingleExecutive = createAsyncThunk('executive/getSingleExecutive', async (executiveId) => {
    const response = await api.get(`/executives/${executiveId}`);
    return response.data;
})

const executiveSlice = createSlice({
    name: 'executive',
    initialState: {
        executives: [],
        executive: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createExecutive.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createExecutive.fulfilled, (state, action) => {
                state.loading = false;
                state.executives.push(action.payload);
                state.error = null;
            })
            .addCase(createExecutive.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getExecutives.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getExecutives.fulfilled, (state, action) => {
                state.loading = false;  
                state.executives = action.payload;
                state.error = null;
            })
            .addCase(getExecutives.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(updateExecutive.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateExecutive.fulfilled, (state, action) => {
                state.loading = false;
                state.executives = state.executives.map((executive) => executive._id === action.payload._id ? action.payload : executive);
                state.error = null;
            })
            .addCase(updateExecutive.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(deleteExecutive.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteExecutive.fulfilled, (state, action) => {
                state.loading = false;
                state.executives = state.executives.filter((executive) => executive._id !== action.payload._id);
                state.error = null;
            })
            .addCase(deleteExecutive.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getSingleExecutive.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getSingleExecutive.fulfilled, (state, action) => {
                state.loading = false;
                state.executive = action.payload;
                state.error = null;
            })
            .addCase(getSingleExecutive.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default executiveSlice.reducer
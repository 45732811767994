import React, { useEffect } from 'react';
import {fetchCurrentOrderBonus } from '../../features/orderBonus/orderBonusSlice';
import { useDispatch, useSelector } from 'react-redux';
import LoaderComponent from '../LoaderComponent';

const OrderBonus = () => {

    const { currentOrderBonus, loading , error } = useSelector((state) => state.orderBonus);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCurrentOrderBonus());
      }, [dispatch]);
    
    if (loading) {
        return <LoaderComponent />;
      }
    
      if (error) {
        return <div>Error: {error}</div>;
      }
    
      if (!currentOrderBonus) {
        return <div>No order bonus found.</div>;
      }

  return (
    <div className="container mx-auto px-4 py-8 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-y-auto h-screen">
      <img
        src={currentOrderBonus.image}
        alt={currentOrderBonus.title}
        className="w-full h-56 object-cover"
      />
      <div className="p-6">
        <h3 className="text-2xl font-bold text-gray-800 mb-3">{currentOrderBonus.title}</h3>
        <p className="text-gray-600 mb-4">{currentOrderBonus.description}</p>
        <button
          className="bg-gradient-to-r from-blue-500 to-purple-600 text-white w-full py-3 rounded-lg hover:opacity-90 transition-opacity duration-300"
        >
          Claim Your Bonus by contact with us.
        </button>
      </div>
    </div>
  );
};

export default OrderBonus;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoaderComponent from '../LoaderComponent';

const PaymentSettings = ({createWithdrawalMethod, getWithdrawalMethods}) => {

  const {withdrawalMethods, loading} = useSelector((state) => state.withdrawal);
  const [paymentMethod, setPaymentMethod] = useState(''); // Track selected payment method
  const [mobileBankingProvider, setMobileBankingProvider] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWithdrawalMethods(JSON.parse(localStorage.getItem('access_token'))));
  }, [dispatch, getWithdrawalMethods]);

  const handleSave = () => {
    if (paymentMethod === 'mobile') {
      const paymentDetails = {paymentMethod: 'mobileBanking', mobileBanking: {
        accountNumber:phoneNumber, provider:mobileBankingProvider
      } };

      dispatch(createWithdrawalMethod({method:paymentDetails,token:JSON.parse(localStorage.getItem('access_token'))}));

      setPaymentMethod(''); // Reset selected payment method
      setMobileBankingProvider('');
      setPhoneNumber('');
      setAccountNumber('');

    } else if (paymentMethod === 'bank') {

      const paymentDetails = {paymentMethod: 'bankTransfer', bankTransfer: {
        accountNumber, bankName
      }};
      dispatch(createWithdrawalMethod(
        {method:paymentDetails,token:JSON.parse(localStorage.getItem('access_token'))}
      ));

      setPaymentMethod(''); // Reset selected payment method
      setBankName('');
      setAccountNumber('');

    }
  }
  
  if (loading) return <LoaderComponent />

  return (
    <div className="max-w-lg mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Your Saved Withdrawal Method</h2>
      <div className="grid grid-cols-2 gap-4 mb-6">
            {
              withdrawalMethods && withdrawalMethods.length > 0 ? withdrawalMethods.map((method) => (<Card key={method._id} label={method.paymentMethod} accountNumber={method[method.paymentMethod]?.accountNumber} provider={method[method.paymentMethod]?.provider} />)) : <p>you don't set any withdrawal method</p>
            }
          </div>
      {/* Payment Method Selection */}
      <h2 className="text-2xl font-bold mb-4">Withdrawal Settings</h2>
      <div className="mb-4">
        <label className="block text-gray-700 font-medium mb-2">Choose Withdrawal Method</label>
        <select
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
          className="w-full p-2 border rounded-lg"
        >
          <option value="" disabled>Select a method</option>
          <option value="mobile">Mobile Banking</option>
          <option value="bank">Bank Transfer</option>
        </select>
      </div>

      {/* Conditional Fields Based on Selected Payment Method */}
      {paymentMethod === 'mobile' && (
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">Mobile Banking Details</h3>
          
          {/* Phone Number Field */}
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Phone Number</label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Enter phone number"
              className="w-full p-2 border rounded-lg"
            />
          </div>

          {/* Mobile Banking Provider Selection */}
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Select Provider</label>
            <select
              value={mobileBankingProvider}
              onChange={(e) => setMobileBankingProvider(e.target.value)}
              className="w-full p-2 border rounded-lg"
            >
              <option value="" disabled>Select provider</option>
              <option value="bkash">bKash</option>
              <option value="nagad">Nagad</option>
              <option value="rocket">Rocket</option>
            </select>
          </div>
        </div>
      )}

      {paymentMethod === 'bank' && (
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">Bank Transfer Details</h3>
          
          {/* Account Number Field */}
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Account Number</label>
            <input
              type="text"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              placeholder="Enter account number"
              className="w-full p-2 border rounded-lg"
            />
          </div>

          {/* Bank Name Field */}
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Bank Name</label>
            <input
              type="text"
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
              placeholder="Enter bank name"
              className="w-full p-2 border rounded-lg"
            />
          </div>
        </div>
      )}
      
      {/* Save Button */}
      <div className="mt-6">
        <button
          onClick={handleSave}
          className="w-full bg-blue-500 text-white p-2 rounded-lg font-semibold hover:bg-blue-600"
        >
          Save Payment Details
        </button>
      </div>
    </div>
  );
};


const Card = ({ label, accountNumber, provider }) => (
  <div className="p-4 bg-blue-500 text-white rounded-lg shadow-md">
   
    <p className="text-sm font-medium">Method: {label}</p>
    <p className="text-xl font-bold">Account Number: {accountNumber}</p>
    <p className="text-sm font-medium">Provider: {provider}</p>
  </div>
);

export default PaymentSettings;


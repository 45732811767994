import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createAffiliate, getAffiliateByUserId } from '../../features/affiliate/affiliateSlice';
import { useDispatch, useSelector } from 'react-redux';
import LoaderComponent from '../LoaderComponent';

const JoinAffiliate = () => {

  const {userDetails:userInfo} = useSelector(state=>state.user);
  const { affiliate, loading } = useSelector(state=>state.affiliate);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleJoinClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(()=>{
    if(userInfo){
      dispatch(getAffiliateByUserId({userId: userInfo._id, token: JSON.parse(localStorage.getItem('access_token'))}));
    }
    
  },[dispatch, userInfo]);
 

  // Updated validation schema with a custom test for at least one link
  const validationSchema = Yup.object().shape({
    referByCode: Yup.string().optional().matches(/^[A-Z0-9]+$/, 'Invalid refer by code'),
    terms: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
    socialLinks: Yup.object().shape({
      facebook: Yup.string().url('Invalid URL'),
      instagram: Yup.string().url('Invalid URL'),
      twitter: Yup.string().url('Invalid URL'),
      youtube: Yup.string().url('Invalid URL'),
    }).test(
      'at-least-one',
      'At least one social media link is required',
      (value) => {
        return Object.values(value).some((link) => link && link.trim() !== '');
      }
    ),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await dispatch(createAffiliate({ social_links: values.socialLinks, referByCode: values.referByCode })).unwrap();
      resetForm();
      handleCloseModal();
      window.location.reload();
      
    } catch (error) {
      console.error('Failed to create affiliate:', error);
      // Optionally handle the error (e.g., show a notification)
    }
  };

 

  if (loading) return <LoaderComponent />

  return (
    <div className="max-w-2xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold mb-4 text-center">Become an Affiliate</h2>

      {
        affiliate && affiliate.status === 'inactive' ? (
          <div>
            <h3 className="text-xl font-semibold">Your Affiliate Request is Pending</h3>
            <p>If you have any questions or need further assistance, please don't hesitate to contact our support team.</p>
          </div>
        ):(
          <>
          {/* information section */}
          <div className="mb-6 space-y-4">
            <section>
              <h3 className="text-xl font-semibold">How Affiliate Works</h3>
              <p>Learn how to promote our products and earn commission on every sale made through your referral links.</p>
            </section>
            <section>
              <h3 className="text-xl font-semibold">What's the Benefit?</h3>
              <p>Enjoy a generous commission structure, exclusive offers, and ongoing support to help maximize your earnings.</p>
            </section>
            <section>
              <h3 className="text-xl font-semibold">Product-Based Commission System</h3>
              <p>Earn different commission rates depending on the product category, with special bonuses for top-performing affiliates.</p>
            </section>
          </div>

          {/* Join Now Button */}
          <button
            onClick={handleJoinClick}
            className="bg-green-500 text-white p-3 rounded-lg font-semibold hover:bg-green-600 w-full mt-4"
          >
            Join Now
          </button>
          </>
        )
      }
      

      {/* Modal for Joining Affiliate */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-2/3 max-h-[90vh] overflow-auto">
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 font-bold"
            >
              &times;
            </button>

            <h2 className="text-2xl font-bold mb-4">Join Affiliate Program</h2>

            {/* Formik Form */}
            <Formik
              initialValues={{
                socialLinks: {
                  facebook: '',
                  instagram: '',
                  twitter: '',
                  youtube: '',
                },
                terms: false,
                referByCode: '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isValid, dirty }) => (
                <Form className="space-y-4">
                  {/* Social Media Links */}
                  <div className="mt-4 space-y-2">
                    <label className="block text-gray-700 font-medium mb-1">Social Media Links</label>
                    <Field
                      type="text"
                      name="socialLinks.facebook"
                      placeholder="Facebook Profile URL"
                      className="w-full p-2 border rounded-lg"
                    />
                    <ErrorMessage name="socialLinks.facebook" component="div" className="text-red-500 text-sm" />

                    <Field
                      type="text"
                      name="socialLinks.instagram"
                      placeholder="Instagram Profile URL"
                      className="w-full p-2 border rounded-lg"
                    />
                    <ErrorMessage name="socialLinks.instagram" component="div" className="text-red-500 text-sm" />

                    <Field
                      type="text"
                      name="socialLinks.twitter"
                      placeholder="Twitter Profile URL"
                      className="w-full p-2 border rounded-lg"
                    />
                    <ErrorMessage name="socialLinks.twitter" component="div" className="text-red-500 text-sm" />

                    <Field
                      type="text"
                      name="socialLinks.youtube"
                      placeholder="YouTube Channel URL"
                      className="w-full p-2 border rounded-lg"
                    />
                    <ErrorMessage name="socialLinks.youtube" component="div" className="text-red-500 text-sm" />
                  </div>

                  {/* Referral Code */}
                  <div className="mt-4">
                    <label className="block text-gray-700 font-medium mb-1">Refer Code (optional) </label>
                    <Field
                      type="text"
                      name="referByCode"
                      placeholder="Enter referral code if any"
                      className="w-full p-2 border rounded-lg"
                    />
                    <ErrorMessage name="referByCode" component="div" className="text-red-500 text-sm" />
                  </div>

                  {/* Terms and Conditions */}
                  <div className="flex items-center mt-4">
                    <Field type="checkbox" name="terms" className="mr-2" />
                    <label htmlFor="terms" className="text-gray-700">
                      I agree to the <a href="/terms-and-conditions" className="text-blue-600 hover:underline">Terms and Conditions</a>
                    </label>
                  </div>
                  <ErrorMessage name="terms" component="div" className="text-red-500 text-sm" />

                  {/* Join Button */}
                  <button
                    type="submit"
                    className={`p-3 rounded-lg font-semibold w-full mt-4 ${isValid && dirty ? 'bg-green-500 hover:bg-green-600 text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
                    disabled={!isValid || !dirty}
                  >
                    {loading ? 'confirming...' :'Confirm and Join'}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default JoinAffiliate;

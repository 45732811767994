import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";

// async thunk for getting all contacts
export const getContacts = createAsyncThunk("contact/getContacts", async (token) => {
    const response = await api.get("/contacts",{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
});


// async thunk for creating a contact
export const createContact = createAsyncThunk("contact/createContact", async (contact) => {
    const response = await api.post("/contacts", contact);
    return response.data;
});

// async thunk for delete contacts
export const deleteContact = createAsyncThunk("contact/deleteContact", async (id) => {
    const response = await api.delete(`/contacts/${id}`);    
    return response.data;
});

const contactSlice = createSlice({
    name: "contact",
    initialState: {
        contacts: [],
        loading: false,
        error: null,
        message: null,
        success: false
    },
    reducers: {
        resetSuccess: (state) => {
            state.success = false;
            state.message = null;
          }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getContacts.pending, (state) => {
                state.loading = true;
            })
            .addCase(getContacts.fulfilled, (state, action) => {
                state.loading = false;
                state.contacts = action.payload;
            })
            .addCase(getContacts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createContact.pending, (state) => {
                state.loading = true;
            })
            .addCase(createContact.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload.message) {
                    state.message = action.payload.message;
                    state.success = true;
                }
            })
            .addCase(createContact.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; 
            })
            .addCase(deleteContact.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteContact.fulfilled, (state, action) => {
                state.loading = false;
                state.contacts = state.contacts.filter((contact) => contact._id !== action.payload._id);
            })
            .addCase(deleteContact.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; 
            });
    },
});


export const { resetSuccess } = contactSlice.actions;

export default contactSlice.reducer;
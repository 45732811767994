import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Validation schema
const validationSchema = Yup.object({
  name: Yup.string().oneOf(['basic', 'standard'], 'Invalid package type').required('Required'),
  price: Yup.number().min(0, 'Price must be at least 0').required('Required'),
  offerPrice: Yup.number()
    .min(0, 'Offer price must be at least 0')
    .max(Yup.ref('price'), 'Offer price must be less than the regular price')
    .notRequired(),
});

const EditPackageFormModal = ({ packageData, onSubmit, setIsEditing }) => {
 
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 overflow-y-auto">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md mx-4 sm:mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Edit Package</h2>
          <button
            onClick={() => setIsEditing(false)}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>

        <Formik
          initialValues={{
            name: packageData?.name || 'basic',
            price: packageData?.price || 0,
            offerPrice: packageData?.offerPrice || 0,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmit(packageData?._id, {
              ...values,
              facilities: packageData?.facilities || [],
            }); // Call the onSubmit handler passed from the parent
            setIsEditing(false); // Close the modal after submitting
          }}
        >
          {({ isSubmitting }) => (
            <Form className="space-y-4">
              {/* Package Type */}
              <div>
                <label htmlFor="name" className="block text-gray-700 font-medium mb-1">
                  Package Type
                </label>
                <Field as="select" name="name" className="w-full border rounded p-2">
                  <option value="basic">Basic</option>
                  <option value="standard">Standard</option>
                </Field>
                <ErrorMessage name="name" component="div" className="text-red-500 text-sm mt-1" />
              </div>

              {/* Price */}
              <div>
                <label htmlFor="price" className="block text-gray-700 font-medium mb-1">
                  Price
                </label>
                <Field
                  type="number"
                  name="price"
                  className="w-full border rounded p-2"
                  placeholder="Enter package price"
                />
                <ErrorMessage name="price" component="div" className="text-red-500 text-sm mt-1" />
              </div>

              {/* Offer Price */}
              <div>
                <label htmlFor="offerPrice" className="block text-gray-700 font-medium mb-1">
                  Offer Price
                </label>
                <Field
                  type="number"
                  name="offerPrice"
                  className="w-full border rounded p-2"
                  placeholder="Enter offer price (if any)"
                />
                <ErrorMessage name="offerPrice" component="div" className="text-red-500 text-sm mt-1" />
              </div>

              {/* Submit and Close Buttons */}
              <div className="flex justify-between items-center mt-6">
                <button
                  type="button"
                  onClick={() => setIsEditing(false)}
                  className="bg-gray-400 text-white py-2 px-4 rounded shadow hover:bg-gray-500 transition-all"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-blue-500 text-white py-2 px-4 rounded shadow hover:bg-blue-600 transition-all"
                >
                  {isSubmitting ? 'Saving...' : 'Save Changes'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditPackageFormModal;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const SliderComponent = ({ executives = [] }) => {
  const isSingleItem = executives.length === 1;

  const settings = {
    dots: true,
    infinite: !isSingleItem, // Disable infinite scroll for a single item
    speed: 500,
    slidesToShow: isSingleItem ? 1 : 2, // Show 1 slide for single item
    slidesToScroll: 1,
    autoplay: !isSingleItem, // Disable autoplay for a single item
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: isSingleItem ? 1 : 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: isSingleItem ? 1 : 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full mx-auto my-10">
      <h2 className="text-3xl font-bold text-green-600 mb-6 text-center">
        Our Company Directors
      </h2>
      {executives.length > 0 ? (
        <Slider {...settings}>
          {executives.map((executive, index) => (
            <Link
              to={`/managing-director/${executive._id}/message`}
              key={index}
              className="px-4"
            >
              <div className="bg-white shadow-lg rounded-lg overflow-hidden text-center p-6 transition-transform transform hover:scale-105 hover:shadow-2xl duration-300">
                {/* Profile Picture */}
                <img
                  src={executive.profilePicture}
                  alt={executive.name}
                  className="w-24 h-24 mx-auto rounded-full object-cover mb-4 border-4 border-green-500"
                />
                {/* Name */}
                <h2 className="text-xl font-semibold text-green-600">
                  {executive.name}
                </h2>
                {/* Message Preview */}
                <p className="mt-2 text-gray-600 line-clamp-3">
                  {executive.message?.body.substring(0, 100)}...
                </p>
              </div>
            </Link>
          ))}
        </Slider>
      ) : (
        <p className="text-center text-gray-500">No executives found.</p>
      )}
    </div>
  );
};

export default SliderComponent;

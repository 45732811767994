import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";


// withdrawal method create by affiliate
export const createWithdrawalMethod = createAsyncThunk('withdrawalMethod/createWithdrawalMethod', async ({method,token}) => {
    const response = await api.post('/withdrawal-methods', method ,{headers: {
        "Content-Type":"application/json",
        "Authorization": `Bearer ${token}`
    }});
    return response.data;   
});

// get all withdrawal methods -- affiliate
export const getWithdrawalMethods = createAsyncThunk('withdrawalMethod/getWithdrawalMethods', async (token) => {
    const response = await api.get('/withdrawal-methods',{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
});

// withdrawal method create by reseller
export const createWithdrawalMethodByReseller = createAsyncThunk('withdrawalMethod/createWithdrawalMethodByReseller', async ({method,token}) => {
    const response = await api.post('/reseller/withdrawal-methods', method ,{headers: {
        "Content-Type":"application/json",
        "Authorization": `Bearer ${token}`
    }});
    return response.data;   
});

// get all withdrawal methods -- reseller
export const getWithdrawalMethodsByReseller = createAsyncThunk('withdrawalMethod/getWithdrawalMethodsByReseller', async (token) => {
    const response = await api.get('/reseller/withdrawal-methods',{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
});

const  initialState = {
    withdrawalMethods: [],
    loading: false,
    error: null,    
}

const paymentSlice = createSlice({
    name: 'withdrawal',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createWithdrawalMethod.pending, (state) => {
                state.loading = true
            })
            .addCase(createWithdrawalMethod.fulfilled, (state, action) => {
                state.loading = false
                state.withdrawalMethods.push(action.payload)
            })
            .addCase(createWithdrawalMethod.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
            .addCase(getWithdrawalMethods.pending, (state) => {
                state.loading = true
            })
            .addCase(getWithdrawalMethods.fulfilled, (state, action) => {
                state.loading = false
                state.withdrawalMethods = action.payload
            })
            .addCase(getWithdrawalMethods.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
            .addCase(createWithdrawalMethodByReseller.pending, (state) => {
                state.loading = true
            })
            .addCase(createWithdrawalMethodByReseller.fulfilled, (state, action) => {
                state.loading = false
                state.withdrawalMethods.push(action.payload)
            })
            .addCase(createWithdrawalMethodByReseller.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
            .addCase(getWithdrawalMethodsByReseller.pending, (state) => {
                state.loading = true
            })
            .addCase(getWithdrawalMethodsByReseller.fulfilled, (state, action) => {
                state.loading = false
                state.withdrawalMethods = action.payload
            })
            .addCase(getWithdrawalMethodsByReseller.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
            
    }
});

export default paymentSlice.reducer;

import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch } from 'react-redux';
import { createReview } from '../features/reviews/reviewSlice';
import * as Yup from 'yup';

const ReviewSchema = Yup.object().shape({
  rating: Yup.number().min(1).max(5).required('Rating is required'),
  comment: Yup.string().required('Comment is required'),
});

const InlineAddReview = ({ userId, productId }) => {
 
  const dispatch = useDispatch();
 

  return (
    <div>
      
        <div className="mt-4 p-4 bg-gray-100 rounded">
          <Formik
            initialValues={{ rating: '', comment: '' }}
            validationSchema={ReviewSchema}
            onSubmit={(values, { resetForm }) => {
              dispatch(createReview({ rating: Number(values.rating), comment: values.comment, user: userId, product: productId }));
              resetForm();
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-4">
                  <label>Rating</label>
                  <Field
                    as="select"
                    name="rating"
                    className="border rounded px-3 py-2 w-full"
                  >
                    <option value="">Select Rating</option>
                    {[1, 2, 3, 4, 5].map((rate) => (
                      <option key={rate} value={rate}>{rate} Star{rate > 1 ? 's' : ''}</option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="rating"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div className="mb-4">
                  <label>Comment</label>
                  <Field
                    as="textarea"
                    name="comment"
                    className="border rounded px-3 py-2 w-full"
                    rows="4"
                  />
                  <ErrorMessage
                    name="comment"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              </Form>
            )}
          </Formik>
        </div>
  
    </div>
  );
};

export default InlineAddReview;

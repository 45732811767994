import { useState } from "react";
import ProductCard from "./ProductCard"; 
import ReviewCard from "./ReviewCard"; 

const RelatedProductsAndReviews = ({ reviews, relatedProducts }) => {
  const [activeTab, setActiveTab] = useState("relatedProducts");

  return (
    <div className="w-full mt-10 px-4">
      {/* Tabs Section */}
      <div className="flex justify-center border-b border-gray-200 mb-6">
        <button
          onClick={() => setActiveTab("relatedProducts")}
          className={`px-6 py-3 font-semibold text-lg ${
            activeTab === "relatedProducts"
              ? "border-b-2 border-blue-600 text-blue-600"
              : "text-gray-600"
          } transition-all duration-200`}
        >
          Related Products
        </button>
        <button
          onClick={() => setActiveTab("reviews")}
          className={`px-6 py-3 font-semibold text-lg ${
            activeTab === "reviews"
              ? "border-b-2 border-blue-600 text-blue-600"
              : "text-gray-600"
          } transition-all duration-200`}
        >
          Reviews
        </button>
      </div>

      {/* Content Section */}
      <div className="relative flex flex-col items-center">
        {activeTab === "relatedProducts" ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 w-full">
            {(relatedProducts && relatedProducts.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))) || <p>No related products found.</p>}
          </div>
        ) : (
          <div className="flex flex-col space-y-4 w-full">
            {reviews && reviews.length > 0 ? (
              reviews.map((review) => <ReviewCard key={review._id} review={review} />)
            ) : (
              <p className="text-center text-gray-600">No reviews yet.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RelatedProductsAndReviews;

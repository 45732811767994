import React, { useState } from "react";
import { bulkUploadProducts } from '../../features/products/productsSlice';
import { useDispatch } from "react-redux";

const UploadFile = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");

  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      setFile(selectedFile);
      setUploadStatus("");
    } else {
      setUploadStatus("Please upload a valid .xlsx file.");
    }
  };

  const handleFileUpload = async () => {
    if (!file) {
      setUploadStatus("No file selected.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      setUploadStatus("Uploading...");
      const response = await dispatch(bulkUploadProducts(formData)).unwrap();

      if (response.status === 200) {
        setUploadStatus("File uploaded successfully.");
      } else {
        setUploadStatus("Failed to upload file.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadStatus("An error occurred during the upload.");
    }
  };

  return (
    <div className="upload-container">
      <h2>Upload Product Excel File</h2>
      <input
        type="file"
        accept=".xlsx"
        onChange={handleFileChange}
        style={{ marginBottom: "10px" }}
      />
      <button
        onClick={handleFileUpload}
        disabled={!file}
        style={{
          padding: "10px 20px",
          backgroundColor: "#4CAF50",
          color: "#fff",
          border: "none",
          cursor: file ? "pointer" : "not-allowed",
        }}
      >
        Upload
      </button>
      <p style={{ marginTop: "10px", color: uploadStatus.startsWith("Error") ? "red" : "green" }}>
        {uploadStatus}
      </p>
    </div>
  );
};

export default UploadFile;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeroSection from "../../components/HeroSection";
import { getResellerStore } from "../../features/resellerStore/resellerStoreSlice";
import LoaderComponent from "../../components/LoaderComponent";
import StoreProductCard from "../../components/StoreProductCard";


const Store = () => {
  const { store, loading } = useSelector((state) => state.resellerStore);

  const dispatch = useDispatch();
  const location = useLocation();

  // get query params
  const queryParams = new URLSearchParams(location.search);
  const storeId = queryParams.get("storeid");

  useEffect(() => {
    if (storeId) {
      dispatch(getResellerStore(storeId));
    }
  }, [dispatch, storeId]);

  if (loading) return <LoaderComponent />;

  //@TODO maintain store 404 page
  if (!storeId) return <div>Store not found</div>;

  return (
    <div className="container mx-auto mb-8">
      <HeroSection storeDetails={{name: store?.storeName,storeId:store?.storeId}} />
      <div>
        <h1 className="text-3xl font-bold mb-4 mt-4">STORE PRODUCTS</h1>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-2">
          {" "}
          {/*gap-4 */}
          {store && store.products?.map((product) => (
            <StoreProductCard key={product._id} product={product} storeId={storeId}/>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Store;

import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash, FaEye, FaPlus } from "react-icons/fa";
import ShowPackageFormModal from "./ShowPackageFormModal";
import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "../../features/package/packageSlice";
import PackageDetails from "./PackageDetails";
import EditPackageFormModal from "./EditPackageFormModal";
import { updatePackage, deletePackage } from "../../features/package/packageSlice";
import LoaderComponent from "../LoaderComponent";

const PackageList = () => {
  const { packages, loading } = useSelector((state) => state.packages);
  const dispatch = useDispatch();

  const [selectedPackage, setSelectedPackage] = useState(null);

  const [showDetails, setShowDetails] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);

  useEffect(() => {
    dispatch(getPackages());
  }, [dispatch]);

  const handleDelete = (packageId) => {
    if (window.confirm("Are you sure you want to delete this package?")) {
      dispatch(deletePackage(packageId));
    }
  };

  const handleEdit = (pkg) => {
    setSelectedPackage(pkg);
    setIsEditing(true);
  };

  const handleViewDetails = (pkg) => {
    setSelectedPackage(pkg);
    setShowDetails(true);
  };

  const handleEditSubmit = (packageId, values) =>{
    dispatch(updatePackage({packageId, packageData: values}));
  }

  if(loading) return <LoaderComponent />

  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Package List</h2>
        <button
          onClick={() => setShowCreateForm(true)}
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 transition-all flex items-center"
        >
          <FaPlus className="mr-2" /> Add Package
        </button>
      </div>

      {packages && packages.length > 0 ? (
        <div className="shadow-lg rounded-lg overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-200">
              <tr>
                <th className="py-2 px-4">Package ID</th>
                <th className="py-2 px-4">Name</th>
                <th className="py-2 px-4">Price</th>
                <th className="py-2 px-4">Offer Price</th>
                <th className="py-2 px-4">Facilities</th>
                <th className="py-2 px-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {packages.map((pkg) => (
                <tr key={pkg._id} className="border-t">
                  <td className="py-2 px-4">{pkg._id}</td>
                  <td className="py-2 px-4">{pkg.name}</td>
                  <td className="py-2 px-4">Tk. {pkg.price}</td>
                  <td className="py-2 px-4">
                    {pkg.offerPrice > 0 ? `TK. ${pkg.offerPrice}` : "N/A"}
                  </td>
                  <td className="py-2 px-4">
                    <ul className="list-disc list-inside">
                      {pkg.facilities.map((facility, index) => (
                        <li
                          key={index}
                          className={
                            facility.available
                              ? "text-green-600"
                              : "text-red-600"
                          }
                        >
                          {facility.name}{" "}
                          {facility.available ? "(Available)" : "(Unavailable)"}
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td className="py-2 px-4 flex gap-2">
                    <button
                      onClick={() => handleViewDetails(pkg)}
                      className="bg-blue-500 text-white p-2 rounded-full shadow hover:bg-blue-600 transition-all"
                    >
                      <FaEye />
                    </button>
                    <button
                      onClick={() => handleEdit(pkg)}
                      className="bg-green-500 text-white p-2 rounded-full shadow hover:bg-green-600 transition-all"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => handleDelete(pkg._id)}
                      className="bg-red-500 text-white p-2 rounded-full shadow hover:bg-red-600 transition-all"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center text-gray-500 text-lg">
          <h2> No packages are available to show</h2>
          <p> Please add a package</p>
        </div>
      )}

      {/* Conditional Modals for creating, viewing, and editing */}
      {showCreateForm && (
        <ShowPackageFormModal setShowCreateForm={setShowCreateForm} />
      )}
     {showDetails && selectedPackage && <PackageDetails packageData={selectedPackage} setShowDetails={setShowDetails} />}
      {isEditing && selectedPackage && <EditPackageFormModal packageData={selectedPackage} onSubmit={handleEditSubmit} setIsEditing={setIsEditing} />} 
    </div>
  );
};

export default PackageList;

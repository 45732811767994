import React, { useEffect, useState } from 'react';
import {  FaTrash, FaEye } from 'react-icons/fa';
import { deleteResellerByAdmin, getAllResellersByAdmin } from '../../features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import LoaderComponent from '../LoaderComponent';
import ShowResellerDetails from './ShowResellerDetails';

const ResellerList = () => {
  const {resellers, loading, error} = useSelector((state) => state.user);
  const [selectedReseller, setSelectedReseller] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  // const [isEditing, setIsEditing] = useState(false);
  // const [showCreateForm, setShowCreateForm] = useState(false);


  const dispatch = useDispatch();

  // fetch all resellers on mount
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('access_token'));
    dispatch(getAllResellersByAdmin(token));
  }, [dispatch]);

  const handleDelete = (resellerId) => {
    if (window.confirm('Are you sure you want to delete this reseller?')) {
      dispatch(deleteResellerByAdmin({id:resellerId, token: JSON.parse(localStorage.getItem("access_token"))})); 
    }
  };

  // const handleEdit = (reseller) => {
  //   setSelectedReseller(reseller);
  //   setIsEditing(true);
  // };

  const handleViewDetails = (reseller) => {
    setSelectedReseller(reseller);
    setShowDetails(true);
  };

  


  if(loading) return <LoaderComponent />
  if(error) return <p className="text-red-500">{error}</p>

  return (
    <div className="container mx-auto p-6 mb-8 h-screen">
      {/* <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Reseller List</h2>
        <button
          onClick={() => setShowCreateForm(true)}
          className="bg-blue-500 text-white p-2 rounded shadow-md hover:bg-blue-600 transition-all flex items-center"
        >
          <FaPlus className="mr-2" /> Create Reseller
        </button>
      </div> */}
      <div className="shadow-lg rounded-lg overflow-hidden">
       {
        resellers.length > 0 ? (
          <table className="min-w-full bg-white">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-2 px-4">Reseller ID</th>
              <th className="py-2 px-4">Name</th>
              <th className="py-2 px-4">Phone</th>
              <th className="py-2 px-4">Subscription</th>
              <th className="py-2 px-4">Subscription Expiration</th>
              <th className="py-2 px-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {resellers.map((reseller) => (
              <tr key={reseller.id} className="border-t">
                <td className="py-2 px-4">{reseller._id}</td>
                <td className="py-2 px-4">{reseller.name}</td>
                <td className="py-2 px-4">{reseller.phone}</td>
                <td className="py-2 px-4">{reseller.subscription || 'N/A'} </td>
                <td className="py-2 px-4">{reseller.subscriptionExpiration || 'N/A'}</td>
                <td className="py-2 px-4 flex gap-2">
                  <button
                    onClick={() => handleViewDetails(reseller)}
                    className="bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-600 transition-all"
                  >
                    <FaEye />
                  </button>
                  
                  <button
                    onClick={() => handleDelete(reseller._id)}
                    className="bg-red-500 text-white p-2 rounded-full shadow-md hover:bg-red-600 transition-all"
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        ):(
          <p>No resellers found.</p>
        )
       }
      </div>

      {showDetails && selectedReseller && (
        <ShowResellerDetails selectedReseller={selectedReseller} setShowDetails={setShowDetails} />
      )}
  </div>
  )
}

export default ResellerList
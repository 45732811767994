import React from 'react';
import { FaTimes } from 'react-icons/fa';

const ContactDetailsModal = ({ contact, isOpen, onClose }) => {
  if (!isOpen || !contact) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-11/12 max-w-lg rounded-lg shadow-lg p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          title="Close"
        >
          <FaTimes size={20} />
        </button>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Contact Details</h2>
        <div className="mb-4">
          <label className="text-gray-600 font-medium">Name:</label>
          <p className="text-gray-700">{contact.name}</p>
        </div>
        <div className="mb-4">
          <label className="text-gray-600 font-medium">Email:</label>
          <p className="text-gray-700">{contact.email}</p>
        </div>
        <div className="mb-4">
          <label className="text-gray-600 font-medium">Message:</label>
          <p className="text-gray-700 whitespace-pre-line">{contact.message}</p>
        </div>
        <div className="mt-6 text-center">
          <button
            onClick={onClose}
            className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactDetailsModal;

import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ product }) => {
  
  return (
    <Link
      to={`/shop/products/${product.title}`}
      className="border rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl bg-white"
    >
      <div className="relative w-full h-48 md:h-56">
        {product.colorSizeVariants && product.colorSizeVariants.length > 0 ? (
          <img
            src={product.colorSizeVariants[0]?.image}
            alt={product.title}
            className="w-full h-full object-contain transition-all duration-300 ease-in-out transform hover:scale-110"
          />
        ) : (
          <img
            src={product.sizeVariants[0]?.image}
            alt={product.title}
            className="w-full h-full object-contain transition-all duration-300 ease-in-out transform hover:scale-110"
          />
        )}
      </div>
      <div className="p-4">
        <h2 className="text-xs sm:text-sm md:text-lg font-bold line-clamp-2">{product.title}</h2>

        {product.upcoming ? (
          <p className="text-sm text-gray-500">Coming soon</p>
        ) : (
          <>
            {product.colorSizeVariants &&
              product.colorSizeVariants.length > 0 && (
                <div className="mt-2">
                  <div
                    className="flex flex-col md:flex-row justify-between items-start sm:items-center mt-1 text-sm md:text-base"
                  >

                    {product.inOffer ? (
                      <span className="flex flex-col md:flex-row items-center space-x-2">
                        <span className="text-red-500 text-xs sm:text-sm md:text-lg font-semibold">
                          TK.
                          {product.colorSizeVariants[0]?.sizes[0].offerPrice.toFixed(
                            2
                          )}
                        </span>
                        <span className="text-gray-500 text-xs sm:text-sm md:text-lg font-semibold line-through">
                          TK.
                          {product.colorSizeVariants[0]?.sizes[0].price.toFixed(
                            2
                          )}
                        </span>
                      </span>
                    ) : (
                      <span>
                        TK.
                        {product.colorSizeVariants[0]?.sizes[0].price.toFixed(
                          2
                        )}
                      </span>
                    )}
                  </div>
                </div>
              )}

            {product.sizeVariants && product.sizeVariants.length > 0 && (
              <div className="mt-2">
                <div
                  className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-1 text-sm md:text-base"
                >

                  {product.inOffer ? (
                    <span className="flex flex-col md:flex-row items-center space-x-2">
                      <span className="text-red-500 text-xs sm:text-sm md:text-lg font-semibold">
                        TK. {product.sizeVariants[0]?.offerPrice.toFixed(2)}
                      </span>
                      <span className="text-gray-500 text-xs sm:text-sm md:text-lg line-through">
                        TK. {product.sizeVariants[0]?.price.toFixed(2)}
                      </span>
                    </span>
                  ) : (
                    <span>TK. {product.sizeVariants[0]?.price.toFixed(2)}</span>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Link>
  );
};

export default ProductCard;
